import React from "react";
import { Route, Switch } from "react-router-dom";
import EditAdmin from "../Admin/AdminUser/EditAdmin.js";
import EditWhitelabelAdmin from "../Admin/WhiteLabelAdmin/EditWhitelabelAdmin.js";
import CompanyNameList from "../Vehicle/CompanyNameList.js";
import VechileModelListing from "../Vehicle/VechileModelListing.js";
import VechileKeyListing from "../Vehicle/VechileKeyListing.js";
import EditVehicleCompany from "../Vehicle/EditVehicleCompany.js";
import EditVehicleModel from "../Vehicle/EditVehicleModel.js";
import EditVehicleKey from "../Vehicle/EditVehicleKey.js";
import UpdateSoftwareUpload from "../SoftwareUpload/UpdateSoftwareUpload.js";
import DevicesCodeList from "../Devices/DevicesCodeList.js";
import AddDevicesCode from "../Devices/AddDevicesCode.js";
import EditDevicesCode from "../Devices/EditDevicesCode.js";
import Mobi1otApi from "../Devices/Mobi1otApi.js";
import UserList from "../User/UserList.js";
import EditUser from "../User/EditUser.js";
import CurrentUserList from "../User/CurrentUserList.js";
import AdminUserList from "../Admin/AdminUser/AdminUserList.js";
import MyAdminUserList from "../Admin/AdminUser/MyAdminUserList.js";
import MyWhiteLabelAdminUserList from "../Admin/WhiteLabelAdmin/MyWhiteLabelAdminUserList.js";
import OperatorList from "../Operator/OperatorList.js";
import AddVehicleCompany from "../Vehicle/AddVehicleCompany.js";
import AddVehicleModel from "../Vehicle/AddVehicleModel.js";
import AddVehicleKey from "../Vehicle/AddVehicleKey.js";
import AddAdminUsersDetail from "../Admin/AdminUser/AddAdminUsersDetail.js"
import AddOperatorDetail from "../Operator/AddOperatorDetail.js";
import DetailsUsagePage from "../Usage/DetailsUsagePage.js";
import VehicleList from "../Vehicle/VehicleList.js";
import AllVehicleList from "../Vehicle/AllVehicleList";
import UsageList from "../Usage/UsageList.js";
import UsageListMyAPI from "../Usage/UsageMyList.js";
import SharesList from "../Shares/SharesList.js";
import VerifyShareUser from "../Shares/VerifyShareUser.js";
import EditShare from "../Shares/EditShare.js";
import SharesMyList from "../Shares/SharesMyList.js";
import SharesWithMeList from "../Shares/SharesWithMeList.js";
import ThankYou from "../thankyou.js";
import AddVehicle from "../Vehicle/AddVehicle.js";
import VehicleMapList from "../Vehicle/VehicleMapList.js";
import SingleVehicleMapList from "../Vehicle/SingleVehicleMapList.js";
import VehicleMapAllList from "../Vehicle/VehicleMapAllList.js";
import VehicleDetials from "../Vehicle/VehicleDetials.js";
import AddDetail from "../Details/AddDetail.js";
import AddUsersDetail from "../User/AddUsersDetail.js";
import UpdateEmailDetail from "../Details/UpdateEmailDetail.js";
import UpdatePhoneDetail from "../Details/UpdatePhoneDetail.js";
import SoftwareUploadList from "../SoftwareUpload/SoftwareUploadList.js";
import AddSoftwareUpload from "../SoftwareUpload/AddSoftwareUpload.js";
import ChangPassword from "../Details/ChangPassword.js";
import VerifyEmailDetail from "../Details/VerifyEmailDetail.js";
import VerifyPhoneDetail from "../Details/VerifyPhoneDetail.js";
import NotFound from "../NotFound.jsx";
import Sidebar from "../Sidebar/Index.js";
import KeyRegenrate from "../Details/KeyRegenrate.js";
import Addwhitelabel from "../WhiteLabel/AddWhiteLabel.js";
import AddwhitelabelListing from "../WhiteLabel/WhiteLabelList.js";
import Editwhitelabel from "../WhiteLabel/EditWhiteLabel.js";
import AlarmSetting from "../AlarmSetting/AlarmSettings.js";
import HelpAndSupport from "../HelpAndSupport/HelpAndSupport.js";
import AddHelpAndSupportCategory from "../HelpAndSupport/AddHelpCategory.js";
import EditHelpCategory from "../HelpAndSupport/EditHelpCategory.js";
import EditHelpSubCategory from "../HelpAndSupport/EditHelpSubCategory.js";
import AddHelpAndSupportSubCategory from "../HelpAndSupport/AddHelpSubCategory.js";
import HeartBeatListing from "../HeartBeat/HeartBeat.js";
import BookingsList from "../Bookings/BookingsList.js";
import BookingsActiveList from "../Bookings/ActiveBooking.js";
import BookingsFutureList from "../Bookings/FutureBooking.js";
import BookingsExpiredList from "../Bookings/ExpiredBooking.js";
import VehicleReturnList from "../Bookings/ReturnVehicle.js";

import AddBooking from "../Bookings/AddBookingList.js";
import EditBooking from "../Bookings/EditBookingList.js";

import ContractLising from "../Contract/Listing.js";
import ContractEdit from "../Contract/edit.js";
import ContractAdd from "../Contract/add.js";

import StationListing from "../Stations/StationListing.js";
import StationEdit from "../Stations/EditStation.js";
import StationAdd from "../Stations/AddStation.js";
import Extra from "../Extra/AddExtra.js";

import WhitelabelAdminList from "../Admin/WhiteLabelAdmin/WhitelabelAdminListForSuperAdmin.js";
import PrivacyPolicy from "../PrivacyTerms/PrivacyPolicy/Listing.js"
import CreatePrivacyPolicy from "../PrivacyTerms/PrivacyPolicy/add.js";
import EditPrivacyPolicy from "../PrivacyTerms/PrivacyPolicy/edit.js"
import TermsOfUse from "../PrivacyTerms/TermsOfUse/Listing.js"
import CreateTermsOfUse from "../PrivacyTerms/TermsOfUse/add.js";
import EditTermsOfUse from "../PrivacyTerms/TermsOfUse/edit.js"
import AppNotification from "../WhiteLabel/AppNotification.js";
import EmailNotificatio from "../WhiteLabel/EmailNotification.js";

import EmailTemplat from "../EmailTemplate/EmailListing.js";
import CreateTemplate from "../EmailTemplate/CreateEmailTemplate.js";
import UpdateTemplate from "../EmailTemplate/UpdateEmailTemplate.js";
 
import NotificationTemplat from "../NotificationTemplate/NotificationListing.js";
import CreateNotificationTemplate from "../NotificationTemplate/CreateNotificationTemplate.js";
import UpdateNotificationTemplate from "../NotificationTemplate/UpdateNotificationTemplate.js";

import NotificationSettings from "../NotificationSettings/Settings.js";


const PrivateRoutes = () => {
  return (
    <>
      <div className="wrapper d-flex align-items-stretch">
        <Sidebar />
        <Switch>
          <Route exact path={"/admin/thankyou"} component={ThankYou} />
          <Route exact path={"/admin/vehicle-list/:id"} component={VehicleList} />
          <Route
            exact
            path={"/admin/all-vehicle-list/:id"}
            component={AllVehicleList}
          />
          <Route
            exact
            path={"/admin/current-user-list/:id"}
            component={CurrentUserList}
          />

          <Route exact path={"/admin/add-vehicle"} component={AddVehicle} />
          <Route
            exact
            path={"/admin/company-vehicle-list/:id"}
            component={CompanyNameList}
          />
          <Route
            exact
            path={"/admin/company-key-list/:id"}
            component={VechileKeyListing}
          />

          <Route
            exact
            path={"/admin/vehicle-map-list"}
            component={VehicleMapList}
          />
          <Route
            exact
            path={"/admin/vehicle-map-all-list"}
            component={VehicleMapAllList}
          />

          <Route
            exact
            path={"/admin/single-vehicle-map-list/:id"}
            component={SingleVehicleMapList}
          />

          <Route exact path={"/admin/add-detail"} component={AddDetail} />

          <Route exact path={"/admin/alarm-settings"} component={AlarmSetting} />

          <Route
            exact
            path={"/admin/add-users-detail"}
            component={AddUsersDetail}
          />

          <Route
            exact
            path={"/admin/add-white-label"}
            component={Addwhitelabel}
          />
          <Route
            exact
            path={"/admin/list-white-label/:id"}
            component={AddwhitelabelListing}
          />

          <Route
            exact
            path={"/admin/list-white-label-edit/:id"}
            component={Editwhitelabel}
          />

          <Route exact path={"/admin/key-regenrate"} component={KeyRegenrate} />

          <Route
            exact
            path={"/admin/add-admin-users-detail"}
            component={AddAdminUsersDetail}
          />

          <Route exact path={"/admin/operator-list"} component={OperatorList} />

          <Route
            exact
            path="/admin/add-operator-detail"
            component={AddOperatorDetail}
          />

          <Route
            exact
            path="/admin/usage-details/:id"
            component={DetailsUsagePage}
          />

          <Route exact path="/admin/user-list/:id" component={UserList} />

          <Route
            exact
            path="/admin/admin-user-list/:id"
            component={AdminUserList}
          />
          <Route
            exact
            path="/admin/my-admin-user-list/:id"
            component={MyAdminUserList}
          />

          <Route
            exact
            path="/admin/software-upload-list/:id"
            component={SoftwareUploadList}
          />

          <Route
            exact
            path="/admin/software-upload-add"
            component={AddSoftwareUpload}
          />

          <Route
            exact
            path="/admin/edit-software-upload/:id"
            component={UpdateSoftwareUpload}
          />

          <Route
            exact
            path="/admin/booking-list/:id"
            component={BookingsList}
          />
           <Route
            exact
            path="/admin/booking-active-list/:id"
            component={BookingsActiveList} 
          />
           <Route
            exact
            path="/admin/booking-future-list/:id"
            component={BookingsFutureList}
          />
           <Route
            exact
            path="/admin/booking-expired-list/:id"
            component={BookingsExpiredList}
          />
          <Route
            exact
            path="/admin/booking-vehicle-return-list/:id"
            component={VehicleReturnList}
          />
          <Route
            exact
            path="/admin/edit-booking/:id"
            component={EditBooking}
          />

          <Route
            exact
            path="/admin/create-booking/:id"
            component={AddBooking}
          />

           <Route
            exact
            path="/admin/contract-listing/:id"
            component={ContractLising}
          />
           <Route
            exact
            path="/admin/privacy-policy/:id"
            component={PrivacyPolicy}
          />
           <Route
            exact
            path="/admin/edit-privacy-policy/:label_id/:locale"
            component={EditPrivacyPolicy}
          />
           <Route
            exact
            path="/admin/create-privacy-policy"
            component={CreatePrivacyPolicy}
          />
           <Route
            exact
            path="/admin/terms-of-use/:id"
            component={TermsOfUse}
          />
           <Route
            exact
            path="/admin/edit-terms-of-use/:label_id/:locale"
            component={EditTermsOfUse}
          />
           <Route
            exact
            path="/admin/create-terms-of-use"
            component={CreateTermsOfUse}
          />

           <Route
            exact
            path="/admin/extra"
            component={Extra}
          />
           <Route
            exact
            path="/admin/create-contract"
            component={ContractAdd}
          />

           <Route
            exact
            path="/admin/edit-contract/:id/:contract"
            component={ContractEdit}
          />
          
          <Route
            exact
            path="/admin/station-listing/:id"
            component={StationListing}
          />

           <Route
            exact
            path="/admin/create-station"
            component={StationAdd} 
          />

           <Route
            exact
            path="/admin/edit-station/:id/"
            component={StationEdit}
          />

          <Route
            exact
            path="/admin/devices-code-list/:id"
            component={DevicesCodeList}
          />
          <Route
            exact
            path="/admin/devices-code-add"
            component={AddDevicesCode}
          />
          <Route
            exact
            path="/admin/heartbeat"
            component={HeartBeatListing}
          />

          <Route
            exact
            path="/admin/whitelabel-admin-user-list/:id"
            component={MyWhiteLabelAdminUserList}
          />
          <Route exact path="/admin/usage-list/:id" component={UsageList} />
          <Route exact path="/admin/usage-my-list/:id" component={UsageListMyAPI} />

          <Route exact path="/admin/shares-list/:id" component={SharesList} />

          <Route exact path="/admin/shares-my-list/:id" component={SharesMyList} />

          <Route
            exact
            path="/admin/shares-with-me-list"
            component={SharesWithMeList}
          />

          <Route
            exact
            path="/admin/mobi-1ot"
            component={Mobi1otApi}
          />

          <Route exact path="/admin/verify-user" component={VerifyShareUser} />

          <Route
            // exact
            path="/admin/edit-vehicle-details/:id"
            component={VehicleDetials}
          />

          <Route
            exact
            path="/admin/add-company-name"
            component={AddVehicleCompany}
          />

          <Route
            exact
            path="/admin/add-model-name"
            component={AddVehicleModel}
          />

          <Route
            exact
            path="/admin/add-model-name-listing/:id"
            component={VechileModelListing}
          />

          <Route exact path="/admin/add-key-name" component={AddVehicleKey} />

          <Route exact path="/admin/edit-admin/:id" component={EditAdmin} />
          <Route exact path="/admin/edit-whitelabel-admin/:id" component={EditWhitelabelAdmin} />
          <Route exact path="/admin/edit-user/:id" component={EditUser} />

          <Route exact path="/admin/edit-email" component={UpdateEmailDetail} />
          <Route
            exact
            path="/admin/verify-email"
            component={VerifyEmailDetail}
          />
          <Route
            exact
            path="/admin/verify-phone"
            component={VerifyPhoneDetail}
          />

          <Route
            exact
            path="/admin/update-phone"
            component={UpdatePhoneDetail}
          />
          <Route exact path="/admin/share-edit/:id" component={EditShare} />

          <Route
            exact
            path="/admin/change-password"
            component={ChangPassword}
          />

          <Route
            exact
            path="/admin/edit-vehicle-company/:id"
            component={EditVehicleCompany}
          />

          <Route
            exact
            path="/admin/edit-vehicle-model/:id"
            component={EditVehicleModel}
          />

          <Route
            exact
            path="/admin/edit-vehicle-key/:id"
            component={EditVehicleKey}
          />

          <Route
            exact
            path="/admin/edit-device-code/:id"
            component={EditDevicesCode}
          />

          <Route
            exact
            path="/admin/help-and-support/:id"
            component={HelpAndSupport}
          />
          <Route
            exact
            path="/admin/add-help-and-support-category"
            component={AddHelpAndSupportCategory}
          />
          <Route
            exact
            path="/admin/edit-help-and-support-category/:id"
            component={EditHelpCategory}
          />
          <Route
            exact
            path="/admin/add-help-and-support-subCategory/:name/:id"
            component={AddHelpAndSupportSubCategory}
          />
          <Route
            exact
            path="/admin/edit-help-and-support-subCategory/:id"
            component={EditHelpSubCategory}
          />
           <Route
            exact
            path="/whitelabel/admin-list/:id"
            component={WhitelabelAdminList}
          />
         
           <Route
            exact
            path="/whitelabel/email-notification/:id"
            component={EmailNotificatio}
          />

          <Route
            exact
            path="/admin/email-templates/1"
            component={EmailTemplat}
          />

          <Route
            exact
            path="/admin/create-template"
            component={CreateTemplate}
          />
          <Route
            exact
            path="/admin/edit-email-template/:id"
            component={UpdateTemplate}
          />

          <Route
            exact
            path="/whitelabel/app-notification/:id"
            component={NotificationTemplat}
          />

          <Route
            exact
            path="/whitelabel/email-notification-settings/:id"
            component={NotificationSettings}
          />

          <Route
            exact
            path="/whitelabel/app-notification/:id"
            component={NotificationTemplat}
          />

          <Route
            exact
            path="/admin/create-notification-template"
            component={CreateNotificationTemplate}
          />
          <Route
            exact
            path="/admin/edit-notification-template/:id"
            component={UpdateNotificationTemplate}
          />


          
          <Route component={NotFound} />

          
        </Switch>
      </div>
    </>
  );
};

export default PrivateRoutes;
