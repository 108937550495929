import React, { useEffect, useCallback } from "react";
import { Context } from "../Store/Store";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../Header/Index.js";
import { Modal, Button, Icon } from "rsuite";
import Pagination from "react-js-pagination";
import Helper from "../Helper";
import { useHistory } from 'react-router-dom';
import CheckBox from "../../commonComponents/CheckBox.js";
var {
  KeyModelListing,
  DeleteVehicleKeyAPI,
  AllDeleteVehicleKey,
} = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");

function VehicleModelListing() {
  const [records, setrecords] = React.useState([]);
  const [result, setResult] = React.useState({});
  const [rsModal, setRsModal] = React.useState(false);
  const [state, dispatch] = React.useContext(Context);
  const [currentId, setCurrentId] = React.useState(null);
  const [locationKeys, setLocationKeys] = React.useState([])
  const [errorMsg, setErrorMsg] = React.useState("");
  const [sort_by, setSortBy] = React.useState(1);

  const history = useHistory()
  const Modalclose = () => {
    setRsModal(false);
  };
  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };
  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  let location = useLocation()

  const getKeyListing = useCallback((page, sort) => {
    //if(Helper.getPermissions('user-read',permissions)){
    Helper.overlay(true);
    apiRequest(KeyModelListing.method, KeyModelListing.url + `?page=${page}&sort_by=${sort ?? sort_by}`)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data;
          setrecords(data.data)
          setResult(data.meta)
          dispatch({ type: "SET_SEARCH_STATE", search_list: false });
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        setErrorMsg(error)
        Helper.overlay(false);
      });
    //}
  }, [dispatch, sort_by]);

  useEffect(() => {
    if (location.hash !== "#search") {
      dispatch({ type: "SET_STATE", response: [] });
      getKeyListing(state.set_active_page);
    }
    else {
      Helper.overlay(false);
    }
  }, [dispatch, getKeyListing, location.hash, state.set_active_page]);

  // useEffect(() => {
  //   if (state.pagination === true) {
  //     setrecords(state.data.data)
  //     setResult(state.data.meta)
  //     sethidePagination(true);
  //     dispatch({ type: "SET_SEARCH_STATE", pagination: false });
  //   }
  // }, [dispatch, state.data.data, state.data.meta, state.pagination]);

  useEffect(() => {
    if (location.pathname.includes('company-key-list') && location.hash === "#search") {
      if (!state.data.data) {
        setrecords([]);
        setResult([]);
        //setShow(true);
        setErrorMsg('No record Found');
      }
      else {
        setrecords(state.data.data || [])
        setResult(state.data.meta || [])
        dispatch({ type: "SET_SEARCH_STATE", pagination: false });
        Helper.overlay(false);
      }

    }

    if (location.hash === "#error") {
      setrecords([]);
      setResult([]);
      //setShow(true);
      setErrorMsg('No record Found');
    }
  }, [dispatch, location, setResult, state.data, state.pagination]);

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          let str = location.pathname
          let id = str.substr(str.length - 1)
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          getKeyListing(+id);
          setLocationKeys(([_, ...keys]) => keys)
        } else {
          setLocationKeys((keys) => [location.key, ...keys])
          let str = location.pathname
          let id = str.substr(str.length - 1)
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          getKeyListing(+id);
        }
      }
    })
  }, [dispatch, getKeyListing, history, locationKeys])

  // eslint-disable-next-line no-unused-vars
  function getcheckbox() {
    var array = [];
    var checkboxes = document.querySelectorAll(
      "input[name='subcheckbox']:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
      checkboxes[i].checked = false;
    }
    //console.log("array", array);
    let params = new FormData();
    params.append("ids", array);
    let delete_property = window.confirm(
      "Are you sure you want to delete these records!"
    );
    if (delete_property) {
      apiRequest(AllDeleteVehicleKey.method, AllDeleteVehicleKey.url, params)
        .then((response) => {
          if (response.data.code === 200) {
            let data = records.filter(item => !array.includes(item.id.toString()));
            setrecords(data)
            setRsModal(false);
            //console.log("Success", response);
          } else {
            //console.log("Failure", response);
          }
        })
        .catch((error) => {
          //console.log("error", error);
          alert(error);
        });
    }
  }

  // eslint-disable-next-line no-unused-vars
  function handleMainCheckBox(e) {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    Array.from(document.querySelectorAll("input[name='subcheckbox']")).forEach(
      (checkbox) => {
        checkbox.checked = value;
      }
    );
  }

  const deleteRecord = (e) => {
    //let params = new FormData();
    //params.append("id", e.target.dataset.id);
    // let delete_property = window.confirm(
    //   "Are you sure you want to delete this record!"
    // );
    // if (delete_property) {
    apiRequest(
      DeleteVehicleKeyAPI.method,
      DeleteVehicleKeyAPI.url + `${currentId}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          let data = records.filter((d) => {
            return d.id !== +currentId
          })
          setrecords(data)
          setRsModal(false);
        } else {
          console.error("Failure", response);
        }
      })
      .catch((error) => {
        console.error("error", error);
        alert(error);
      });
    // }
  };

  const handlePageChange = (pageNumber) => {
    //console.log(`active page is ${pageNumber}`);
    Helper.overlay(true);
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    apiRequest(
      KeyModelListing.method,
      KeyModelListing.url + `?page=${pageNumber}&sort_by=${sort_by}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setrecords(response.data.data);
          setResult(response.data.meta);
          //console.log("Success", response);
          Helper.overlay(false);
          history.push(`/admin/company-key-list/${pageNumber}`);
        } else {
          Helper.overlay(false);
          //console.log("Failure", response);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        //console.log("error", error);
      });
  };
  const setDropDown = (e) => {
    setSortBy(e.target.value)
    getKeyListing(state.set_active_page, e.target.value)
  }

  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header />
        {
          Helper.getPermissions(
            "vehicle_key-read",
            permissions && permissions,
            "admin"
          ) || Helper.getPermissions("", [], user.type) ? (
            <div className="p-4">
              <div className="row pb-2 d-flex justify-content-between align-items-center border-bottom">
                <div className="col-lg-8 col-md-6">
                  <div className="d-flex flex-row mb-3">
                    {(Helper.getPermissions(
                      "vehicle_key-delete",
                      permissions && permissions,
                      "admin"
                    ) ||
                      Helper.getPermissions("", [], user.type)) && (
                        <>
                          <div className="p-2">
                            <CheckBox onChange={handleMainCheckBox} />
                          </div>
                          <div className="p-2">

                            <button className="btn btn-primary" onClick={getcheckbox}>
                              Bulk Delete
                            </button>

                          </div>
                        </>
                      )}
                    {
                      //Helper.getPermissions('usage-create',permissions) &&
                      <div className="p-2">
                        {Helper.getPermissions(
                          "vehicle_key-create",
                          permissions && permissions,
                          user.type
                        ) && (
                            <NavLink
                              exact
                              to={`/admin/add-key-name`}
                              className="btn btnn-user white bg-orange" style={{ backgroundColor: user.background_color }}
                            >
                              Add New Key
                            </NavLink>
                          )}
                      </div>
                    }
                  </div>
                </div>
                {/* <div className="col-md-4 p-2 text-center font-weight-bold"> */}
                {/* <NavLink className="black" exact to={`/admin/current-user-list`}>
                  Show My List
                </NavLink>
                {(Helper.getPermissions(
                  "user-read",
                  permissions && permissions,
                  "admin"
                ) ||
                  Helper.getPermissions("", [], user.type)) && (
                  <React.Fragment>
                    {` | `}
                    <NavLink
                      className="black"
                      exact
                      to={`/admin/user-list`}
                    >
                      Show All List
                    </NavLink>
                  </React.Fragment>
                )} */}
                {/* {` | `}
                <NavLink className="black" exact to={`/admin/vehicle-map-list`}>
                  Show on Map
                </NavLink> */}
                {/* </div> */}

                <div className="col-lg-4 col-md-6 ">
                  <div className="d-flex flex-row align-items-center  row">
                    <div className="col-md-12 col-lg-3 pl-lg-0 pr-lg-0">Results: {result?.total}</div>
                    <div className="col-md-12 col-lg-9 pl-lg-0 pr-lg-0 row">
                      <label class="col-3 mt-auto">Sort By:</label>
                      <select className="form-select form-control col-8 " aria-label="Default select example" name="sort_by" onChange={setDropDown}>
                        <option selected={sort_by === 1 ? true : false} value={1}>Last created on top</option>
                        <option selected={sort_by === 2 ? true : false} value={2}>First created on top</option>
                        <option selected={sort_by === 3 ? true : false} value={3}>Alphabetically A-Z</option>
                        <option selected={sort_by === 4 ? true : false} value={4}>Alphabetically Z-A</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {records.length ? (
                  records.map((record, index) => (
                    <div
                      className="card col-12 mt-3 shadow  bg-white rounded"
                      key={index}
                    >
                      <div className="row">
                        <div className="col-md-12 col-lg-1  col-sm-12 pb-3 pt-2 my-auto text-lg-center">
                          <i className="fas fa-key fa-3x text-center text-primary"></i>
                        </div>
                        <div className="col-md-12 col-lg-9  col-sm-12 pb-3 pt-2 my-auto">
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <input
                                type="checkbox"
                                className="pt-1"
                                name="subcheckbox"
                                defaultValue={record.id}
                                id="exampleCheck1"
                              />
                              {
                                Helper.getPermissions(
                                  "vehicle_key-update",
                                  permissions && permissions,
                                  user.type
                                ) ? (
                                  <NavLink
                                    className="pl-3 form-check-label font-weight-bold"
                                    exact
                                    to={`/admin/edit-vehicle-key/${record.id}`}
                                  >{record.id}

                                  </NavLink>
                                ) : (
                                  <strong>  {record.id}</strong>
                                )}
                            </div>

                          </div>
                          <div className="row pt-2">
                            <div className="col-md-4 col-lg-4 col-12">
                              <strong>Name:</strong> {record.name}
                            </div>
                            <div className="col-md-4 col-lg-4  col-12">
                              <strong>Key Tolerancee:</strong> {
                                record.tolerance
                                  ? (typeof record.tolerance === 'string' && !record.tolerance.includes('MM')
                                    ? record.tolerance + 'MM'
                                    : record.tolerance)
                                  : ''
                              }
                            </div>
                            <div className="col-md-4 col-lg-4  col-12">
                              <strong>Key Size:</strong> {record.size ? record.size : ""}
                            </div>

                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12  col-lg-2 my-auto">
                          <div className="row pt-2 pb-2">

                            <div className="col-md-6 col-sm-6 col-lg-12 pt-lg-2 pt-md-0 pt-2">
                              {Helper.getPermissions(
                                "vehicle_key-update",
                                permissions && permissions,
                                user.type
                              ) && (
                                  <NavLink
                                    className=""
                                    exact
                                    to={`/admin/edit-vehicle-key/${record.id}`}
                                  >
                                    <button className="btn-info w-100">
                                      Edit
                                    </button>
                                  </NavLink>
                                )}
                            </div>
                            <div className="col-md-6  col-sm-6 col-lg-12 pt-lg-2 pt-md-0 pt-2">
                              {Helper.getPermissions(
                                "vehicle_key-delete",
                                permissions && permissions,
                                user.type
                              ) && (
                                  <button
                                    className="btn-danger w-100"
                                    data-id={record.id}
                                    onClick={Modalopen}
                                  >
                                    Delete
                                  </button>
                                )}
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                    // <div
                    //   className="col-md-4 mt-3 border-bottom pb-3 ft-14"
                    //   key={index}
                    // >
                    //   <div className="card card-shadow">
                    //     <i className="fas fa-key fa-3x text-center text-primary"></i>
                    //     <div className="card-body">
                    //       <h5 className="card-title text-uppercase">
                    //         <div className="form-check">
                    //           <label
                    //             className="form-check-label font-weight-bold"
                    //             htmlFor="exampleCheck1"
                    //           >
                    //             <u>{record.id}</u>
                    //           </label>
                    //         </div>
                    //       </h5>
                    //       <p className="card-text">
                    //         <div className="d-flex justify-content-between">
                    //           <div>
                    //             <strong>Name:</strong> {record.name}
                    //           </div>
                    //         </div>
                    //       </p>
                    //     </div>

                    //     <div className="card-body">
                    //       <div className="row">
                    //         <div className="col-md-6">
                    //           {(Helper.getPermissions(
                    //             "user-delete",
                    //             permissions && permissions,
                    //             "admin"
                    //           ) ||
                    //             Helper.getPermissions("", [], user.type)) && (
                    //             <button
                    //               className="btn btnn-user btn-block btn-danger"
                    //               data-id={record.id}
                    //               onClick={Modalopen}
                    //             >
                    //               Delete
                    //             </button>
                    //           )}
                    //         </div>
                    //         <div className="col-md-6">
                    //           {(Helper.getPermissions(
                    //             "user-edit",
                    //             permissions && permissions,
                    //             "admin"
                    //           ) ||
                    //             Helper.getPermissions("", [], user.type)) && (
                    //             <NavLink
                    //               className=""
                    //               exact
                    //               to={`/admin/edit-vehicle-key/${record.id}`}
                    //             >
                    //               <button className="btn btnn-user btn-block btn-info">
                    //                 Edit
                    //               </button>
                    //             </NavLink>
                    //           )}
                    //         </div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                  ))
                ) : (
                  errorMsg && (
                    <div className="text-center ft-14 mt-3 font-weight-bold">
                      No Data Found...
                    </div>
                  )
                )}
              </div>
              <div className="row mt-3 ft-14">
                <div className="col-md-12">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                      {(
                        <Pagination
                          activePage={state.set_active_page}
                          totalItemsCount={result.total ? result.total : 1}
                          pageRangeDisplayed={5}
                          itemClass="page-item"
                          linkClass="page-link"
                          onChange={handlePageChange}
                          activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                        />
                      )}
                      <li className="pl-3">
                        {/* <form className="form-inline">
                          <label className="my-1 mr-2">Results on page:</label>
                          <select
                            className="form-control p-0"
                            style={{ height: "30px" }}
                            defaultValue={"10"}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </form> */}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          ) : (
            <div className="p-4">
              <div className="text-center ft-14 mt-3 font-weight-bold">
                You are not allowed to visit this screen...
              </div>
            </div>
          )}

      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
export default VehicleModelListing;
