import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class EditorConvertToHTML extends Component {
  constructor(props) {
    super(props);
    const contentBlocks = props.tem.data;
    this.state = contentBlocks;
  }
  render() {
    const editorContent = this.state;
    return (
      <div className="App">
        <CKEditor
          editor={ClassicEditor}
          data={editorContent}
          //config={editorConfiguration} // Pass the custom configuration here

          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            this.props.selTemplate(data);
            console.log({ event, editor, data });
          }}
          config={{
            toolbar: [
              "heading",
              "|",
              "bold",
              "strikethrough",
              "subscript",
              "superscript",
              "code",
              "italic",
              "underline",
              "|",
              "bulletedList",
              "numberedList",
              "todoList",
              "outdent",
              "indent",
              "|",
              "-",
              "link",
              "uploadImage",
              "blockQuote",
              "codeBlock",
              "|",
              "undo",
              "redo",
              "|",
              "fontfamily",
              "fontsize",
              "fontColor",
              "fontBackgroundColor",
            ],
          }}
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}
        />
      </div>
    );
  }
}
export default EditorConvertToHTML;
