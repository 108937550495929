import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import { Context } from "../Store/Store";

const Helper = require("../Helper");


var { apiRequest } = require("../Api/Service");
var {
    saveNotificationSettings,
    getNotificationTypes,
    getEmailTypes,

} = require("../Api/ApiRoutes");


const initialState = {
    isError: false,
    errors: [],
    isSuccess: false,
    success: "",
    disable: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                ...state,
                isSuccess: true,
                success: action.payload,
                disable: true,
            };
        case "FETCH_ERROR":
            return {
                ...state,
                isError: true,
                errors: action.payload,
                disable: false,
            };
        case "DEFAULT":
            return {
                disable: action.payload,
            };
        default:
            return state;
    }
};


function NotificationSettings() {
    let user = Helper.getStorageData("SESSION");

    const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
    const [emailTypeList, setEmailTypes] = React.useState([]);
    const [notificationTypeList, setNotificationTypes] = React.useState([]);
    const [saveData, setSaved] = React.useState(false);

    const handleEmailTypelList = (event) => {
        
        apiRequest(
            getEmailTypes.method,
            getEmailTypes.url
        )
        .then((response) => {
            setEmailTypes(response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });

        apiRequest(
            getNotificationTypes.method,
            getNotificationTypes.url
        )
        .then((response) => {
            setNotificationTypes(response.data.data);
        })
        .catch((error) => {
            console.log(error);
        });
    };
   
    useEffect(() => {
        handleEmailTypelList()
    }, [])


    const handleSubmit = (values) => {
        
    }

    const updateEmailTypeSetting = (e,id,type) => {
       
        const form_data = new FormData();
        form_data.append("type", type);
        form_data.append("id", id);
        form_data.append("status", e.target.value);
       

        Localdispatch({ type: "DEFAULT", payload: true });
        apiRequest(saveNotificationSettings.method, saveNotificationSettings.url, form_data)
            .then((response) => {
                setSaved(true);
                handleEmailTypelList();
                 Localdispatch({
                        type: "FETCH_SUCCESS",
                        payload: "Email/Notification settings updated successfully"
                        
                });
                setTimeout(() => {
                    //setSaved(false);
                }, 2000);
               
            })
            .catch((error) => {
                console.log(error);
                Localdispatch({
                    type: "FETCH_ERROR",
                    payload: error.response.data.data,
                });
                window.scrollTo(500, 0);
            });
    }

    return (
        <React.Fragment>
   
            <div id="content">
                <Header showSearch={false} showAdvanceSearch={false} />
              
                <div className="p-4">
                    
                    <h4 className="mb-3">Email/Notification Settings</h4>
                   
                    <Formik
                        validateOnChange={false}
                        onSubmit={async (values) => {
                            handleSubmit(values);
                        }}
                        initialValues={{
                            email_type_id: "",
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            setFieldValue,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                        }) => (
                            <Form className="mt-2 user">
                                <FlashMessage
                                    success={LocalState.success}
                                    isSuccess={LocalState.isSuccess}
                                    isError={LocalState.isError}
                                    errors={LocalState.errors}
                                />
                                
                                <div className="form-row">
                                    <div className="form-group col-md-5">
                                        
                                        
                                        <h5>Email Settings</h5>
                                       {emailTypeList &&
                                            emailTypeList.map((emailTypeList, index) => (
                                        <div>
                                        <label className="form-label">{emailTypeList.name}</label>
                                        <select
                                            name="email_type_id"
                                            key={index}
                                            value={emailTypeList.status}
                                            onChange={(e) => {
                                                values.email_type_id = ''
                                                handleChange(e);
                                                updateEmailTypeSetting(e,emailTypeList.id,'EMAIL');
                                            }}
                                         className={`form-control form-select-user dropdown-height`}    
                                        >
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                
                                        </select> 
         
                                        </div>
                                        ))}
                                            
                                    </div>
                                    <div className="form-group col-md-2"></div>
                                    <div className="form-group col-md-5">
                                        
                                        
                                        <h5>Notification Settings</h5>
                                       {notificationTypeList &&
                                            notificationTypeList.map((notificationTypeList, index) => (
                                        <div>
                                        <label className="form-label">{notificationTypeList.name}</label>
                                        <select
                                            name="email_type_id"
                                            key={index}
                                            value={notificationTypeList.status}
                                            onChange={(e) => {
                                                values.email_type_id = ''
                                                handleChange(e);
                                                updateEmailTypeSetting(e,notificationTypeList.id,'NOTIFICATION');
                                            }}
                                         className={`form-control form-select-user dropdown-height`}    
                                        >
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                
                                        </select> 
                                        </div>
                                        ))}
                                            
                                    </div>
                                    
                                </div>

                     
                                
                            </Form>
                        )}
                    </Formik>
                </div>
                
            </div>
        </React.Fragment>
    );
}

export default NotificationSettings;
