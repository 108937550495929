import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Helper from "../Helper";
import enablepush from "./enable-push.js";
import { Context } from "../Store/Store";

function lightenHexColor(hex, percent) {
  // Ensure the percent is between 0 and 100
  if (hex != null && hex != undefined) {
    percent = Math.min(100, Math.max(0, percent));

    // Remove the "#" symbol if it exists
    hex = hex.replace(/^#/, "");

    // Convert the hex color to its RGB components
    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);

    // Calculate the new RGB values by increasing each channel
    r += (255 - r) * (percent / 100);
    g += (255 - g) * (percent / 100);
    b += (255 - b) * (percent / 100);

    // Ensure the values are within the valid range (0-255)
    r = Math.min(255, r);
    g = Math.min(255, g);
    b = Math.min(255, b);

    // Convert the RGB values back to a hex color code
    const newHex = `#${Math.round(r)
      .toString(16)
      .padStart(2, "0")}${Math.round(g)
        .toString(16)
        .padStart(2, "0")}${Math.round(b).toString(16).padStart(2, "0")}`;

    return newHex;
  }
}

function Index() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isToggleOn, setIsToggleOn] = useState(false);

  const handleClick = () => {
    setIsToggleOn(!isToggleOn);
  };

  useEffect(() => {
    let fun = (e) => {
      navLinks.forEach((navLink) => {
        let navLinkId = navLink.getAttribute("data-target");

        if (navLinkId) {
          let collapsableDiv = document.querySelector(navLinkId);
          if (!navLink.classList.contains("collapsed") && isToggleOn)
            collapsableDiv.classList.remove("show");
        }
      });
    };

    let navLinks = document.querySelectorAll(".nav-link");

    document.addEventListener("click", (e) => fun(e));

    return () => {
      document.addEventListener("click", (e) => fun(e));
    };
  }, [isToggleOn]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [state, dispatch] = useContext(Context);

  function linerGradient() {
    let gradiant = "";

    if (user.type === "whitelabel" && +user.label_id === 11) {
      gradiant = `linear-gradient(180deg, ${user.background_color} 10%, rgb(214 243 57) 100%)`;
    } else {
      gradiant = `linear-gradient(180deg, ${user.background_color} 10%, ${user.background_color} 100%)`;
    }

    return gradiant;
  }



  React.useEffect(() => {
    enablepush();
  });
  const handleLogout = () => {
    localStorage.removeItem("SESSION");
    window.location.href = "/";
  };
  const handleSearchList = () => {
    dispatch({
      type: "SET_SEARCH_STATE",
      search_list: true,
      pagination: false,
      searchedName: null,
    });
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: 1 });
    dispatch({ type: "SET_USAGES_DATES", start_date: null, end_date: null });
  };

  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  const vehicleModelReadPermission = Helper.getPermissions(
    "vehicle_model-read",
    permissions && permissions,
    user.type
  );
  const vehicleCompanyReadPermission = Helper.getPermissions(
    "vehicle_company-read",
    permissions && permissions,
    user.type
  );
  const vehicleKeyReadPermission = Helper.getPermissions(
    "vehicle_key-read",
    permissions && permissions,
    user.type
  );

  let elements = document.querySelectorAll(".sideBarText");
  useEffect(() => {
    const shouldApplyStyle = windowWidth <= 767 || isToggleOn;

    elements.forEach(function (element) {
      if (shouldApplyStyle) {
        element.style.fontSize = "0.75rem";
      } else {
        element.style.fontSize = "";
      }
    });
  }, [elements, isToggleOn, windowWidth]);

  React.useEffect(() => {
    // Helper.loadScript('https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js');
    Helper.loadScript("/assets/js/sb-admin-2.js");
  }, []);



  return (
    <>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
        style={{
          backgroundColor: user.background_color,
          backgroundImage: linerGradient(),
        }}
      >
        {windowWidth <= 767 || isToggleOn ? (
          <div
            className="sidebar-brand-text mx-3 sidebar-brand position-relative"
            style={{ bottom: "12px" }}
          >
            <NavLink exact to="/admin/thankyou" className="nav-link">
              {user.type === "whitelabel" ?
                <img
                  src={
                    "https://play-lh.googleusercontent.com/I9DtXAoL6ehOjbEcFCIu3EE-Z55FylyXmIWOTWsc2nHBj4h_sgcsIw0bYt0illSKoA"
                  }
                  className="logo logo2"
                  alt="Logo"
                  width={50}
                  height={50}
                /> :
                <img src={user.thumbnail} className="logo logo2" alt="Logo" />}
            </NavLink>
          </div>
        ) : (
          <div className="sidebar-brand-text mx-3 sidebar-brand d-flex align-items-center ">
            <NavLink exact to="/admin/booking-active-list/1" className="nav-link">
              <img
                src={user.main_logo}
                className="img-fluid logo logo1  w-100 h-100"
                alt="Logo"
              />
            </NavLink>
          </div>
        )}

        <hr className="sidebar-divider my-0" />

        {user.type !== "whitelabel" && (
          <li className="nav-item">
            <a
              className="nav-link"
              href={
                Helper.getPermissions(
                  "vehicle-read",
                  permissions && permissions,
                  user.type
                )
                  ? "/admin/vehicle-list/1"
                  : "/admin/thankyou"
              }
            >
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span className="sideBarText">
                {" "}
                <b> Dashboard </b>
              </span>
            </a>
          </li>
        )}

        <hr className="sidebar-divider" />

        {/* <div className="sidebar-heading">
                Interface
            </div> */}

        {Helper.getPermissions(
          "vehicle-read",
          permissions && permissions,
          user.type
        ) && (
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="/"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i className="fas fa-car"></i>
                <span className="sideBarText">Vehicles</span>
              </a>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div
                  className="bg-orange-light  py-2 collapse-inner rounded"
                  style={{
                    backgroundColor: lightenHexColor(user.background_color, 10),
                  }}
                >
                  <NavLink
                    className="dropdown-item collapse-item"
                    exact
                    to="/admin/vehicle-list/1"
                    onClick={handleSearchList}
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    <span className="ft-12 text-white">Vehicles My List</span>
                  </NavLink>
                  {(user.type === "superadmin" || user.type === "admin") && (
                    <NavLink
                      className="dropdown-item collapse-item"
                      exact
                      to="/admin/all-vehicle-list/1"
                      onClick={handleSearchList}
                      activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                    >
                      <span className="ft-12 text-white">Vehicles All List</span>
                    </NavLink>
                  )}
                  {(user.type === "superadmin" || user.type === "admin") && (
                    <NavLink
                      className="dropdown-item collapse-item"
                      exact
                      to="/admin/vehicle-map-all-list"
                      activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                    >
                      {" "}
                      <span className="ft-12 text-white">Vehicles All Map</span>
                    </NavLink>
                  )}
                  <NavLink
                    className="dropdown-item collapse-item"
                    exact
                    to="/admin/vehicle-map-list"
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    {" "}
                    <span className="ft-12 text-white">Vehicles My Map</span>
                  </NavLink>
                  {(user.type === "superadmin" ||
                    user.type === "admin" ||
                    user.type === "operator") && (
                      <NavLink
                        className="dropdown-item collapse-item"
                        exact
                        to="/admin/shares-with-me-list"
                        onClick={handleSearchList}
                        activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                      >
                        <span className="ft-12 text-white">Shared With Me </span>
                      </NavLink>
                    )}
                </div>
              </div>
            </li>
          )}

        {Helper.getPermissions(
          "share-read",
          permissions && permissions,
          user.type
        ) && (
            <>
              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  href="/"
                  data-toggle="collapse"
                  data-target="#collapseUtilities"
                  aria-expanded="true"
                  aria-controls="collapseUtilities"
                >
                  <i className="fas fa-share-alt"></i>
                  <span className="sideBarText">Shares</span>
                </a>
                <div
                  id="collapseUtilities"
                  className="collapse"
                  aria-labelledby="headingUtilities"
                  data-parent="#accordionSidebar"
                >
                  <div
                    className="bg-orange-light py-2 collapse-inner rounded"
                    style={{
                      backgroundColor: lightenHexColor(user.background_color, 10),
                    }}
                  >
                    <NavLink
                      className="dropdown-item collapse-item"
                      exact
                      to="/admin/shares-my-list/1"
                      onClick={handleSearchList}
                      activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                    >
                      <span className="ft-12 text-white">Shares My List</span>
                    </NavLink>
                    {(user.type === "superadmin" || user.type === "admin") && (
                      <NavLink
                        className="dropdown-item collapse-item"
                        exact
                        to="/admin/shares-list/1"
                        onClick={handleSearchList}
                        activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                      >
                        <span className="ft-12 text-white">Shares All List</span>
                      </NavLink>
                    )}
                  </div>
                </div>
              </li>
              <hr className="sidebar-divider" />
            </>
          )}

        {/* {Helper.getPermissions(
          "usage-read",
          permissions && permissions,
          user.type) && user.type !== "whitelabel" && (
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="/"
                data-toggle="collapse"
                data-target="#usageCollapse"
                aria-expanded="true"
                aria-controls="usageCollapse"
              >
                <i className="fas fa-taxi"></i>
                <span>Usages</span>
              </a>
              <div
                id="usageCollapse"
                className="collapse"
                aria-labelledby="usageCollapse"
                data-parent="#accordionSidebar"
              >
                <div className="bg-orange-light py-2 collapse-inner rounded" style={{backgroundColor: lightenHexColor(user.background_color,10)}}>
                  <NavLink
                    className="dropdown-item collapse-item"
                    exact
                    to="/admin/usage-my-list/1"
                    onClick={handleSearchList}
                  >
                    <span className="ft-12 text-white">Usages My List</span>
                  </NavLink>
                  {(user.type === "superadmin" || user.type === "admin") && (
                    <NavLink
                      className="dropdown-item collapse-item"
                      exact
                      to="/admin/usage-list/1"
                      onClick={handleSearchList}
                    >
                      <span className="ft-12 text-white">Usages All List</span>
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )} */}

        {Helper.getPermissions(
          "user-read",
          permissions && permissions,
          user.type
        ) && (
            <>
              <li className="nav-item">
                <a
                  className="nav-link collapsed"
                  href="/"
                  data-toggle="collapse"
                  data-target="#userCollapse"
                  aria-expanded="true"
                  aria-controls="userCollapse"
                >
                  <i className="fas fa-user"></i>
                  <span className="sideBarText">Users</span>
                </a>
                <div
                  id="userCollapse"
                  className="collapse"
                  aria-labelledby="userCollapse"
                  data-parent="#accordionSidebar"
                >
                  <div
                    className="bg-orange-light py-2 collapse-inner rounded"
                    style={{
                      backgroundColor: lightenHexColor(user.background_color, 10),
                    }}
                  >
                    <NavLink
                      className="dropdown-item collapse-item"
                      exact
                      onClick={handleSearchList}
                      to="/admin/current-user-list/1"
                      activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                    >
                      <span className="ft-12 text-white">User My List</span>
                    </NavLink>
                    {(user.type === "superadmin" || user.type === "admin") && (
                      <NavLink
                        className="dropdown-item collapse-item"
                        exact
                        onClick={handleSearchList}
                        to="/admin/user-list/1"
                        activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                      >
                        <span className="ft-12 text-white">User All List</span>
                      </NavLink>
                    )}
                  </div>
                </div>
              </li>
              <hr className="sidebar-divider" />
            </>
          )}

        {/* {
          (user.type !== "operator") && (
          )
        }
         */}
        {user.type === "superadmin" && (
          <li className="nav-item">
            <NavLink
              exact
              to="/whitelabel/admin-list/1"
              onClick={handleSearchList}
              className="nav-link"
              activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
            >
              <span>
                <i className="fas fa-layer-group"></i>
              </span>
              <span className="sideBarText">Company admin list</span>
            </NavLink>
          </li>
        )}
        {Helper.getPermissions(
          "admin-read",
          permissions && permissions,
          user.type
        ) && (
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="/"
                data-toggle="collapse"
                data-target="#adminCollapse"
                aria-expanded="true"
                aria-controls="adminCollapse"
              >
                <i className="fas fa-user"></i>
                <span className="sideBarText">Admin</span>
              </a>
              <div
                id="adminCollapse"
                className="collapse"
                aria-labelledby="adminCollapse"
                data-parent="#accordionSidebar"
              >
                <div
                  className="bg-orange-light py-2 collapse-inner rounded"
                  style={{
                    backgroundColor: lightenHexColor(user.background_color, 10),
                  }}
                >
                  <NavLink
                    className="dropdown-item collapse-item"
                    exact
                    onClick={handleSearchList}
                    to="/admin/my-admin-user-list/1"
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}

                  >
                    <span className="ft-12 text-white">Admin My List</span>
                  </NavLink>
                  {(user.type === "superadmin" || user.type === "admin") && (
                    <NavLink
                      className="dropdown-item collapse-item"
                      exact
                      onClick={handleSearchList}
                      to="/admin/admin-user-list/1"
                      activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                    >
                      <span className="ft-12 text-white">Admin All List</span>
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}

        {user.type === "whitelabel" && (
          <li className="nav-item">
            <a
              className="nav-link collapsed"
              href="/"
              data-toggle="collapse"
              data-target="#whitelabeladminCollapse"
              aria-expanded="true"
              aria-controls="whitelabeladminCollapse"
            >
              <i className="fas fa-user"></i>
              <span className="sideBarText">Admin</span>
            </a>
            <div
              id="whitelabeladminCollapse"
              className="collapse"
              aria-labelledby="whitelabeladminCollapse"
              data-parent="#accordionSidebar"
            >
              <div
                className="bg-orange-light py-2 collapse-inner rounded"
                style={{
                  backgroundColor: lightenHexColor(user.background_color, 10),
                }}
              >
                <NavLink
                  className="dropdown-item collapse-item"
                  exact
                  onClick={handleSearchList}
                  to="/admin/whitelabel-admin-user-list/1"
                  activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                >
                  <span className="ft-12 text-white">Admin My List</span>
                </NavLink>
              </div>
            </div>
          </li>
        )}

        {Helper.getPermissions(
          "firmware-read",
          permissions && permissions,
          user.type
        ) && (
            <li className="nav-item">
              <NavLink
                exact
                to="/admin/software-upload-list/1"
                className="nav-link"
                onClick={handleSearchList}
                activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
              >
                <span>
                  <i className="fas fa-edit"></i>
                </span>
                <span className="sideBarText">Soft Upload</span>
              </NavLink>
            </li>
          )}

        {Helper.getPermissions(
          "bookings-read",
          permissions && permissions,
          user.type
        ) && (
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="/"
                data-toggle="collapse"
                data-target="#boookingCollapse"
                aria-expanded="true"
                aria-controls="boookingCollapse"
              >
                <i className="fas fa-book"></i>
                <span className="sideBarText">Booking</span>
              </a>
              <div
                id="boookingCollapse"
                className="collapse"
                aria-labelledby="boookingCollapse"
                data-parent="#accordionSidebar"
              >
                <div
                  className="bg-orange-light py-2 collapse-inner rounded"
                  style={{
                    backgroundColor: lightenHexColor(user.background_color, 10),
                  }}
                >

                  <NavLink
                    exact
                    to="/admin/booking-list/1"
                    className="dropdown-item collapse-item"
                    onClick={handleSearchList}
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    <span className="ft-12 text-white">All bookings</span>
                  </NavLink>


                  <NavLink
                    exact
                    to="/admin/booking-active-list/1"
                    className="dropdown-item collapse-item"
                    onClick={handleSearchList}
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    <span className="ft-12 text-white">Active bookings</span>
                  </NavLink>


                  <NavLink
                    exact
                    to="/admin/booking-future-list/1"
                    className="dropdown-item collapse-item"
                    onClick={handleSearchList}
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    <span className="ft-12 text-white">Future bookings</span>
                  </NavLink>


                  <NavLink
                    exact
                    to="/admin/booking-vehicle-return-list/1"
                    className="dropdown-item collapse-item"
                    onClick={handleSearchList}
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    <span className="ft-12 text-white">Vehicle is returned</span>
                  </NavLink>


                  <NavLink
                    exact
                    to="/admin/booking-expired-list/1"
                    className="dropdown-item collapse-item"
                    onClick={handleSearchList}
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    <span className="ft-12 text-white">Expired bookings</span>
                  </NavLink>

                </div>
              </div>
            </li>
          )}
        {Helper.getPermissions(
          "contract-read",
          permissions && permissions,
          user.type
        ) && (
            <li className="nav-item">
              <NavLink
                exact
                to="/admin/contract-listing/1"
                className="nav-link"
                onClick={handleSearchList}
                activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
              >
                <span>
                  <i className="fas fa-pen"></i>
                </span>
                <span>Contract</span>
              </NavLink>
            </li>
          )}

        {Helper.getPermissions(
          "extras-read",
          permissions && permissions,
          user.type
        ) && (
            <li className="nav-item">
              <NavLink
                exact
                to="/admin/extra"
                className="nav-link"
                onClick={handleSearchList}
                activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
              >
                <span>
                  <i className="fas fa-layer-group"></i>
                </span>
                <span className="sideBarText">Extra</span>
              </NavLink>
            </li>
          )}
        {Helper.getPermissions(
          "stations-read",
          permissions && permissions,
          user.type
        ) && (
            <li className="nav-item">
              <NavLink
                exact
                to="/admin/station-listing/1"
                className="nav-link"
                onClick={handleSearchList}
                activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
              >
                <span>
                  <i className="fas fa-map-pin"></i>
                </span>
                <span className="sideBarText">Stations</span>
              </NavLink>
            </li>
          )}

        {Helper.getPermissions(
          "device-read",
          permissions && permissions,
          user.type
        ) && (
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="/"
                data-toggle="collapse"
                data-target="#deviceCollapse"
                aria-expanded="true"
                aria-controls="userCollapse"
              >
                <i className="fas fa-cog"></i>
                <span className="sideBarText">Devices</span>
              </a>
              <div
                id="deviceCollapse"
                className="collapse"
                aria-labelledby="deviceCollapse"
                data-parent="#accordionSidebar"
              >
                <div
                  className="bg-orange-light py-2 collapse-inner rounded"
                  style={{
                    backgroundColor: lightenHexColor(user.background_color, 10),
                  }}
                >
                  <NavLink
                    exact
                    to="/admin/devices-code-list/1"
                    className="dropdown-item collapse-item"
                    onClick={handleSearchList}
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    <span className="ft-12 text-white">Devices</span>
                  </NavLink>
                  {(user.type === "superadmin" || user.type === "admin") && (
                    <NavLink
                      exact
                      to="/admin/mobi-1ot"
                      className="dropdown-item collapse-item"
                      onClick={handleSearchList}
                      activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                    >
                      <span className="ft-12 text-white">1ot</span>
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}
        {/* {user.type === "superadmin" && (
          <li className="nav-item">
            <NavLink
              exact
              to="/admin/email-templates/1"
              onClick={handleSearchList}
              className="nav-link"
            >
              <span>
                <i className="fas fa-envelope"></i>
              </span>
              <span className="sideBarText">Email Template</span>
            </NavLink>
          </li>
        )} */}

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="/"
            data-toggle="collapse"
            data-target="#companyCollapse"
            aria-expanded="true"
            aria-controls="userCollapse"
          >
            <i class="fas fa-building"></i>
            <span className="sideBarText">Company Settings</span>
          </a>
          <div
            id="companyCollapse"
            className="collapse"
            aria-labelledby="companyCollapse"
            data-parent="#accordionSidebar"
          >
            <div
              className="bg-orange-light py-2 collapse-inner rounded"
              style={{
                backgroundColor: lightenHexColor(user.background_color, 10),
              }}
            >


              {Helper.getPermissions(
                "whitelabel-read",
                permissions && permissions,
                user.type
              ) &&
                (user.type === "whitelabel" ? (
                  <NavLink
                    exact
                    to={`/admin/list-white-label-edit/${user.label_id}`}
                    className="dropdown-item collapse-item"
                    onClick={handleSearchList}
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    {/* <span>
                      <i className="fas fa-edit"></i>
                    </span> */}
                    <span className="ft-12 text-white">Company Details</span>
                  </NavLink>
                ) : (
                  <NavLink
                    exact
                    to="/admin/list-white-label/1"
                    className="dropdown-item collapse-item"
                    onClick={handleSearchList}
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    <span className="ft-12 text-white">Company Details</span>
                  </NavLink>
                ))}{" "}
              {Helper.getPermissions(
                "whitelabel-read",
                permissions && permissions,
                user.type
              ) && (user.type === "superadmin" ? (
                <NavLink
                  exact
                  to="/whitelabel/email-notification-settings/1"
                  className="dropdown-item collapse-item"
                  onClick={handleSearchList}
                  activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                >
                  {/* <span>
                  <i class="fas fa-cog"></i>
                </span> */}
                  <span className="ft-12 text-white"> Settings</span>
                </NavLink>
              ) : (""))}

              {Helper.getPermissions(
                "app_notification-read",
                permissions && permissions,
                user.type
              ) && (
                  <NavLink
                    exact
                    to="/whitelabel/app-notification/1"
                    className="dropdown-item collapse-item"
                    onClick={handleSearchList}
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    {/* <span>
                  <i class="fas fa-clipboard"></i>
                </span> */}
                    <span className="ft-12 text-white"> App notifications</span>
                  </NavLink>
                )}
              {Helper.getPermissions(
                "email_notification-read",
                permissions && permissions,
                user.type
              ) && (
                  <NavLink
                    exact
                    to="/admin/email-templates/1"
                    onClick={handleSearchList}
                    className="dropdown-item collapse-item"
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    {/* <span>
                  <i className="fas fa-envelope"></i>
                </span> */}
                    <span className="ft-12 text-white"> E-mail notifications </span>
                  </NavLink>
                )}
              {Helper.getPermissions(
                "privacy-read",
                permissions && permissions,
                user.type
              ) && (
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/admin/privacy-policy/1"
                      className="dropdown-item collapse-item"
                      onClick={handleSearchList}
                      activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                    >
                      {/* <span>
                        <i class="fas fa-file-alt"></i>
                      </span> */}
                      <span className="ft-12 text-white">Privacy policy</span>
                    </NavLink>
                  </li>
                )}
              {Helper.getPermissions(
                "terms_of_use-read",
                permissions && permissions,
                user.type
              ) && (
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/admin/terms-of-use/1"
                      className="dropdown-item collapse-item"
                      onClick={handleSearchList}
                      activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                    >
                      {/* <span>
                        <i class="fas fa-file-alt"></i>
                      </span> */}
                      <span className="ft-12 text-white">Terms Of Use</span>
                    </NavLink>
                  </li>
                )}
            </div>
          </div>
        </li>

        {(vehicleModelReadPermission ||
          vehicleCompanyReadPermission ||
          vehicleKeyReadPermission) && (
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="/"
                data-toggle="collapse"
                data-target="#dropdownMenuLink"
                aria-expanded="true"
                aria-controls="dropdownMenuLink"
              >
                <i className="fas fa-user"></i>
                <span className="sideBarText">Models, Marks & Keys</span>
              </a>
              <div
                id="dropdownMenuLink"
                className="collapse"
                aria-labelledby="dropdownMenuLink"
                data-parent="#accordionSidebar"
              >
                <div
                  className="bg-orange-light py-2 collapse-inner rounded"
                  style={{
                    backgroundColor: lightenHexColor(user.background_color, 10),
                  }}
                >
                  {vehicleCompanyReadPermission && (
                    <NavLink
                      className="dropdown-item collapse-item"
                      exact
                      to="/admin/company-vehicle-list/1"
                      onClick={handleSearchList}
                      activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                    >
                      <span className="ft-12 text-white">Company List</span>
                    </NavLink>
                  )}
                  {vehicleModelReadPermission && (
                    <NavLink
                      className="dropdown-item collapse-item"
                      exact
                      to="/admin/add-model-name-listing/1"
                      onClick={handleSearchList}
                      activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                    >
                      <span className="ft-12 text-white">Models List</span>
                    </NavLink>
                  )}

                  {vehicleKeyReadPermission && (
                    <NavLink
                      className="dropdown-item collapse-item"
                      exact
                      to="/admin/company-key-list/1"
                      onClick={handleSearchList}
                      activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                    >
                      <span className="ft-12 text-white">Keys List</span>
                    </NavLink>
                  )}
                </div>
              </div>
            </li>
          )}

        {Helper.getPermissions(
          "profile-update",
          permissions && permissions,
          user.type
        ) && (
            <li className="nav-item">
              <a
                className="nav-link collapsed"
                href="/"
                data-toggle="collapse"
                data-target="#settingsCollapse"
                aria-expanded="true"
                aria-controls="settingsCollapse"
              >
                <i className="fas fa-cog"></i>
                <span className="sideBarText">Settings</span>
              </a>
              <div
                id="settingsCollapse"
                className="collapse"
                aria-labelledby="settingsCollapse"
                data-parent="#accordionSidebar"
              >
                <div
                  className="bg-orange-light py-2 collapse-inner rounded"
                  style={{
                    backgroundColor: lightenHexColor(user.background_color, 10),
                  }}
                >
                  <NavLink
                    className="dropdown-item collapse-item"
                    exact
                    to="/admin/change-password"
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    <span className="ft-12 text-white">Change Password</span>
                  </NavLink>
                  <NavLink
                    className="dropdown-item collapse-item"
                    exact
                    to="/admin/update-phone"
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    <span className="ft-12  text-white">Phone Update</span>
                  </NavLink>
                  <NavLink
                    className="dropdown-item collapse-item"
                    exact
                    to="/admin/edit-email"
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    {" "}
                    <span className="ft-12  text-white">Email Update</span>
                  </NavLink>
                  <NavLink
                    className="dropdown-item collapse-item"
                    exact
                    to="/admin/add-detail"
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    {" "}
                    <span className="ft-12  text-white">My Details</span>
                  </NavLink>

                  <NavLink
                    className="dropdown-item collapse-item"
                    exact
                    to="/admin/key-regenrate"
                    activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                  >
                    {" "}
                    <span className="ft-12  text-white">API Key</span>
                  </NavLink>
                  {Helper.getPermissions(
                    "alarm_settings-update",
                    permissions && permissions,
                    user.type
                  ) && (
                      <NavLink
                        className="dropdown-item collapse-item"
                        exact
                        to="/admin/alarm-settings"
                        activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
                      >
                        {" "}
                        <span className="ft-12  text-white">Alarm Settings</span>
                      </NavLink>
                    )}
                </div>
              </div>
            </li>
          )}

        {Helper.getPermissions(
          "help_and_support-read",
          permissions && permissions,
          user.type
        ) && (
            <li className="nav-item">
              <NavLink
                exact
                to="/admin/help-and-support/1"
                className="nav-link"
                onClick={handleSearchList}
                activeClassName={user.type === "whitelabel" ? "active-whitelabel" : "active-default"}
              >
                <span>
                  <i className="fas fa-edit"></i>
                </span>
                <span className="sideBarText">Help And Support</span>
              </NavLink>
            </li>
          )}

        {/* <li className="nav-item active">
                <a className="nav-link" href="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="true"
                    aria-controls="collapsePages">
                    <i className="fas fa-fw fa-folder"></i>
                    <span>Pages</span>
                </a>
                <div id="collapsePages" className="collapse show" aria-labelledby="headingPages"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Login Screens:</h6>
                        <a className="collapse-item" href="login.html">Login</a>
                        <a className="collapse-item" href="register.html">Register</a>
                        <a className="collapse-item" href="forgot-password.html">Forgot Password</a>
                        <div className="collapse-divider"></div>
                        <h6 className="collapse-header">Other Pages:</h6>
                        <a className="collapse-item" href="404.html">404 Page</a>
                        <a className="collapse-item active" href="blank.html">Blank Page</a>
                    </div>
                </div>
            </li> */}

        <li className="nav-item ">
          <a onClick={handleLogout} className="nav-link">
            <span>
              <i className="fas fa-sign-out-alt"></i>
            </span>
            <span className="sideBarText">Logout</span>
          </a>
        </li>
        {/* <li className="nav-item">
                <a className="nav-link" href="charts.html">
                    <i className="fas fa-fw fa-chart-area"></i>
                    <span>Charts</span></a>
            </li>

        
            <li className="nav-item">
                <a className="nav-link" href="tables.html">
                    <i className="fas fa-fw fa-table"></i>
                    <span>Tables</span></a>
            </li> */}

        <hr className="sidebar-divider d-none d-md-block" />

        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={handleClick}
          ></button>
        </div>
      </ul>
    </>
  );
}

export default Index;
