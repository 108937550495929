import React, { useEffect, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../Header/Index.js";
import { Context } from "../Store/Store";
import { Modal, Button, Icon } from "rsuite";
import Pagination from "react-js-pagination";
import Helper from "../Helper";
import { useHistory } from 'react-router-dom';
var {
  SoftwareListAPI,
  SoftwareDeleteAPI,
  alldeleteUser,
} = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");

function SoftwareUploadList() {
  const [records, setrecords] = React.useState([]);
  const [result, setResult] = React.useState({});
  const [rsModal, setRsModal] = React.useState(false);
  const [hidePagination, sethidePagination] = React.useState(false);
  const [state, dispatch] = React.useContext(Context)
  const [currentId, setCurrentId] = React.useState(null);
  const [locationKeys, setLocationKeys] = React.useState([]);
  const [sort_by, setSortBy] = React.useState(1);
  const [errorMsg, setErrorMsg] = React.useState("");

  const history = useHistory()
  const Modalclose = () => {
    setRsModal(false);
  };
  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };
  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  var location = useLocation()

  const getSoftUploadList = useCallback((page, sort) => {
    if (
      Helper.getPermissions(
        "firmware-read",
        permissions && permissions,
        "admin"
      ) ||
      user.type === "superadmin"
    ) {

      Helper.overlay(true);

      apiRequest(SoftwareListAPI.method, SoftwareListAPI.url + `?page=${page}&sort_by=${sort ?? sort_by}`)
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data.data;
            setrecords(data);
            dispatch({ type: "SET_SEARCH_STATE", search_list: false });
            setResult(response.data.meta);
            sethidePagination(false);
            Helper.overlay(false);
          }
        })
        .catch((error) => {
          Helper.overlay(false);
        });
    }
  }, [dispatch, permissions, sort_by, user.type]);

  useEffect(() => {
    if (location.hash !== "#search") {
      dispatch({ type: "SET_STATE", response: [] });
      getSoftUploadList(state.set_active_page);
    }
    else {
      Helper.overlay(false);
    }
  }, [dispatch, location.hash, state.set_active_page]);

  // useEffect(() => {
  //   if (state.pagination === true) {
  //     setrecords(state.data.data)
  //     setResult(state.data.meta)
  //     sethidePagination(true);
  //   } else {
  //     sethidePagination(false);
  //   }
  // }, [state.data.data, state.data.meta, state.pagination]);

  useEffect(() => {
    if (location.pathname.includes('software-upload-list') && location.hash === "#search") {
      setrecords(state.data.data || [])
      setResult(state.data.meta || [])
      sethidePagination(true);
      dispatch({ type: "SET_SEARCH_STATE", pagination: false });
      Helper.overlay(false)

    }

    if (location.hash === "#error") {
      setrecords([]);
      setResult([]);
      sethidePagination(true);
      setErrorMsg('No record Found');
    }
  }, [dispatch, location, state.data, state.pagination]);
  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          let str = location.pathname
          var id = str.substr(str.length - 1)
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          getSoftUploadList(+id);
          setLocationKeys(([_, ...keys]) => keys)
        } else {
          let str = location.pathname
          let id = str.substr(str.length - 1)
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          getSoftUploadList(+id);
          setLocationKeys((keys) => [location.key, ...keys])
        }
      }
    })
  }, [dispatch, history, locationKeys])

  // eslint-disable-next-line no-unused-vars
  function getcheckbox() {
    var array = [];
    var checkboxes = document.querySelectorAll(
      "input[name='subcheckbox']:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }

    let params = new FormData();
    params.append("ids", array);
    let delete_property = window.confirm(
      "Are you sure you want to delete these records!"
    );
    if (delete_property) {
      apiRequest(alldeleteUser.method, alldeleteUser.url, params)
        .then((response) => {
          if (response.data.code === 200) {
            let data = state.data.filter((d) => {
              return d.id !== currentId
            })
            dispatch({ type: "SET_STATE", response: data });
            setRsModal(false);
          } else {
            console.log("Failure", response);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  // eslint-disable-next-line no-unused-vars
  function handleMainCheckBox(e) {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    Array.from(document.querySelectorAll("input[name='subcheckbox']")).forEach(
      (checkbox) => {
        checkbox.checked = value;
      }
    );
  }

  const deleteRecord = (e) => {
    //let params = new FormData();
    //params.append("id", e.target.dataset.id);
    // let delete_property = window.confirm(
    //   "Are you sure you want to delete this record!"
    // );
    // if (delete_property) {
    apiRequest(SoftwareDeleteAPI.method, SoftwareDeleteAPI.url + `${currentId}`)
      .then((response) => {
        if (response.data.code === 200) {
          getSoftUploadList(state.set_active_page)
          setRsModal(false);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        alert(error);
      });
    //}
  };

  const handlePageChange = (pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    Helper.overlay(true);
    apiRequest(
      SoftwareListAPI.method,
      SoftwareListAPI.url + `?page=${pageNumber}&sort_by=${sort_by}`
    )
      .then((response) => {
        let data = response.data.data;
        if (response.data.code === 200) {
          dispatch({ type: "SET_STATE", response: data });
          setrecords(response.data.data);
          setResult(response.data.meta);
          Helper.overlay(false);
          history.push(`/admin/software-upload-list/${pageNumber}`);
        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  };

  const setDropDown = (e) => {
    setSortBy(e.target.value)
    getSoftUploadList(state.set_active_page, e.target.value)
  }

  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header />
        {Helper.getPermissions(
          "firmware-read",
          permissions && permissions,
          "admin"
        ) || Helper.getPermissions("", [], user.type) ? (
          <div className="p-4 ">
            <div className="row d-flex justify-content-between align-items-center border-bottom">
              <div className="col-md-6 col-lg-8">
                <div className="d-flex flex-row mb-3">
                  {
                    <div className="p-2">
                      {(Helper.getPermissions(
                        "firmware-create",
                        permissions && permissions,
                        "admin"
                      ) ||
                        Helper.getPermissions("", [], user.type)) && (
                          <NavLink
                            exact
                            to={`/admin/software-upload-add`}
                            className="btn btnn-user white bg-orange" style={{backgroundColor: user.background_color}}
                          >
                            UPLOAD FIRMWARE
                          </NavLink>
                        )}
                    </div>
                  }
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className=" d-flex row align-items-center">
                  <div className="col-md-12 col-lg-3 pl-lg-0 pr-lg-0">Results: {result.total ? result.total : '1'}</div>
                  <div className="col-md-12 col-lg-9 pl-lg-0 pr-lg-0 row">
                    <label class="col-3 mt-auto">Sort By:</label>
                    <select className="form-select form-control col-8" aria-label="Default select example" name="sort_by" onChange={setDropDown}>
                      <option selected={sort_by === 1 ? true : false} value={1}>Last created on top</option>
                      <option selected={sort_by === 2 ? true : false} value={2}>First created on top</option>
                      <option selected={sort_by === 3 ? true : false} value={3}>Latest Version</option>
                      <option selected={sort_by === 4 ? true : false} value={4}>Old Version</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {records.length ? (
                records.map((record, index) => (
                  <div
                    className="card col-12 mt-3 shadow mb-2 bg-white rounded"
                    key={index}
                  >
                    <div className="row">
                      <div className="col-md-6 pr-md-5 col-lg-1 pt-2 pt-md-2 pl-md-4 pt-lg-0 col-sm-2 my-auto">
                        <a
                          href={record.path}
                          download
                          target="_blank"
                          className="text-center"
                          rel="noreferrer"
                        >
                          <i
                            className="fa fa-download fa-3x"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                      <div className="col-md-10 col-lg-9 col-10 pl-md-4 pb-3 pt-4">
                        <div className="row ">
                          <div className="col-12">
                            <input
                              type="checkbox"
                              className=""
                              name="subcheckbox"
                              defaultValue={record.id}
                              id="exampleCheck1"
                            />
                            <NavLink
                              className="font-weight-bold ml-3 ml-3"
                              exact
                              to={`/admin/edit-software-upload/${record.id}`}
                            >{record.id}

                            </NavLink>
                          </div>
                          <div className="col-md-12 col-lg-12 col-sm-10">
                            <div className="row pt-2">
                              <div className="col-lg-3 col-md-6 col-12">
                                Software version : <strong>{record.version}</strong>
                              </div>
                              <div className="col-lg-2 col-md-6 col-12">hardware
                                CRC:
                                <strong> 4A 6D</strong>
                              </div>
                              <div className="col-lg-2 col-md-6 col-12">
                                For hardware:
                                <strong> {record.hardware}</strong>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12">
                                Upload date:
                                <strong> {record.upload_date ? <>{Helper.getDateByTZ(record.upload_date)} ({user?.timezone ? user.timezone : Helper.localTimezone()})</> : '-'}</strong>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                      <div className="col-md-12 col-sm-12 col-lg-2 ">
                        <div className="row pb-md-3 pb-2">
                          <div className="col-md-6  col-sm-4 col-lg-12 pt-lg-4">
                            {(Helper.getPermissions(
                              "firmware-update",
                              permissions && permissions,
                              "admin"
                            ) ||
                              Helper.getPermissions("", [], user.type)) && (
                                <NavLink
                                  className="black"
                                  exact
                                  to={`/admin/edit-software-upload/${record.id}`}
                                >
                                  <button className="btn-success w-100">
                                    Edit
                                  </button>
                                </NavLink>
                              )}
                          </div>

                          <div className="col-md-6  col-sm-4 col-lg-12 pt-lg-2 pt-md-0 pt-2">
                            {(Helper.getPermissions(
                              "firmware-delete",
                              permissions && permissions,
                              "admin"
                            ) ||
                              Helper.getPermissions("", [], user.type)) && (
                                <button
                                  className="btn-danger w-100"
                                  data-id={record.id}
                                  onClick={Modalopen}
                                >
                                  Delete
                                </button>
                              )}
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                ))
              ) : (
                errorMsg && (
                  <div className="text-center ft-14 mt-3 font-weight-bold">
                    No Data Found...
                  </div>
                )
              )}
            </div>
            <div className="row mt-3 ft-14">
              <div className="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    {!hidePagination && (
                      <Pagination
                        activePage={state.set_active_page}
                        totalItemsCount={result.total ? result.total : 1}
                        pageRangeDisplayed={result.last_page}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handlePageChange}
                        activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                      />
                    )}
                    <li className="pl-3">
                      {/* <form className="form-inline">
                          <label className="my-1 mr-2">Results on page:</label>
                          <select
                            className="form-control p-0"
                            style={{ height: "30px" }}
                            defaultValue={"10"}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </form> */}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-4">
            <div className="text-center ft-14 mt-3 font-weight-bold">
              You are not allowed to visit this screen...
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
export default SoftwareUploadList;
