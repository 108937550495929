import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import { Context } from "../Store/Store";
import Terminal, { ColorMode, TerminalOutput } from "react-terminal-ui";
import Echo from "laravel-echo";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FaRedoAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Modal, Button, Icon } from "rsuite";
import Pagination from "react-js-pagination";
var { apiRequest } = require("../Api/Service");
window.Pusher = require("pusher-js");
var {
  UserPermissions,
  HardwareList,
  DeviceEditAPI,
  DeviceUpdateAPI,
  GenerateSerialEncryptionKey,
  UploadSerialConfigurationApi,
  UpdateSerialConfigurationApi,
  deviceDeleteQueueAPI,
  deviceQueueListAPI,
  sendCommandApi,
  broadcastAuth,
  UpdateSoftwaree,
  updateDeviceWakeup,
} = require("../Api/ApiRoutes");
const Helper = require("../Helper");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};
const FileSchema = Yup.object().shape({
  file: Yup.string().required("This Field is Required"),
});

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function EditDevicesCode(props) {
  const DetailSchema = Yup.object().shape({
    //serial: Yup.string().required("This Field is Required"),
    //device: Yup.mixed().required("This Field is Required"),
    //manufacturing_date:Yup.mixed().required(),
  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);
  const [userpermissions, setPermissions] = React.useState([]);
  const [record, setRecord] = React.useState(undefined);
  const [queuerecord, setQueueRecord] = React.useState([]);
  const [queuePaginationResult, setQueuePaginationResult] = React.useState({});
  const [sethardwarelist, setHardwarelist] = React.useState([]);
  const [keyValue, setKeyValue] = React.useState("");
  const [firmwareResponse, setFirmwareResponse] = React.useState(null);
  const [firmwareStatus, setFirmwareStatus] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [state, dispatch] = React.useContext(Context);
  const [loader, setloader] = React.useState(false);
  const [UploadConf, setUploadConf] = React.useState(false);
  const [rsModal, setRsModal] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(null);
  const [windowVar, setwindowVar] = React.useState(false);
  const [terminalLineData, setTerminalLineData] = React.useState([
    <TerminalOutput>Movitronic Terminal!&#128075;</TerminalOutput>,
    <TerminalOutput></TerminalOutput>,
    <TerminalOutput>'clear' will clear the terminal.</TerminalOutput>,
  ]);
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();
  const [serial, setSerial] = useState("");
  var user = Helper.getStorageData("SESSION");
  // eslint-disable-next-line no-unused-vars
  var user_permissions = user.permissions;
  let id = props.match.params.id;

  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };
  const Modalclose = () => {
    setRsModal(false);
  };
  const generateKey = () => {
    apiRequest(
      GenerateSerialEncryptionKey.method,
      GenerateSerialEncryptionKey.url + "?serial=" + record.serial
    ).then((response) => {
      if (response.status === 200) {
        let key = response.data.data.key;
        setKeyValue(key);
      }
    });
  };
  const deleteRecord = (e) => {
    apiRequest(
      deviceDeleteQueueAPI.method,
      deviceDeleteQueueAPI.url + `${currentId}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          let data = queuerecord.filter((d) => {
            return d.id !== currentId;
          });
          setQueueRecord(data);
          setRsModal(false);
          console.log("Success", response);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log("error", error);
        alert(error);
      });
  };
  const deviceWakeup = (values) => {
    const form_data = new FormData();
    form_data.append("device_code", record.serial);
    form_data.append("minute", values.minutes);
    apiRequest(updateDeviceWakeup.method, updateDeviceWakeup.url, form_data)
      .then((response) => {
        if (response.status === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            //setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.message,
        });
        window.scrollTo(500, 0);
      });
  };

  // eslint-disable-next-line no-unused-vars
  let loading = [...terminalLineData];
  var ld = [...terminalLineData];


  const UpdateSoftware = (serial_id) => {
    let params = new FormData();
    params.append("serial", serial_id);
    Helper.overlay(true);
    apiRequest(UpdateSoftwaree.method, UpdateSoftwaree.url, params)
      .then((response) => {
        let data = response.data;
        if (response.data.code === 200) {
          console.log("Success", response);
          dispatch({ type: "SET_STATE", response: data, advanceSearch: true });
          Helper.overlay(false);
        } else {
          Localdispatch({
            type: "FETCH_ERROR",
            payload: response.data.message,
          });
          setErrorMsg(response.data.message);
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.message,
        });
        console.log("FETCH_ERROR");
        Helper.overlay(false);
        setErrorMsg(error.response.data.message);
      });
  };

  const TerminalPrintw = (e) => {
    var eStr = "";
    var dash = "";

    let eObj = e.data.data;

    let stringObject = JSON.stringify(eObj);

    for (var i in eObj) {
      eStr += " | " + i + " : " + eObj[i];
    }

    for (let i = 0; i < stringObject.length + 40; i++) {
      dash += "-";
    }

    let output = "";
    output += " " + dash + "\n";
    output += "  | Command : " + e.data.command + " |  Code : " + e.data.code +
      eStr +
      " | \n";
    output += "  " + dash + "-\n";
    TerminalPrint(output);
  };


  React.useEffect(() => {

    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "app",
      wsHost: "dev.movitronic.com",
      wsPort: 6001,
      wssPort: 6001,
      forceTLS: true,
      useTLS: true,
      disableStats: true,
      encrypted: true,
      transports: ["websocket", "polling", "flashsocket"],
      authEndpoint: broadcastAuth.url,
      auth: { headers: { Authorization: "Bearer " + user.access_token } },
    });

    if (windowVar === false) {
      setwindowVar(true);

      let shellCmdKey = ".shell-response-" + id;

      window.Echo.private("movitronic").listen(shellCmdKey, (e) => {

        TerminalPrintw(e);
      });
    }
    window.Echo.private("movitronic").listen(
      ".firmware-progress-" + serial,
      (e) => {
        if (e.status === 3) {
          setFirmwareResponse(e.progress);
          setFirmwareStatus(e.status);
        } else if (e.status === 2) {
          setFirmwareStatus(e.status);
          setErrorMsg(
            "Unable to complete firmware update at the moment. Please try again"
          );
        } else if (e.status === 1) {
          setFirmwareStatus(e.status);
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: "Firmware updated",
          });
        }
      }
    );

    window.Echo.private("movitronic").listen(
      ".device-config-progress-" + serial,
      (e) => {
        if (e.status === 3) {
          setFirmwareResponse(e.progress);
          setFirmwareStatus(e.status);
        } else if (e.status === 2) {
          setFirmwareStatus(e.status);
          setErrorMsg(
            "Unable to complete the upload configuration at the moment. Please try again"
          );
        } else if (e.status === 1) {
          setFirmwareStatus(e.status);
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: "Configuration uploaded",
          });
        }
      }
    );



  }, [serial, id, user.access_token, TerminalPrintw, setErrorMsg, windowVar]);



  useEffect(() => {

    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          dispatch({ type: "SET_SEARCH_STATE", pagination: true });
        } else {
          dispatch({ type: "SET_SEARCH_STATE", pagination: true });
        }
      }
    });
  }, [dispatch, history, locationKeys, TerminalPrintw]);

  const TerminalPrint = (str) => {
    // ld.push(<TerminalOutput>$ {str}</TerminalOutput>);

    // setTerminalLineData(ld);
    console.log(ld);

    setTerminalLineData((terminalLineData) => [
      ...terminalLineData,
      <TerminalOutput>$ {str}</TerminalOutput>,
    ]);
  };
  const lofingPrint = (str) => {
    //loading.push(<TerminalOutput>$ {str}</TerminalOutput>);
    //setTerminalLineData(loading);
  };
  const onInput = (input) => {
    // ld.push(<TerminalOutput>$ {input}</TerminalOutput>);
    // setTerminalLineData(ld);
    TerminalPrint(input);

    if (input === "") {
      TerminalPrint("Unrecognized command");
    } else if (input.toLocaleLowerCase().trim() === "clear") {
      setTerminalLineData([]);
    } else {
      lofingPrint("Loading...");
      const form_data = new FormData();
      form_data.append("device_code", record?.serial);
      form_data.append("command", input);

      apiRequest(sendCommandApi.method, sendCommandApi.url, form_data)
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            //setTerminalLineData(ld);
          } else {
            let er = "Error: " + response.status;
            TerminalPrint(er);
          }
        })
        .catch((error) => {
          let er = "Error: " + error.response.data.message;
          TerminalPrint(er);
        });
    }
  };

  const handleQueueCommandsPageChange = (pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
        var params = "";
    params = `?device_code=${serial}&page=${pageNumber}`;
    Helper.overlay(true);
    apiRequest(
      deviceQueueListAPI.method,
      deviceQueueListAPI.url + params
    )
      .then((response) => {
        if (response.data.code === 200) {
        setQueueRecord(response.data.data);
          setQueuePaginationResult(response.data.meta)
          Helper.overlay(false);
        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  };

  const getDeviceQueue = (values) => {
    var params = "";
    params = "?device_code=" + values;
    apiRequest(deviceQueueListAPI.method, deviceQueueListAPI.url + params)
      .then((response) => {
        let data = response.data;
        setQueueRecord(data.data);
        setQueuePaginationResult(data.meta)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    // if(Helper.getPermissions(
    //   "share-update",
    //   user_permissions && user_permissions,
    //   "admin"
    // ) || (Helper.getPermissions('',[], user.type ))){
    var params = "";
    if (state.call_one === true) {
      params = "?call_one=" + state.call_one;
    }
    apiRequest(DeviceEditAPI.method, DeviceEditAPI.url + id + params)
      .then((response) => {
        let data = response.data.data;
        setRecord(data);
        setSerial(data?.serial);
        setKeyValue(response.data.data.key);
        getDeviceQueue(data?.serial)
        if (response.data.code === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, state.call_one]);

  const handleSubmit = (values) => {
    // const form_data = Helper.formData(values);
    const form_data = new FormData();
    form_data.append("id", id);
    form_data.append("serial", values.serial);
    form_data.append("device", values.device);
    form_data.append("manufacturing_date", Helper.convertToUTC(values.manufacturing_date));
    form_data.append("hardware_id", values.hardware_id);
    form_data.append("server_fallback_path", values.server_fallback_path);
    form_data.append("server_path", values.server_path);
    form_data.append("heartbeat_time", values.heartbeat_time);
    // for (let index = 0; index < values.hardwarelist.length; index++) {
    //   form_data.append('hardware_id', values.hardwarelist[index])
    // }

    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(DeviceUpdateAPI.method, DeviceUpdateAPI.url + id, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });

          setTimeout(() => {
            //setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  const uploadConfig = (values) => {
    const form_data = Helper.formData(values);
    form_data.append("serial", record.serial);
    setloader(true);
    Helper.overlay(true);
    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(
      UploadSerialConfigurationApi.method,
      UploadSerialConfigurationApi.url,
      form_data
    )
      .then((response) => {
        if (response.data.code === 200) {
          setUploadConf(true);
          window.scrollTo(500, 0);
          setloader(false);
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        setUploadConf(false);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
        setloader(false);
        Helper.overlay(false);
        setErrorMsg(error.response.message);
      });
  };
  const UpdateConfiguration = (values) => {
    const form_data = Helper.formData(values);
    form_data.append("serial", values);
    setloader(true);
    Helper.overlay(true);
    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(
      UpdateSerialConfigurationApi.method,
      UpdateSerialConfigurationApi.url,
      form_data
    )
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          window.scrollTo(500, 0);
          setloader(false);
          Helper.overlay(false);
        } else {
        }
      })
      .catch((error) => {
        console.log(error.response);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.message,
        });
        window.scrollTo(500, 0);
        setloader(false);
        Helper.overlay(false);
        setErrorMsg(error.response.data.message);
      });
  };
  useEffect(() => {
    apiRequest(UserPermissions.method, UserPermissions.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setPermissions(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setPermissions]);

  useEffect(() => {
    apiRequest(HardwareList.method, HardwareList.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setHardwarelist(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  if (redirectToReferrer) {
    return <Redirect push to="/admin/devices-code-list/1" />;
  }

  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        {/* {Helper.getPermissions(
            "admin-create",
            user_permissions && user_permissions,
            "admin"
          ) || Helper.getPermissions("", [], user.type) ? ( */}
        <div className="pt-4 pl-4 pr-4 pb-2">
          {record && (
            <Formik
              validateOnChange={false}
              validationSchema={DetailSchema}
              onSubmit={async (values) => {
                // console.log("permissions: ", values);
                handleSubmit(values);
              }}

              initialValues={{
                serial: record.serial,
                device: record.device,
                manufacturing_date: record.manufacturing_date,
                // hardwarelist:[],
                hardware_id: record.hardware_id,
                server_fallback_path: record.server_fallback_path,
                server_path: record.server_path,
                heartbeat_time: record.heartbeat_time,
                last_online: record.last_online,
                last_lat: record.last_location.lat,
                last_long: record.last_location.long,
                software_version: record.software_version,
                can_update: record.can_update,


                last_lat: record.last_lat,
                last_long: record.last_long,
                fuel: record.fuel,
                engine: record.engine,
                battery_voltage: record.battery_voltage,
                license: record.license,
                last_usage: record.last_usage,
                previos_usage: record.usages.previos_usage.created_at,
                current_user: record.usages.current_user.name,
                current_usage: record.usages.current_usage.created_at,
                year: record.year

              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form className="mt-2 user">
                  <FlashMessage
                    success={LocalState.success}
                    isSuccess={LocalState.isSuccess}
                    isError={LocalState.isError}
                    errors={LocalState.errors}
                  />
                  <div className="form-row">
                    <div className="form-group d-flex align-items-end">
                      <h4 className="mr-5">Edit Device Code</h4>
                      {values.can_update && (
                        <button
                          type="button"
                          className="btn-submit btn-sm pl-3 pr-3"
                          style={{ backgroundColor: user.background_color }}
                          onClick={(e) => UpdateSoftware(values.serial)}
                        >
                          Update Firmware
                        </button>
                      )}
                    </div>
                  </div>
                  {firmwareStatus === 3 ? (
                    <div className="row  d-flex align-items-center">
                      <div className="col-md-11">
                        <ProgressBar
                          animated
                          variant="warning"
                          now={firmwareResponse}
                        />
                      </div>
                      <div className="col-md-1 d-flex">
                        <button
                          className="btn btn-primary"
                          style={{ height: "40px", width: "42px" }}
                          type="button"
                          onClick={(e) => UpdateSoftware(values.serial)}
                        >
                          <FaRedoAlt />
                        </button>
                      </div>
                    </div>
                  ) : <></>}
                  <div className="form-row pb-3 pt-2">
                    <div className="col-3">
                      Last online:{" "}
                      <strong>
                        {values.last_online ? values.last_online : "-"}{" "}
                      </strong>
                    </div>
                    <div className="col-3">
                      software_version:{" "}
                      <strong>
                        {" "}
                        {values.software_version
                          ? values.software_version
                          : "-"}{" "}
                      </strong>
                    </div>

                    <div className="col-6 ">
                      Last Location:{" "}
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${values.last_lat},${values.last_long}`}
                        target={`blank`}
                      >
                        {values.last_lat && values.last_long
                          ? values.last_lat + " " + values.last_long
                          : "-"}
                      </a>
                    </div>
                    <div className="col-3">
                      Fuel level :{" "}
                      <strong>
                        {values.fuel ? values.fuel : "-"}{" "}
                      </strong>
                    </div>
                    <div className="col-3">
                      Engine :{" "}
                      <strong>
                        {values.engine ? values.engine : "-"}
                      </strong>
                    </div>
                    <div className="col-3">
                      Internal battery :{" "}
                      <strong>
                        {values.battery_voltage ? values.battery_voltage : "-"}
                      </strong>
                    </div>
                    <div className="col-3">
                      Year :{" "}
                      <strong>
                        {values.year}
                      </strong>
                    </div>
                    <div className="col-3">
                      License :{" "}
                      <strong>
                        {values.license}
                      </strong>
                    </div>
                    <div className="col-3">
                      Last usage :{" "}
                      <strong>
                        {values.last_usage ? <u>{Helper.getDateByTZ(values.last_usage)} ({user?.timezone ? user.timezone : Helper.localTimezone()})</u> : "-"} 
                      </strong>
                    </div>
                    <div className="col-3">
                      Previous usage :{" "}
                      <strong>
                        {values?.previos_usage ? <u>{Helper.getDateByTZ(values.previos_usage)} ({user?.timezone ? user.timezone : Helper.localTimezone()})</u> : "-"}
                      </strong>
                    </div>
                    <div className="col-3">
                      Current user: :{" "}
                      <strong>
                        {values?.current_user ? values.current_user : "-"}
                      </strong>
                    </div>
                    <div className="col-3">
                      Current usage :{" "}
                      <strong>
                        {values?.current_usage ? <u>{Helper.getDateByTZ(values.current_usage)} ({user?.timezone ? user.timezone : Helper.localTimezone()})</u> : "-"}
                      </strong>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Device Code#</label>
                      <input
                        type="text"
                        name="serial"
                        value={values.serial}
                        onChange={handleChange}
                        placeholder="Device Code"
                        className={`form-control form-control-user ${errors.serial ? "error" : ""
                          }`}
                      />
                      {errors.serial && (
                        <div className="ft-14 mt-1 red">{errors.serial}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Device/Product</label>
                      <input
                        type="text"
                        name="device"
                        value={values.device}
                        onChange={handleChange}
                        placeholder="Device Product"
                        className={`form-control form-control-user ${errors.device ? "error" : ""
                          }`}
                      />
                      {errors.device && (
                        <div className="ft-14 mt-1 red">{errors.device}</div>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Hardware Version List
                      </label>

                      <select
                        className={`form-control hideArrow form-select-user ${errors.id ? "error" : ""
                          }`}
                        name="hardware_id"
                        defaultValue={values.version}
                        value={values.hardware_id}
                        onChange={(e) => {
                          //   setkeyList([]);
                          //   handleCar(e);
                          handleChange(e);
                        }}
                      >
                        <option value="" selected="selected">
                          Select Hardware Version
                        </option>
                        {sethardwarelist &&
                          sethardwarelist.map((HardwareList, index) => (
                            <option value={HardwareList.id} key={index}>
                              {HardwareList.version}
                            </option>
                          ))}
                      </select><div className="custom-arrow"></div>
                      {errors.id && (
                        <div className="ft-14 mt-1 red">{errors.id}</div>
                      )}

                      {/* <FieldArray
                          name="hardwarelist"
                          render={(arrayHelpers) => (
                            <div>
                              {sethardwarelist &&
                                sethardwarelist.map((hardwarelist) => (
                                  <div key={hardwarelist.id}>
                                    <label
                                      htmlFor={`hardwarelist-${hardwarelist.id}`}
                                    >
                                      <input
                                        name="hardwarelist"
                                        type="checkbox"
                                        id={`hardwarelist-${hardwarelist.id}`}
                                        value={hardwarelist.id}
                                        checked={values.hardwarelist.includes(
                                          hardwarelist.id
                                        )}
                                        onChange={(e) => {
                                          if (e.target.checked)
                                            arrayHelpers.push(hardwarelist.id);
                                          else {
                                            const idx = values.hardwarelist.indexOf(
                                              hardwarelist.id
                                            );
                                            arrayHelpers.remove(idx);
                                          }
                                        }}
                                      />{" "}
                                      {hardwarelist.version}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          )}
                        />
                       {errors.hardwarelist && (
                          <div className="ft-14 mt-1 red">
                            {errors.hardwarelist}
                          </div>
                        )} */}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="mr-sm-3 form-label">
                        Manufacturing date
                      </label>
                      <input
                        type="date"
                        name="manufacturing_date"
                        value={moment(values.manufacturing_date).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={handleChange}
                        className={`form-control form-select-user ${errors.manufacturing_date ? "error" : ""
                          }`}
                      />
                      {errors.manufacturing_date ? (
                        <div className="ft-14 mt-1 red">
                          {errors.manufacturing_date}
                        </div>
                      ) : null}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="form-label">Server fallback Path</label>
                      <input
                        type="text"
                        name="server_fallback_path"
                        value={values.server_fallback_path}
                        onChange={handleChange}
                        placeholder="Server fallback Path"
                        className={`form-control form-control-user ${errors.server_fallback_path ? "error" : ""
                          }`}
                      />
                      {errors.server_fallback_path && (
                        <div className="ft-14 mt-1 red">
                          {errors.server_fallback_path}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Server Path</label>
                      <input
                        type="text"
                        name="server_path"
                        value={values.server_path}
                        onChange={handleChange}
                        placeholder="Server Path"
                        className={`form-control form-control-user ${errors.server_path ? "error" : ""
                          }`}
                      />
                      {errors.server_path && (
                        <div className="ft-14 mt-1 red">
                          {errors.server_path}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Next Heartbeat Time (in seconds)
                      </label>
                      <input
                        type="text"
                        name="heartbeat_time"
                        value={values.heartbeat_time}
                        onChange={handleChange}
                        placeholder="Next Heartbeat Time"
                        className={`form-control form-control-user ${errors.heartbeat_time ? "error" : ""
                          }`}
                      />
                      {errors.heartbeat_time && (
                        <div className="ft-14 mt-1 red">
                          {errors.heartbeat_time}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <div className="row d-flex align-items-end">
                        <div className="col-8">
                          <label className="form-label">Encryption Key</label>
                          <input
                            type="text"
                            name="encryption_key"
                            value={keyValue}
                            onChange={handleChange}
                            placeholder="Encryption Key"
                            className={`form-control form-control-user ${errors.encryption_key ? "error" : ""
                              }`}
                          />
                          {errors.encryption_key && (
                            <div className="ft-14 mt-1 red">
                              {errors.encryption_key}
                            </div>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="">
                            <button
                              type="button"
                              className="btn-submit"
                              disabled={LocalState.disable}
                              onClick={generateKey}
                              style={{ backgroundColor: user.background_color }}
                            >
                              Generate Key
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8"></div>
                    <div className="form-group col-md-4 pt-2">
                      <button
                        type="submit"
                        className="btn-submit btn-user w-100"
                        disabled={LocalState.disable}
                        style={{ backgroundColor: user.background_color }}
                      >
                        confirm Details

                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {/* <div className="row">
                <div className="col-md-12 mb-4">
                  Usages: <u>20</u>
                </div>
                <div className="col-md-12">
                  Drivers behaviour score: <u>4.5</u>
                </div>
              </div> */}
        </div>
        <hr></hr>
        <div className="form-row">
          <div className="col-12">
            <Formik
              validateOnChange={false}
              onSubmit={(values) => {
                deviceWakeup(values);
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <div className="col-6 float-left">
                    <input
                      type="number"
                      name="minutes"
                      value={values.minutes}
                      onChange={handleChange}
                      placeholder="Enter total minutes"
                      className={`form-control form-control-user ${errors.minutes ? "error" : ""
                        }`}
                    />
                    {errors.minutes && (
                      <div className="ft-14 mt-1 red">{errors.minutes}</div>
                    )}
                  </div>
                  <div className="col-6 float-right">
                    <button
                      type="submit"
                      className="btn-submit"
                      disabled={LocalState.disable}
                      style={{ backgroundColor: user.background_color }}
                    >
                      Set device Wakeup
                    </button>
                  </div>
                  {errors.file && (
                    <div className="ft-14 mt-1 red">{errors.file}</div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
        <hr></hr>
        <div className="form-row">
          <div className="col-12">
            <Formik
              validateOnChange={false}
              validationSchema={FileSchema}
              onSubmit={(values) => {
                // same shape as initial values
                uploadConfig(values);
              }}
            >
              {({ handleSubmit, setFieldValue, errors }) => (
                <form onSubmit={handleSubmit}>
                  <div className="col-6 float-left">
                    <input
                      id="file"
                      type="file"
                      name="file"
                      accept="conf/*"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                      className={`form-controlr form-control-user  ${errors.file ? "error" : ""
                        }`}
                    />
                  </div>
                  <div className="col-6 float-right">
                    <button type="submit" className="btn-submit"
                    style={{ backgroundColor: user.background_color }}
                    >
                      Upload Config
                    </button>
                  </div>
                  {errors.file && (
                    <div className="ft-14 mt-1 red">{errors.file}</div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
        <hr></hr>
        <div className="pl-4 pr-4">
          <h4 className="mr-5">Device Queue</h4>
          <div className="row">
            <div className="col-md-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Command Name</th>
                    <th>Command</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {queuerecord ? (
                    queuerecord.map((cmdQue, index) => (
                      <tr>
                        <td>{cmdQue.id}</td>
                        <td>{cmdQue ? cmdQue.command_name : "--"}</td>
                        <td>{cmdQue.command}</td>
                        <td className="text-capitalize">
                          <div className=" d-flex justify-content-end pt-2">
                            <button
                              className="btn-danger w-100"
                              data-id={cmdQue.id}
                              onClick={Modalopen}
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center ft-14 mt-3 font-weight-bold">
                        No Data Found...
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="row mt-3 ft-14">
                <div className="col-md-12">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                      <Pagination
                        activePage={state.set_active_page}
                        totalItemsCount={queuePaginationResult.total ? queuePaginationResult.total : 1}
                        pageRangeDisplayed={5}
                        itemsCountPerPage={50}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handleQueueCommandsPageChange}
                        activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                      />
                      <li className="pl-3">
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="col-md-12 m-5">
          <Terminal
            name="Terminal"
            colorMode={ColorMode.Dark}
            //lineData={ terminalLineData }
            onInput={onInput}
          >
            {terminalLineData}
          </Terminal>
        </div>
        {/* ) : (
            <div className="p-4">
              <div className="text-center ft-14 mt-3 font-weight-bold">
                You are not allowed to visit this screen...
              </div>
            </div>

          )} */}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default EditDevicesCode;
