import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage.js";
import { Context } from "../Store/Store.js";
import { Modal, Button, Icon } from "rsuite";

import  EditorConvertToHTML  from './EmailEditor.js';

const Helper = require("../Helper.js");


var { apiRequest } = require("../Api/Service.js");
var {
    UserPermissions,
    whitelabelListEndpoint,
    getNotificationTypes,
    getNotificationTemplateByTypeId,
    createNotificationTemplate,
    getNotificaitonTypes,

} = require("../Api/ApiRoutes.js");


const initialState = {
    isError: false,
    errors: [],
    isSuccess: false,
    success: "",
    disable: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                ...state,
                isSuccess: true,
                success: action.payload,
                disable: true,
            };
        case "FETCH_ERROR":
            return {
                ...state,
                isError: true,
                errors: action.payload,
                disable: false,
            };
        case "DEFAULT":
            return {
                disable: action.payload,
            };
        default:
            return state;
    }
};


function UpdateNotificationTemplate(props) {
    let user = Helper.getStorageData("SESSION");

    let id = props.match.params.id;


    const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
    const [redirectToReferrer, setredirectToReferrer] = useState(false);
    const [setPermissions] = React.useState([]);
    const [whitelabelList, setWhitelabelList] = React.useState([]);
    const [emailTypeList, setEmailTypes] = React.useState([]);
    const [template, setTemplate] = React.useState([]);
    const [showTemplate, setShowTemplate] = React.useState(false);
    const [dispatch] = React.useContext(Context);
    const [getTemplate, setGetemplate] = React.useState(false);
    const [tempModal, setTempModal] = React.useState(false);
    const [adminurl, setadminurl] = React.useState('');
    const [bcc, setBcc] = React.useState(false);
    const [emailType, setEmailType] = React.useState('');
    const [emailLang, setEmailLang] = React.useState('');
    const [labelId, setLabelId] = React.useState('');
    const [record, recordData] = React.useState(null);
    const [templateName, setTempleteName] = React.useState('');
    const [templateSubject, setTempleteSubject] = React.useState('');
    const [templateBcc, setTempleteBcc] = React.useState('0');
    const [templateBccEmail, setTempleteBccEmail] = React.useState('');
    const [templateplaceholders, setTempleteplaceholders] = React.useState('');

    const Modalclose = () => {
        setTempModal(false);
    };

    const handleWhitelabelList = (event) => {
       // if (user.type !== "whitelabel") {

            apiRequest(
                getNotificationTypes.method,
                getNotificationTypes.url
            )
            .then((response) => {
                
                setEmailTypes(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
            apiRequest(
                whitelabelListEndpoint.method,
                whitelabelListEndpoint.url + `per_page=-1`
            )
                .then((response) => {
                    if (response.data.code === 200) {
                        setWhitelabelList(response.data.data);
                    } else {
                        console.log("Failure", response);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
             apiRequest(
                getNotificationTemplateByTypeId.method,
                getNotificationTemplateByTypeId.url + `${id}`
            )
            .then((response) => {
                console.log(response.data?.data[0]);
                if(response.data?.data[0] === undefined){
                    setShowTemplate(true);
                    return false;
                }
                recordData(response.data.data[0]);
                let content = response.data?.data[0]?.content?response.data.data[0].content:'';

                let templateName = response.data?.data[0]?.template_name?response.data.data[0].template_name:'';
                let typeID = response.data?.data[0]?.notification_type_id?response.data.data[0].notification_type_id:'';
                let templatesubject = response.data?.data[0]?.title?response.data.data[0].title:'';
                let templatePlaceholders = response.data?.data[0]?.placeholders?response.data.data[0].placeholders[0].placeholder_name:'';
                let placeholderArray = templatePlaceholders.split(',');
                let lang  = response.data?.data[0]?.lang?response.data.data[0].lang:'';
                setEmailType(typeID);
                setTemplate(content);

                setTempleteName(templateName);
                setTempleteSubject(templatesubject);
                setTempleteplaceholders(placeholderArray);
                setEmailLang(lang);
                setShowTemplate(true);

                 if(templateBcc == '1'){
                    setBcc(true);
                }
                else{
                    setBcc(false);
                }

                let labeID = response.data?.data[0]?.whitelabel?response.data.data[0].whitelabel.id:undefined;
                if(labeID !== undefined){
                    setLabelId(labeID);
                }
            })
            .catch((error) => {
                setShowTemplate(false);
                console.log(error);
            });
        //}

        
    };
   
    useEffect(() => {
       
         handleWhitelabelList()
    }, [])

    const handleSubmit = (values) => {

        const form_data = new FormData();
        if(user.type === "whitelabel"){
            form_data.append("label_id", user.label_id);
        }
        else{
            if(labelId !== ''){
                form_data.append("label_id", labelId);
            }
        }
        
        form_data.append("template_name", templateName);
        form_data.append("notification_type_id", emailType);
        form_data.append("lang", emailLang);
        form_data.append("title", templateSubject);
       

        if(getTemplate !== false){
            form_data.append("content", getTemplate);
        }
        else{
            form_data.append("content", template);
        }


         

        Localdispatch({ type: "DEFAULT", payload: true });
        apiRequest(createNotificationTemplate.method, createNotificationTemplate.url, form_data)
            .then((response) => {
                    Localdispatch({
                        type: "FETCH_SUCCESS",
                        payload: "Email data has been Successfully created"
                        
                    });
                    setredirectToReferrer(true); setTimeout(() => {
                        setredirectToReferrer(true);
                    }, 2000);

            })
            .catch((error) => {
                console.log(error);
                Localdispatch({
                    type: "FETCH_ERROR",
                    payload: error.response.data.data,
                });
                window.scrollTo(500, 0);
            });
    };

    React.useEffect(() => {
        apiRequest(UserPermissions.method, UserPermissions.url)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data;
                    setPermissions(data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [setPermissions]);

   

    if (redirectToReferrer) {
        return <Redirect to={{
            pathname: `/whitelabel/app-notification/1`,
        }} />;
    }

    const handleWhitelabelChange = (event) => {
        let id = event.target.value;
        setLabelId(id);
        console.log(id);
        handleeEmailTemplate(id,'labelId');
    }
    const handelEmailType = (event) => {
        let id = event.target.value;
        setEmailType(id);

       handleeEmailTemplate(id, 'emailtype');
        
    }
    const handleLangChange = (event) => {
        let lang = event.target.value;
        setEmailLang(lang);
        
        console.log(emailType);
        handleeEmailTemplate(lang,'lang');
    }

    const handleeEmailTemplate = (id,type=null) => {
        setShowTemplate(false);
        let param = '';
        console.log('calling handleeEmailTemplate')
        

        if(type === 'emailtype') {
            param = `?notification_type_id=${id}&lang=${emailLang}`;
        }
        else if(type === 'lang'){
            if(emailType === ''){
                return true;
            }
             param = `?notification_type_id=${emailType}&lang=${id}`;
        }
        else{
            param = `?notification_type_id=${emailType}&lang=${emailLang}&label_id=${id}`;
        }

        apiRequest(
            getNotificationTemplateByTypeId.method,
            getNotificationTemplateByTypeId.url + param
        )
            .then((response) => {
                console.log(response.data?.data[0]);

                if(response.data?.data[0] === undefined){
                    setShowTemplate(true);
                    return false;
                }
                let content = response.data?.data[0]?.content?response.data.data[0].content:'';

                let templateName = response.data?.data[0]?.template_name?response.data.data[0].template_name:'';
                let templatesubject = response.data?.data[0]?.subject?response.data.data[0].subject:'';
                let templateBcc= response.data?.data[0]?.bcc?response.data.data[0].bcc:'';
                let templateBccemail = response.data?.data[0]?.bcc_email?response.data.data[0].bcc_email:'';
                let templatePlaceholders = response.data?.data[0]?.placeholders?response.data.data[0].placeholders[0].placeholder_name:'';
                let placeholderArray = templatePlaceholders.split(',');
                let lang  = response.data?.data[0]?.lang?response.data.data[0].lang:'';

                setTemplate(content);

                setTempleteName(templateName);
                setTempleteSubject(templatesubject);
                setTempleteBcc(templateBcc);
                setTempleteBccEmail(templateBccemail);
                setTempleteplaceholders(placeholderArray);
                setEmailLang(lang);
                setShowTemplate(true);
                let labeID = response.data?.data[0]?.whitelabel?response.data.data[0].whitelabel.id:undefined;
                if(labeID !== undefined){
                    setLabelId(labeID);
                }
                
            })
            .catch((error) => {
                setShowTemplate(false);
                console.log(error);
            });
        
       
    };

    const handTemplate = (template) => {
        setGetemplate(template);
        console.log(template);
    }

    const handelBccMail = (e) => {
        let cc = e.target.value;
        setTempleteBcc(cc);

        if(cc == '1'){
            setBcc(true);
        }
        else{
            setBcc(false);
        }
    }

    const changeTitle= (title) => {
         setTempleteName(title);
    }

    const changeSubject= (subject) => {
         setTempleteSubject(subject);
    }



    return (
        <React.Fragment>
            <Modal backdrop="static" show={tempModal} onHide={Modalclose} size="xl">
                <Modal.Body>
                    <Icon
                        icon="remind"
                        style={{
                            color: "#ffb300",
                            fontSize: 24,
                        }}
                    />
                    {"  "}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={Modalclose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <div id="content">
                <Header showSearch={false} showAdvanceSearch={false} />
              
                <div className="p-4">
                    
                    <h4 className="mb-3">Update Notification Template</h4>
                   {record && (
                    <Formik
                        validateOnChange={false}
                        onSubmit={async (values) => {
                            handleSubmit(values);
                        }}
                        initialValues={{
                            notification_type_id: record.type_id,
                            lang: record.lang,
                            label_id: record.whitelabel?.id? record.whitelabel.id:null,
                            title:"",
                            template_name:record.template_name,
                            placeholders:record?.placeholders.map(function(placeholder) {
                            return placeholder.placeholder_name;
                            }).join(","),
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            isSubmitting,
                            setFieldValue,
                            handleBlur,
                            values,
                            touched,
                            isValid,
                            errors,
                        }) => (
                            <Form className="mt-2 user">
                                <FlashMessage
                                    success={LocalState.success}
                                    isSuccess={LocalState.isSuccess}
                                    isError={LocalState.isError}
                                    errors={LocalState.errors}
                                />
                                
                                <div className="form-row">
                                   
                                    <div className="form-group col-md-6">
                                        <label className="form-label">Notification Type</label>
                                        <select
                                        
                                            name="notification_type_id"
                                            value={values.notification_type_id}
                                            onChange={(e) => {

                                                handleChange(e);
                                                handelEmailType(e);
                                            }}
                                            className={`form-control hideArrow form-select-user dropdown-height ${errors.notification_type_id ? "error" : ""
                                        }`}
                                        >
                                            {emailTypeList &&
                                                emailTypeList.map((emailTypeList, index) => (
                                                    <option value={emailTypeList.id} key={index}>
                                                        {emailTypeList.name}
                                                    </option>
                                                ))}</select><div className="custom-arrow"></div>
                                        {errors.notification_type_id && (
                                            <div className="ft-14 mt-1 red">{errors.notification_type_id}</div>
                                        )}
                                    </div>
                                     <div className="form-group col-md-6">
                                        <label className="form-label">Email Notification Name </label>
                                        <input
                                            type="text"
                                            name="template_name"
                                            value={templateName || ""}
                                            onChange={e => changeTitle(e.target.value)}
                                            
                                            placeholder="Welcome Email"
                                            className={`form-control form-control-user ${errors.template_name ? "error" : ""}`}
                                        />
                                        {errors.template_name && (
                                        <div className="ft-14 mt-1 red">
                                            {errors.template_name}
                                        </div>
                                        )}
                                    </div>
                                </div>
                                

                     
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="form-label">Select Language</label>
                                        <select
                                        
                                            name="lang"
                                            value={values.lang || ""}
                                            onChange={(e) => {
                                                values.lang = ''
                                                handleLangChange(e);
                                                handleChange(e);
                                            }}
                                            className={`form-control hideArrow form-select-user dropdown-height ${errors.lang ? "error" : ""
                                        }`}
                                        > 
                                        <option value="en">English</option>
                                        <option value="et">Estonian</option>
                                        </select><div className="custom-arrow"></div>
                                        {errors.lang && (
                                            <div className="ft-14 mt-1 red">{errors.lang}</div>
                                        )}

                                    </div>
                                    {user.type !== "whitelabel" && (
                                    <div className="form-group col-md-6">
                                        <label className="form-label">WhiteLabel</label>
                                        <select
                                        
                                            name="label_id"
                                            value={values.label_id}
                                            onChange={(e) => {
                    
                                                handleWhitelabelChange(e);
                                                handleChange(e);
                                            }}
                                            className={`form-control hideArrow form-select-user dropdown-height ${errors.label_id ? "error" : ""
                                        }`}
                                        > <option value="">Select WhiteLabel</option>
                                            {whitelabelList &&
                                                whitelabelList.map((record, index) => (
                                                    <option value={record.id} key={index}>
                                                        {record.name}
                                                    </option>
                                                ))}</select><div className="custom-arrow"></div>
                                        {errors.label_id && (
                                            <div className="ft-14 mt-1 red">{errors.label_id}</div>
                                        )}

                                    </div>  
                                    )} 
                                    
                                </div>

                               
                                <div className="form-row">
                              
                                   <div className="form-group col-md-12">
                                        <label className="form-label">Title </label>
                                        <input
                                            type="text"
                                            name="title"
                                            value={templateSubject || ""}
                                            onChange={e => changeSubject(e.target.value)}
                                            placeholder=""
                                            className={`form-control form-control-user ${errors.subject ? "error" : ""}`}
                                        />
                                        {errors.subject && (
                                        <div className="ft-14 mt-1 red">
                                            {errors.subject}
                                        </div>
                                        )}
                                    </div>
                                    
                                </div>
                                <div className="form-row">
                              
                                   <div className="form-group col-md-12">
                                        <label className="form-label">PlaceHolders: </label>
                                        {templateplaceholders &&
                                        templateplaceholders.map((precord, index) => (
                                            <strong className="badge p-2 m-1 label-assg badge-success mt-1" key={index}>&#123;{precord}&#125; </strong>
                                        ))}
                                    </div>
                                    
                                </div>

                                <div>
                                { showTemplate &&<h3>Notification Content</h3> }            
                                { showTemplate &&  <EditorConvertToHTML tem={template} selTemplate={handTemplate} />}
                                </div>
                                 {showTemplate && <div className="form-row mt-2">
                                    <div className="form-group d-flex justify-content-end col-md-12">
                                        <button
                                            type="submit"
                                            className="btn-submit"
                                            disabled={LocalState.disable}
                                            style={{ backgroundColor: user.background_color }}
                                        >
                                            confirm Details
                                            {LocalState.disable ? (
                                                <span
                                                    className="ml-2 spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            ) : (
                                                ""
                                            )}
                                        </button>
                                    </div>
                                    </div>
                                }

                            </Form> 
                        )}
                    </Formik>
                    )}
                </div>
                
            </div>
        </React.Fragment>
    );
}

export default UpdateNotificationTemplate;
