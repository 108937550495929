import React , { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../Header/Index.js";
import Pagination from "react-js-pagination";
import Helper from "../Helper";
import { Context } from "../Store/Store";
import { useHistory } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
var {
  Mobi1otApi
} = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function Mobi1otList() {
  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [records, setrecords] = React.useState([]);
  const [activePage, setactivePage] = React.useState(1);
  const [result, setResult] = React.useState({});
  const [errorMsg, setErrorMsg] = React.useState("");
  const [hidePagination, sethidePagination] = React.useState(false);
  const [state, dispatch] = React.useContext(Context);
  const [ locationKeys, setLocationKeys ] = React.useState([])
  const history = useHistory()

  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  var location = useLocation()

  const getDeviceCodeList = () => {
    if (
      Helper.getPermissions(
        "serial-read",
        permissions && permissions,
        "admin"
      ) ||
      user.type === "superadmin"
    ) {
      Helper.overlay(true);

      const form_data = new FormData();
      form_data.append("url", "get_sims");
      form_data.append("method", "get");
  
      Localdispatch({ type: "DEFAULT", payload: true });
      apiRequest(Mobi1otApi.method, Mobi1otApi.url + `?page=${state.set_active_page}`,form_data)
        .then((response) => {
          if (response.data.code == 200) {
            let data = response.data.data.sims;
            dispatch({ type:"SET_CALL_STATE",call_one: false });
            setrecords(data);
            dispatch({ type: "SET_SEARCH_STATE", search_list: false });
            sethidePagination(true);
            setResult(response.data.meta);
            Helper.overlay(false);
          }
        })
        .catch((error) => {
          setErrorMsg(error)
          Helper.overlay(false);
        });
    }
  }

const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const dateConvert = (str) =>{
  var date = new Date(str);
  return date.toDateString();
}
  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }
  
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys)
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
          history.push(`${location.pathname}`);
          dispatch({ type: "SET_SEARCH_STATE", search_list: true });
        }
      }
    })
  }, [ locationKeys, ])

 useEffect(() => {
  if (location.hash !== "#search") {
    getDeviceCodeList(activePage);
  } else {
    Helper.overlay(false);
  }
}, [state.search_list]);

useEffect(() => {
  if (state.pagination === true) {
    sethidePagination(true);
    setrecords(state.data)
    dispatch({ type: "SET_SEARCH_STATE", pagination: false });
  } 
}, [state.pagination]);
  
 

 

  const handlePageChange = (pageNumber) => {
    console.log(`active page is ${pageNumber}`);
    setactivePage(pageNumber);
    Helper.overlay(true);
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });

    const form_data = new FormData();
    form_data.append("url", "get_sims");
    form_data.append("method", "get");

    Localdispatch({ type: "DEFAULT", payload: true });

    apiRequest(Mobi1otApi.method, Mobi1otApi.url + `?page=${pageNumber}`, form_data)
      .then((response) => {
        let data = response.data;
        if (response.data.code == 200) {
          setrecords(response.data.sims);
          console.log("Success", response);
          Helper.overlay(false);
        } else {
          console.log("Failure", response);
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        console.log("error", error);
      });
  };

  return (
    <React.Fragment>
  
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false}/>
        {Helper.getPermissions(
          "serial-read",
          permissions && permissions,
          "admin"
        ) || Helper.getPermissions("", [], user.type) ? (
          <div className="p-5">
      
            <div className="row">
              <div className="col-4 ml-n2 p-2">
                  <h3>Overview of SIMs</h3>
              </div>
              <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>ICCID</th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Last Network activity</th>
                    <th>Last session</th>
                    <th>Data Used</th>
                    <th>Data Limit</th>
                    </tr>
                </thead>
                <tbody>
              {records.length ? (
                records.map((record, index) => (
                    <tr  key={index}>
                        <td>{record.iccid}</td>
                        <td>{record.name}</td>
                        <td>{record.state}</td>
                        <td>{record.lastNetworkActivityMillis ? dateConvert(record.lastNetworkActivityMillis) : "--"}</td>
                        <td>{ record.lastDataSessionMillis ? dateConvert(record.lastDataSessionMillis) : "--" }</td>
                        <td>{formatBytes(record.dataUsedInBytes)}</td>
                        <td>{record.data_limit}</td>
                  
                    </tr>
                ))
              ) : (
                errorMsg && (
                <div className="text-center ft-14 mt-3 font-weight-bold">
                  No Data Found...
                </div>
                )
              )}
               </tbody>
            </Table>
            </div>
            
          </div>
        ) : (
          <div className="p-4">
            <div className="text-center ft-14 mt-3 font-weight-bold">
              You are not allowed to visit this screen...
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
export default Mobi1otList;
