import React, {
  useState
} from "react";
import { NavLink, Redirect } from "react-router-dom";
import HeaderWithoutAuth from "../Header/HeaderWithoutAuth.js";
import { Formik } from "formik";
import * as Yup from "yup";
import { formData } from "../Helper";
import FlashMessage from "../FlashMessage/FlashMessage";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
var { SignUpStep1 } = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
  show: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        isSuccess: true,
        success: action.payload,
        disable: true,
        show: true,
      };
    case "FETCH_ERROR":
      return {
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function Index() {
  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [token, setToken] = useState();
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);
  const SignUpSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("This Field is Required")
      .max(32).min(3),
    last_name: Yup.string()
      .required("This Field is Required")
      .max(32).min(3),
    user: Yup.string().required("This Field is Required"),
    strict: false
  });

  const handleSubmit = (values) => {
    const form_data = formData(values);
    form_data.append('recaptcha', token);
    form_data.append('name', values.first_name +' '+values.last_name );
    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(SignUpStep1.method, SignUpStep1.url, form_data)
      .then((response) => {
        if (response.data.code == 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        } else {
          Localdispatch({ type: "FETCH_ERROR", payload: response.data.data });
          console.log("Failure", response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
      });
  };

  if (redirectToReferrer) {
    return <Redirect to={"/sign-up-2"} />;
  }

  return (
    <React.Fragment>
      <HeaderWithoutAuth />
      <div className="container">
        <div className="card o-hidden border-0 shadow-lg my-5">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-5 d-none d-lg-block bg-register-image"></div>
              <div className="col-lg-7">
                <div className="p-5">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">Create your Movitronic account</h1>
                  </div>
                  <Formik
                    validateOnChange={false}
                    validationSchema={SignUpSchema}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                    initialValues={{
                      name: "",
                      email: "",
                      type: "",
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                    }) => (
                      <form
                        className="pt-5 mt-5 form user"
                        onSubmit={handleSubmit}
                      >
                        <FlashMessage
                          success={LocalState.success}
                          isSuccess={LocalState.isSuccess}
                          isError={LocalState.isError}
                          errors={LocalState.errors}
                        />
                        <div className="form-group row">
                          <div className="col-sm-12 mb-3 mb-sm-0">
                            <label>First name</label>
                            <input
                              type="text"
                              name="first_name"
                              id="first_name"
                              placeholder="Your first name"
                              value={values.first_name || ""}
                              maxLength={32}
                              onChange={handleChange}
                              className={`form-control form-control-user ${errors.first_name ? "error" : ""
                                }`}
                            />
                            {errors.first_name ? (
                              <div className="ft-14 mt-1 red">
                                {errors.first_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-12 mb-3 mb-sm-0">
                            <label>Last name</label>
                            <input
                              type="text"
                              name="last_name"
                              id="last_name"
                              placeholder="Your last name"
                              value={values.last_name || ""}
                              maxLength={32}
                              onChange={handleChange}
                              className={`form-control form-control-user ${errors.last_name ? "error" : ""
                                }`}
                            />
                            {errors.last_name ? (
                              <div className="ft-14 mt-1 red">
                                {errors.last_name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group" id="recaptcha">
                          <label>Email or phone number</label>
                          <input
                            type="text"
                            name="user"
                            id="email"
                            value={values.user || ""}
                            placeholder="Your Email or phone number"
                            onChange={handleChange}
                            className={`form-control form-control-user ${errors.user ? "error" : ""
                              }`}
                          />
                          {errors.user ? (
                            <div className="ft-14 mt-1 red">{errors.user}</div>
                          ) : null}
                          <GoogleReCaptchaProvider reCaptchaKey='6LeKUokpAAAAAPFAlUMH8EimpCjtaOhXgShpIflN' container={{
                            element: "[recaptcha]",
                            parameters: {
                              badge: '[inline]', // optional, default undefined
                            }
                          }}>
                            <GoogleReCaptcha onVerify={token => {
                              setToken(token);
                            }} />
                          </GoogleReCaptchaProvider>
                        </div>

                        <button
                          type="submit"
                          className="btn-submit btn bg-orange text-white btn-user btn-block "
                          disabled={LocalState.disable}
                        >
                          Register Account
                          {LocalState.disable ? (
                            <span
                              className="ml-2 spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                        </button>
                      </form>
                    )}
                  </Formik>
                  <div className="text-center">
                    <NavLink to="/forgot-password" className="small">
                      Forgot Password?
                    </NavLink>
                  </div>
                  <hr />
                  <div className="text-center">
                    <NavLink to="/" className="small">
                      Already have an account? Login!
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Index;
