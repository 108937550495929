import React, { useContext } from "react";
import { Formik, Form } from "formik";
import { Context } from "../Store/Store";
import Helper from "../Helper";
var { apiRequest } = require("../Api/Service");
var { SearchApi } = require("../Api/ApiRoutes");
//const Helper = require('../Helper');

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};
function AdvanceSearch({AdvanceVehicleSearch}) {
  let user = Helper.getStorageData("SESSION");
  const [state, dispatch] = useContext(Context);
  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [loader, setloader] = React.useState(false);
  const handleSubmit = (values) => {
    
    const queryParams = {};

    if(values.car_battery_low === true){
      queryParams.car_battery_low = values.car_battery_low;
    }

    if(values.Service_light_on === true){
      queryParams.service_light_on = values.Service_light_on;
    }

    if(values.Lightbulb_error === true){
      queryParams.lightbulb_error = values.Lightbulb_error;
    }

    if(values.breakdown_light === true){
      queryParams.breakdown_light = values.breakdown_light;
    }

    if(values.car_disconnected === true){
      queryParams.car_disconnected = values.car_disconnected;
    }

    if(values.low_fuel === true){
      queryParams.low_fuel = values.low_fuel;
    }

    if(values.Door_Trunk_left_open === true){
      queryParams.door_trunk_left_open = values.Door_Trunk_left_open;
    }
    if(values.idle === true){
      queryParams.idle = values.idle;
    }

    if(values.not_shared === true){
      queryParams.not_shared = values.not_shared;
    }

    if(values.share === true){
      queryParams.share = values.share;
    }

    if(values.Possible_crash === true){
      queryParams.possible_crash = values.Possible_crash;
    }

    if(values.Damper_alarm === true){
      queryParams.damper_alarm = values.Damper_alarm;
    }

    if(values.Device_battery_low === true){
      queryParams.device_battery_low = values.Device_battery_low;
    }

    if(values.Hardware_error === true){
      queryParams.hardware_error = values.Hardware_error;
    }

     if(values.Not_configured === true){
      queryParams.not_configured = values.Not_configured;
    }

    if(values.Open === true){
      queryParams.open = values.Open;
    }

    if(values.license !== ""){
      queryParams.license = values.license;
    }

    if(values.name !== ""){
      queryParams.name = values.name;
    }

    if(values.model !== ""){
      queryParams.model = values.model;
    }

    if(values.ignition === "2"){
      queryParams.key_ignition = 2;
    }

    if(values.ignition === "1"){
      queryParams.key_ignition = 1;
    }

    if(values.tags !== ""){
      queryParams.tags = values.tags;
    }

    if(values.operator !== ""){
      queryParams.operator = values.operator;
    }
    if(values.acriss_code  !== ""){
      queryParams.acriss_code = values.acriss_code;
    }

    if(values.location !== ""){
      queryParams.location = values.location;
    }

    if(values.year !== ""){
      queryParams.year = values.year;
    }

    if(values.serial !== ""){
      queryParams.serial = values.serial;
    }

    if(values.engine_running === true){
      queryParams.status = 1;
    }

    if(values.in_use === true){
      queryParams.in_use = values.in_use;
    }

    if(values.sleep_mode === true){
      queryParams.status = 3;
    }

    if(values.not_responding === true){
      queryParams.status = 4;
    }

    if(values.Key_missing === true){
      queryParams.Key_missing = values.Key_missing;
    }

    if(values.Closed === true){
      queryParams.closed = values.Closed;
    }
    
    if(values.Stand_by === true){
      queryParams.status = 2;
    }
    

    const queryString = new URLSearchParams(queryParams).toString();
    const apiUrl = `${SearchApi.url}?${queryString}`;
    Localdispatch({ type: "DEFAULT", payload: true });
    Helper.overlay(true);
    setloader(true);
    apiRequest(SearchApi.method,apiUrl)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          AdvanceVehicleSearch(response);
          console.log("FETCH_SUCCESS", data);
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          dispatch({ type: "SET_STATE", response: data, advanceSearch: true });
          Helper.overlay(false);
          setloader(false);
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        AdvanceVehicleSearch([]);
        //Localdispatch({ type: 'FETCH_ERROR', payload: error.response.data.data })
        dispatch({ type: "SET_STATE", response: [] });
        console.log("FETCH_ERROR");
        Helper.overlay(false);
        setloader(false);
      });
  };

   return (
    <div className="" id="">
      <div className="pt-1">
        {loader && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      <Formik
            onSubmit={async (values) => {
              // console.log("permissions: ", values);
              handleSubmit(values);
            }}
            initialValues={{
              license: "",
              name: "",
              model: "",
              year: "",
              serial: "",
              ignition: 1,
              push_start:2,
              tags: "",
              operator: "",
              location: "",
              acriss_code : "",
              engine_running:1,
              Stand_by:1,
              sleep_mode:1,
              not_responding:1,
              in_use:1,
              idle:false,
              share:false,
              not_shared:false,
              low_fuel:false,
              car_battery_low:false,
              car_disconnected:false,
              breakdown_light:false,
              Lightbulb_error:false,
              Service_light_on:false,
              Door_Trunk_left_open:false,
              Possible_crash:false,
              Damper_alarm:false,
              Device_battery_low:false,
              Hardware_error:false,
              Not_configured:false,
              Open:false,
              Closed:false,
              Key_missing:false
            }}
          >
            {({
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue,
              handleBlur,
              values,
            }) => (
        <Form className="mt-2 user">
          <h4 className="pl-4" >Advanced Search</h4>
          <aside className="">
            <div className="d-flex row">
              <div className="col-md-12 col-lg-3 card-group-item">
                <div className="filter-content">
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-md-6 font-weight-bold">
                        Number Plate
                      </label>
                      <input
                        type="text"
                        value={values.license || ""}
                        onChange={handleChange}
                        className="form-control col-md-6"
                        name="license"
                      />
                    </div>
                    <div className="form-group row">
                      <label className="col-md-6 font-weight-bold">
                        Car Name
                      </label>
                      <input
                        type="text"
                        value={values.name || ""}
                        onChange={handleChange}
                        className="form-control col-md-6"
                        name="name"
                      />
                    </div>
                    <div className="form-group row">
                      <label className="col-md-6 font-weight-bold">
                        Car make/model
                      </label>
                      <input
                        type="text"
                        name="model"
                        value={values.model || ""}
                        onChange={handleChange}
                        className="form-control col-md-6"
                      />
                    </div>
                    <div className="form-group row">
                      <label className="col-md-6 font-weight-bold">
                        Car Year
                      </label>
                      <div className="input-group col-md-6 p-0">
                        <input
                          type="text"
                          name="year"
                          value={values.year || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                        {/* <input type="text" className="form-control" /> */}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-6 font-weight-bold">
                        Device Serial
                      </label>
                      <div className="input-group col-md-6 p-0">
                        <input
                          type="text"
                          name="serial"
                          value={values.serial || ""}
                          onChange={handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-md-6 font-weight-bold">Tags</label>
                      <input
                        type="text"
                        className="form-control col-md-6"
                        name="tags"
                        value={values.tags || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group row">
                      <label className="col-md-6 font-weight-bold">
                        Location
                      </label>
                      <input
                        type="text"
                        className="form-control col-md-6"
                        name="location"
                        value={values.location || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group row">
                      <label className="col-md-6 font-weight-bold">
                        Operator
                      </label>
                      <input
                        type="text"
                        className="form-control col-md-6"
                        name="operator"
                        value={values.operator || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group row">
                      <label className="col-md-6 font-weight-bold">
                      ACRISS code
                      </label>
                      <input
                        type="text"
                        className="form-control col-md-6"
                        name="acriss_code"
                        value={values.acriss_code  || ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 card-group-item">
                <h6 className="title">Status </h6>
                <div className="filter-content">
                  <div className="">
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="engine_running"
                        name="engine_running"
                        value={values.engine_running || ""}
                        onChange={handleChange}
                      />
                      <label
                        className="ml-2"
                        htmlFor="engine_running"
                      >
                        Engine running
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="Stand_by"
                        name="Stand_by"
                        value={values.Stand_by || ""}
                        onChange={handleChange}
                      />
                      <label
                        className="ml-2"
                        htmlFor="Stand_by"
                      >
                        Stand-by
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="sleep_mode"
                        name="sleep_mode"
                        value={values.sleep_mode || ""}
                        onChange={handleChange}
                      />
                      <label
                        className="ml-2"
                        htmlFor="sleep_mode"
                      >
                        Sleep mode
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="not_responding"
                        name="not_responding"
                        value={values.not_responding || ""}
                        onChange={handleChange}
                      />
                      <label
                        className="ml-2"
                        htmlFor="not_responding"
                      >
                        Not responding
                      </label>
                    </div>
                    <div className="mb-2 mt-2">
                      <strong>Usage:</strong>
                    </div>
                    <div className="form-group d-flex">
                      <div className="d-flex align-items-center col-5 pl-0">
                        <input
                          className=""
                          type="checkbox"
                          id="in_use"
                          value={values.in_use || ""}
                          onChange={handleChange}
                          name="in_use"
                        />
                        <span
                          className="ml-2"
                          htmlFor="in_use"
                        >
                          In Use
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          className=""
                          type="checkbox"
                          id="idle"
                          value={values.idle || ""}
                          onChange={handleChange}
                          name="idle"
                        />
                        <span
                          className="ml-2"
                          htmlFor="idle"
                        >
                          Idle
                        </span>
                      </div>
                    </div>

                    <div className="mb-2 mt-2">
                      <strong>Shared:</strong>
                    </div>
                    <div className="form-group d-flex ">
                      <div className="d-flex align-items-center col-5 pl-0 ">
                        <input
                          className=""
                          type="checkbox"
                          id="shared"
                          value={values.share || ""}
                          onChange={handleChange}
                          name="share"
                        />
                        <span
                          className="ml-2"
                          htmlFor="shared"
                        >
                          Shared
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          className=""
                          type="checkbox"
                          id="not_shared"
                          name="not_shared"
                          value={values.not_shared || ""}
                          onChange={handleChange}
                        />
                        <span
                          className="ml-2"
                          htmlFor="not_shared"
                        >
                          Not Shared
                        </span>
                      </div>
                    </div>
                    <div className="form-group d-flex">
                      <div className="d-flex align-items-center col-5 pl-0 pr-0">
                        <input
                          className=""
                          type="checkbox"
                          id="inlineCheckbox1"
                          defaultValue={1}
                          name="push_start"
                          value={values.push_start || ""}
                          onChange={handleChange}
                        />
                        <span
                          className="ml-2"
                          htmlFor="inlineCheckbox1"
                        >
                          Push Start
                        </span>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          className=""
                          type="checkbox"
                          id="inlineCheckbox2"
                          defaultValue={2}
                          value={values.ignition || ""}
                          name="ignition"
                          onChange={handleChange}
                        />
                        <span
                          className="ml-2"
                          htmlFor="inlineCheckbox2"
                        >
                          Key-ignition
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 card-group-item">
                  <h6 className="title">Car Alarms</h6>
                <div className="filter-content">
                  <div className="">
                    <div className="mb-2">
                      <strong></strong>
                    </div>

                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="low_fuel"
                        name="low_fuel"
                        value={values.low_fuel || ""}
                        onChange={handleChange}
                      />
                      <label
                        className="ml-2"
                        htmlFor="low_fuel"
                      >
                        Low fuel
                      </label>
                    </div>

                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="car_battery_low"
                        value={values.car_battery_low || ""}
                        onChange={handleChange}
                        name="car_battery_low"
                      />
                      <label
                        className="ml-2"
                        htmlFor="car_battery_low"
                      >
                        Car battery low
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="car_disconnected"
                        value={values.car_disconnected || ""}
                        onChange={handleChange}
                        name="car_disconnected"
                      />
                      <label
                        className="ml-2"
                        htmlFor="car_disconnected"
                      >
                        Car battery disconnected
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=" font-weight-bold"
                        id="breakdown_light"
                        name="breakdown_light"
                        value={values.breakdown_light || ""}
                        onChange={handleChange}
                      />
                      <label
                        className="ml-2"
                        htmlFor="breakdown_light"
                      >
                        Engine breakdown light on
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=" font-weight-bold"
                        id="Lightbulb_error"
                        name="Lightbulb_error"
                        value={values.Lightbulb_error || ""}
                        onChange={handleChange}
                      />
                      <label
                        className="ml-2"
                        htmlFor="Lightbulb_error"
                      >
                        Lightbulb error
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="Service_light_on"
                        name="Service_light_on"
                        value={values.Service_light_on || ""}
                        onChange={handleChange}
                      />
                      <label
                        className="ml-2"
                        htmlFor="Service__light_on"
                      >
                        Service due light on
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="Door_Trunk_left_open"
                        value={values.Door_Trunk_left_open || ""}
                        onChange={handleChange}
                        name="Door_Trunk_left_open"
                      />
                      <label
                        className="ml-2"
                        htmlFor="Door_Trunk_left_open"
                      >
                        Door/Trunk left open
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="Possible_crash"
                        value={values.Possible_crash || ""}
                        onChange={handleChange}
                        name="Possible_crash"
                      />
                      <label
                        className="ml-2"
                        htmlFor="Possible_crash"
                      >
                        Possible crash
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* align-items-start flex-column */}
              <div className="col-md-6 col-lg-3 card-group-item">
                <h6 className="title">Device Alarms </h6>
                <div className="filter-content">
                  <div className="">
                    {/* <div> */}
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="Damper_alarm"
                        value={values.Damper_alarm || ""}
                        onChange={handleChange}
                        name="Damper_alarm"
                      />
                      <label
                        className="ml-2"
                        htmlFor="Damper_alarm"
                      >
                        Damper alarm
                      </label>
                    </div>

                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="Device_battery_low"
                        value={values.Device_battery_low || ""}
                        onChange={handleChange}
                        name="Device_battery_low"
                      />
                      <label
                        className="ml-2"
                        htmlFor="Device_battery_low"
                      >
                        Device battery low
                      </label>
                    </div>

                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="Hardware_error"
                        value={values.Hardware_error || ""}
                        onChange={handleChange}
                        name="Hardware_error"
                      />
                      <label
                        className="ml-2"
                        htmlFor="Hardware_error"
                      >
                        Hardware error
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="Not_configured"
                        value={values.Not_configured || ""}
                        onChange={handleChange}
                        name="Not_configured"
                      />
                      <label
                        className="ml-2"
                        htmlFor="Not_configured"
                      >
                        Not configured
                      </label>
                    </div>
                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="Key_missing"
                        value={values.Key_missing || ""}
                        onChange={handleChange}
                        name="Key_missing"
                      />
                      <label
                        className="ml-2"
                        htmlFor="Key_missing"
                      >
                        Key missing from holder
                      </label>
                    </div>
                    <div className="mb-2">
                      <strong>Central lock:</strong>
                    </div>

                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=" "
                        id="Closed"
                        value={values.Closed || ""}
                        onChange={handleChange}
                        name="Closed"
                      />
                      <label
                        className="ml-2"
                        htmlFor="Closed"
                      >
                        Closed
                      </label>
                    </div>

                    <div className="mb-1">
                      <input
                        type="checkbox"
                        className=""
                        id="Open"
                        value={values.Open || ""}
                        onChange={handleChange}
                        name="Open"
                      />
                      <label
                        className="ml-2"
                        htmlFor="Open"
                      >
                        Open
                      </label>
                    </div>

                    {/* </div>
                  </div>
                </div> */}
                <div className="mt-lg-5 mt-md-0 pt-lg-5 pt-md-3">
                <button
                      type="submit"
                      className="btn btnn-user btn-primary float-lg-right float-md-left  mt-lg-5 mt-md-0 mb-md-2"
                      disabled={LocalState.disable}
                      style={{ backgroundColor: user.background_color, border:  user.background_color}}

                    >
                      Search
                      {LocalState.disable ? (
                        <span
                          className="ml-2 spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        ""
                      )}
                    </button>
                </div>
                </div>
              </div>
              </div>
            </div>
          </aside>
        </Form>
        )}
        </Formik>
      </div>
    </div>
  );
}


export default AdvanceSearch;
