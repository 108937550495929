import React, { useMemo, useState } from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { Col, ColorPicker, Row, Space } from 'antd';
import Helper from "../Helper";
import { Formik, Form, } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
var { apiRequest } = require("../Api/Service");
var {
  UpdateWhitelabelListing,
  EditWhitelabelListing,
  GetWhiteLabelStation,
  TimeZoneList
} = require("../Api/ApiRoutes");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function EditWhiteLabel(props) {
  const DetailSchema = Yup.object().shape({
    //name: Yup.string().required("This Field is Required"),
    //email: Yup.string().email().required("This Field is Required"),
    //contact_no: Yup.number().required("This Field is Required"),
    // password: Yup.string().required("This Field is Required"),
    //password_confirmation: Yup.string().required("This Field is Required")
    // .oneOf([Yup.ref("password"), null], "Passwords must match"),

    // strip_key: Yup.string().required("This Field is Required"),
    // strip_secret: Yup.string().required("This Field is Required"),
    // package_id: Yup.string().required("This Field is Required"),
    // subDomain_url: Yup.string().required("This Field is Required"),
  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = useState(false);
  const [userData, setuserData] = useState(null);
  const [colorHex, setColorHex] = useState('#1677ff');
  const [formatHex, setFormatHex] = useState('hex');
  const [colorHexLogo, setColorHexLogo] = useState('#1677ff');
  const [formatHexLogo, setFormatHexLogo] = useState('hex');
  const [whitelabelStationList, setWhitelabelStationList] = useState([]);
  var user = Helper.getStorageData("SESSION")
  var permissions = user.permissions;
  const [colorHexFont, setColorHexFont] = useState('#1677ff');
  const [formatHexFont, setFormatHexFont] = useState('hex');
  const [vatValue, setVatValue] = useState(0);

  const [timezoneList, settimezoneList] = useState([]);

  let user_id = props.match.params.id;
  const fileMaxSize = 5;

  const checkFileSize = (value) => {
    let error;
    if (value > fileMaxSize) {
      error = 'File size exceeds the allowed limit of ' + fileMaxSize + 'MB';
    } else {
      error = undefined
    }
    return error;
  };

  const handleSubmit = (values) => {
    // const form_data = Helper.formData(values);
    const form_data = new FormData();
    form_data.append("name", values.name);
    form_data.append("email", values.email);
    form_data.append("id", user_id);
    form_data.append("contact_no", values.contact_no);
    form_data.append("color", colorHex);
    // form_data.append("file", values.file);
    (values.main_logo !== undefined) && (form_data.append("main_logo", values.main_logo));
    (values.splash_logo !== undefined) && (form_data.append("splash_logo", values.splash_logo));
    (values.thumbnail !== undefined) && (form_data.append("thumbnail", values.thumbnail));
    form_data.append("strip_key", values.strip_key);
    form_data.append("strip_secret", values.strip_secret);
    form_data.append("webhook_url", values.webhook_url);
    form_data.append("package_id", values.package_id);
    form_data.append("subdomain_url", values.subdomain_url);
    form_data.append("support_email", values.support_email);
    form_data.append("slogan", values.slogan);
    (values.mail_logo !== undefined) && (form_data.append("mail_logo", values.mail_logo));
    form_data.append("ios_url", values.ios_url);
    form_data.append("vat", vatValue);
    form_data.append("vat_code", values.vat_code);
    form_data.append("android_url", values.android_url);
    form_data.append("default_pickup_station_id", values.default_pickup_station_id);
    form_data.append("default_dropoff_station_id", values.default_dropoff_station_id);
    form_data.append("logo_color_schema", colorHexLogo);
    form_data.append("font_color", colorHexFont);
    form_data.append("address", values.address);
    form_data.append("registration_no", values.registration_no);
    form_data.append("timezone", values.timezone);
    // for (let index = 0; index < values.permissions.length; index++) {
    //   form_data.append("permissions[]", values.permissions[index]);
    // }
    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(
      UpdateWhitelabelListing.method,
      UpdateWhitelabelListing.url + user_id,
      form_data
    )
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  // React.useEffect(() => {
  //   apiRequest(UserPermissions.method, UserPermissions.url)
  //     .then((response) => {
  //       if (response.data.code === 200) {
  //         let data = response.data.data;
  //         setPermissions(data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);


  React.useEffect(() => {
    apiRequest(TimeZoneList.method, TimeZoneList.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          settimezoneList(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // if (
    //   Helper.getPermissions(
    //     "label-update",
    //     user_permissions && user_permissions,
    //     "admin"
    //   ) ||
    //   Helper.getPermissions("", [], user.type)
    // ) {
    apiRequest(
      EditWhitelabelListing.method,
      EditWhitelabelListing.url + user_id
    )
      .then((response) => {
        setuserData(response.data.data);
        setColorHex(response.data.data.color)
        setColorHexLogo(response.data.data.logo_color_schema)
        setColorHexFont(response.data.data.font_color)
        setVatValue(response.data.data.vat);
      })
      .catch((error) => {
        console.log(error);
      });

    apiRequest(
      GetWhiteLabelStation.method,
      GetWhiteLabelStation.url + `${user_id}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setWhitelabelStationList(response.data.data);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //}
  }, [user_id]);

  const rgbString = useMemo(
    () => (typeof colorHex === 'string' ? colorHex : colorHex.toRgbString()),
    [colorHex],
  );
  const rgbStringLogo = useMemo(
    () => (colorHexLogo != null ? (typeof colorHexLogo === 'string' ? colorHexLogo : colorHexLogo.toRgbString()) : colorHexLogo),
    [colorHexLogo],
  );

  const rgbStringFont = useMemo(
    () => (colorHexFont != null ? (typeof colorHexFont === 'string' ? colorHexFont : colorHexFont.toRgbString()) : colorHexFont),
    [colorHexFont],
  );

  const handleVatValue = event => {
    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    setVatValue(value);
  };

  if (redirectToReferrer) {
    if (user.type !== 'whitelabel') {
      return <Redirect to={"/admin/list-white-label/1"} />;
    } else {
      window.location.reload();
    }

  }
  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        <div className="p-4">
          <h4 className="mb-3">Edit whitelabel</h4>
          {userData && (
            <Formik
              validateOnChange={false}
              validationSchema={DetailSchema}
              onSubmit={async (values) => {
                // console.log("permissions: ", values);
                handleSubmit(values);
              }}
              initialValues={{
                name: userData && userData.name,
                email: userData && userData.email,
                contact_no: userData && userData.contact_no,
                color: userData && userData.color,
                logo_color_schema: userData && userData.logo_color_schema,
                file: userData && userData.file,
                permissions: [],
                strip_key: userData && userData.strip_key,
                strip_secret: userData && userData.strip_secret,
                webhook_url: userData && userData.webhook_url,
                package_id: userData && userData.package_id,
                subdomain_url: userData && userData.subdomain_url,
                support_email: userData && userData.support_email,
                slogan: userData && userData.slogan,
                ios_url: userData && userData.ios_url,
                vat: userData && userData.vat,
                vat_code: userData && userData.vat_code,
                android_url: userData && userData.android_url,
                default_pickup_station_id: userData && userData.default_pickup_station_id,
                default_dropoff_station_id: userData && userData.default_dropoff_station_id,
                address: userData && userData.address,
                registration_no: userData && userData.registration_no,
                timezone: userData && userData.timezone,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form className="mt-2 user">
                  <FlashMessage
                    success={LocalState.success}
                    isSuccess={LocalState.isSuccess}
                    isError={LocalState.isError}
                    errors={LocalState.errors}
                  />
                  <div className="form-row">
                    <div className="form-group col-md-12 text-center">
                      <img
                        src={userData && userData.file}
                        alt="User"
                        style={{
                          width: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Your Full Name</label>
                      <input
                        type="text"
                        name="name"
                        value={values.name || ""}
                        onChange={handleChange}
                        placeholder="Enter Your Name"
                        className={`form-control form-control-user  ${errors.name ? "error" : ""
                          }`}
                      />
                      {errors.name && (
                        <div className="ft-14 mt-1 red">{errors.name}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Email</label>
                      <input
                        type="text"
                        name="email"
                        value={values.email || ""}
                        onChange={handleChange}
                        placeholder="Enter Your Email"
                        className={`form-control form-control-user  ${errors.email ? "error" : ""
                          }`}
                      />
                      {errors.email && (
                        <div className="ft-14 mt-1 red">{errors.email}</div>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Contact</label>
                      <input
                        type="text"
                        name="contact_no"
                        value={values.contact_no || ""}
                        onChange={handleChange}
                        placeholder="Enter Your Contact"
                        className={`form-control form-control-user  ${errors.contact_no ? "error" : ""
                          }`}
                      />
                      {errors.contact_no && (
                        <div className="ft-14 mt-1 red">
                          {errors.contact_no}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      {/* <label className="form-label" htmlFor="avatar">
                        Avatar
                      </label>
                      <input
                        id="avatar"
                        type="file"
                        name="file"
                        onChange={(event) => {
                          setFieldValue("file", event.currentTarget.files[0]);
                        }}
                        className={`form-control form-select-user  ${errors.file ? "error" : ""
                          }`}
                      />
                      {errors.file && (
                        <div className="ft-14 mt-1 red">{errors.file}</div>
                      )} */}
                      <label className="form-label" htmlFor="avatar">
                        Main Logo
                      </label>
                      <input
                        id="image"
                        type="file"
                        name="main_logo"
                        accept=".png, .jpg, .jpeg, .svg"
                        onChange={(event) => {
                          setFieldValue("main_logo", event.currentTarget.files[0]);
                        }}
                        className={`form-control form-select-user ${errors.main_logo ? "error" : ""
                          }`}
                      />
                      {errors.main_logo && (
                        <div className="ft-14 mt-1 red">{errors.main_logo}</div>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Color</label>
                      <Row className={`form-control form-control-user }`} >
                        <Space style={{ position: "absolute", top: "36px" }}>
                          <Col>
                            <ColorPicker
                              name={"color"}
                              format={formatHex}
                              value={colorHex}
                              trigger="hover"
                              onChange={(_, value) => {
                                setColorHex(value)
                                handleChange("color", value)
                              }}
                              onFormatChange={setFormatHex}
                            />
                          </Col>
                          <Col>
                            Hex: <span>{rgbString}</span>
                          </Col>
                        </Space>
                      </Row>
                      {/* {errors.color && touched.color ? (
                        <div className="ft-14 mt-1 red">{errors.color}</div>
                      ) : null} */}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="form-label">Stripe Key</label>
                      <input
                        type="text"
                        name="strip_key"
                        value={values.strip_key || ""}
                        onChange={handleChange}
                        className={`form-control form-control-user  ${errors.strip_key ? "error" : ""
                          }`}
                      />
                      {errors.strip_key && touched.strip_key ? (
                        <div className="ft-14 mt-1 red">{errors.strip_key}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Stripe Secret Key</label>
                      <input
                        type="text"
                        name="strip_secret"
                        value={values.strip_secret || ""}
                        onChange={handleChange}
                        className={`form-control form-control-user  ${errors.strip_secret ? "error" : ""
                          }`}
                      />
                      {errors.strip_secret && touched.strip_secret ? (
                        <div className="ft-14 mt-1 red">
                          {errors.strip_secret}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Webhook URL</label>
                      <input
                        type="text"
                        name="webhook_url"
                        value={values.webhook_url || ""}
                        onChange={handleChange}
                        placeholder="Enter webhook url"
                        className={`form-control form-control-user ${errors.webhook_url ? "error" : ""
                          }`}
                      />
                      {errors.webhook_url && touched.webhook_url ? (
                        <div className="ft-14 mt-1 red">
                          {errors.webhook_url}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Package Id</label>
                      <input
                        type="text"
                        name="package_id"
                        value={values.package_id || ""}
                        onChange={handleChange}
                        placeholder="Enter package id"
                        className={`form-control form-control-user  ${errors.package_id ? "error" : ""
                          }`}
                      />
                      {errors.package_id && touched.package_id ? (
                        <div className="ft-14 mt-1 red">
                          {errors.package_id}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Sub Domain URL</label>
                      <input
                        type="text"
                        name="subdomain_url"
                        value={values.subdomain_url || ""}
                        onChange={handleChange}
                        placeholder="Enter subDomain url"
                        className={`form-control form-control-user ${errors.subdomain_url ? "error" : ""
                          }`}
                      />
                      {errors.subdomain_url && touched.subdomain_url ? (
                        <div className="ft-14 mt-1 red">
                          {errors.subdomain_url}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        Splash Screen
                      </label>
                      <input
                        id="image"
                        type="file"
                        name="splash_logo"
                        accept=".png, .jpg, .jpeg, .svg"
                        onChange={(event) => {
                          setFieldValue("splash_logo", event.currentTarget.files[0]);
                        }}
                        className={`form-control form-select-user ${errors.splash_logo ? "error" : ""
                          }`}
                      />
                      {errors.splash_logo && (
                        <div className="ft-14 mt-1 red">{errors.splash_logo}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        Thumbnail
                      </label>
                      <input
                        id="image"
                        type="file"
                        name="thumbnail"
                        accept=".png, .jpg, .jpeg, .svg"
                        onChange={(event) => {
                          setFieldValue("thumbnail", event.currentTarget.files[0]);
                        }}
                        className={`form-control form-select-user ${errors.thumbnail ? "error" : ""
                          }`}
                      />
                      {errors.thumbnail && (
                        <div className="ft-14 mt-1 red">{errors.thumbnail}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        Support email
                      </label>
                      <input
                        type="text"
                        name="support_email"
                        value={values.support_email || ""}
                        onChange={handleChange}
                        placeholder="Enter Your Email"
                        className={`form-control form-control-user ${errors.support_email ? "error" : ""
                          }`}
                      />
                      {errors.support_email && (
                        <div className="ft-14 mt-1 red">{errors.support_email}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        Company Slogan
                      </label>
                      <input
                        type="text"
                        id="slogan"
                        name="slogan"
                        value={values.slogan || ""}
                        onChange={handleChange}
                        className={`form-control form-select-user ${errors.slogan ? "error" : ""
                          }`}
                      />
                      {errors.slogan && (
                        <div className="ft-14 mt-1 red">{errors.slogan}</div>
                      )}

                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        Email Logo
                      </label>
                      <input
                        id="image"
                        type="file"
                        name="mail_logo"
                        accept=".png, .jpg, .jpeg, .svg"
                        onChange={(event) => {
                          setFieldValue("mail_logo", event.currentTarget.files[0]);
                          const error = checkFileSize(event.currentTarget.files[0].size / 1024 / 1024);
                          errors.mail_logo = error;
                        }}
                        className={`form-control form-select-user ${errors.mail_logo ? "error" : ""
                          }`}
                      />
                      {errors.mail_logo && (
                        <div className="ft-14 mt-1 red">{errors.mail_logo}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        Android App URL
                      </label>
                      <input
                        type="text"
                        name="android_url"
                        value={values.android_url || ""}
                        onChange={handleChange}
                        placeholder="Enter subDomain url"
                        className={`form-control form-control-user ${errors.android_url ? "error" : ""
                          }`}
                      />
                      {errors.android_url && touched.android_url ? (
                        <div className="ft-14 mt-1 red">
                          {errors.android_url}
                        </div>
                      ) : null}

                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        App Store URL
                      </label>
                      <input
                        type="text"
                        name="ios_url"
                        value={values.ios_url || ""}
                        onChange={handleChange}
                        placeholder="Enter subDomain url"
                        className={`form-control form-control-user ${errors.ios_url ? "error" : ""
                          }`}
                      />
                      {errors.ios_url && touched.ios_url ? (
                        <div className="ft-14 mt-1 red">
                          {errors.ios_url}
                        </div>
                      ) : null}

                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Logo color scheme</label>
                      <Row className={`form-control form-control-user }`} >
                        <Space style={{ position: "absolute", top: "36px" }}>
                          <Col>
                            <ColorPicker
                              name={"color"}
                              format={formatHexLogo}
                              value={colorHexLogo}
                              trigger="hover"
                              onChange={(_, value) => {
                                setColorHexLogo(value)
                                handleChange("color", value)
                              }}
                              onFormatChange={setFormatHexLogo}
                            />
                          </Col>
                          <Col>
                            Hex: <span>{rgbStringLogo}</span>
                          </Col>
                        </Space>
                      </Row>
                      {/* {errors.color && touched.color ? (
                        <div className="ft-14 mt-1 red">{errors.color}</div>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Pickup Station</label>

                      <select
                        className={`form-control form-select-user dropdown-height ${errors.default_pickup_station_id ? "error" : ""
                          }`}
                        name="default_pickup_station_id"
                        value={values.default_pickup_station_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option value="">Select Pickup</option>
                        {whitelabelStationList &&
                          whitelabelStationList.map((rwecord, index) => (
                            <option value={rwecord.id} key={index}>
                              {rwecord.name}
                            </option>
                          ))}
                      </select>
                      {errors.default_pickup_station_id && (
                        <div className="ft-14 mt-1 red">{errors.default_pickup_station_id}</div>
                      )}

                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Dropoff Station</label>

                      <select
                        className={`form-control form-select-user dropdown-height ${errors.default_dropoff_station_id ? "error" : ""
                          }`}
                        name="default_dropoff_station_id"
                        value={values.default_dropoff_station_id}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option value="">Select Dropoff</option>
                        {whitelabelStationList &&
                          whitelabelStationList.map((rwecord, index) => (
                            <option value={rwecord.id} key={index}>
                              {rwecord.name}
                            </option>
                          ))}
                      </select>
                      {errors.default_dropoff_station_id && (
                        <div className="ft-14 mt-1 red">{errors.default_dropoff_station_id}</div>
                      )}

                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        VAT %
                      </label>
                      <input
                        type="number"
                        step="any"
                        name="vat"
                        value={vatValue}
                        onChange={handleVatValue}
                        placeholder="Enter VAT %"
                        min="1" max="100"
                        className={`form-control form-control-user ${errors.vat ? "error" : ""
                          }`}
                      />
                      {errors.vat && touched.vat ? (
                        <div className="ft-14 mt-1 red">
                          {errors.vat}
                        </div>
                      ) : null}

                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        VAT Code
                      </label>
                      <input
                        type="text"
                        name="vat_code"
                        value={values.vat_code || ""}
                        onChange={handleChange}
                        placeholder="Enter VAT Code"
                        min="1" max="100"
                        className={`form-control form-control-user ${errors.vat_code ? "error" : ""
                          }`}
                      />
                      {errors.vat_code && touched.vat_code ? (
                        <div className="ft-14 mt-1 red">
                          {errors.vat_code}
                        </div>
                      ) : null}

                    </div>


                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        Address
                      </label>
                      <input
                        type="text"
                        step="any"
                        name="address"
                        value={values.address || ""}
                        onChange={handleChange}
                        placeholder="Enter address"
                        className={`form-control form-control-user ${errors.address ? "error" : ""
                          }`}
                      />
                      {errors.address && touched.address ? (
                        <div className="ft-14 mt-1 red">
                          {errors.address}
                        </div>
                      ) : null}

                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        Registration Number
                      </label>
                      <input
                        type="text"
                        step="any"
                        name="registration_no"
                        value={values.registration_no || ""}
                        onChange={handleChange}
                        placeholder="Enter registration number"
                        className={`form-control form-control-user ${errors.registration_no ? "error" : ""
                          }`}
                      />
                      {errors.registration_no && touched.registration_no ? (
                        <div className="ft-14 mt-1 red">
                          {errors.registration_no}
                        </div>
                      ) : null}

                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Font color scheme</label>
                      <Row className={`form-control form-control-user }`} >
                        <Space style={{ position: "absolute", top: "36px" }}>
                          <Col>
                            <ColorPicker
                              name={"color"}
                              format={formatHexFont}
                              value={colorHexFont}
                              trigger="hover"
                              onChange={(_, value) => {
                                setColorHexFont(value)
                                handleChange("color", value)
                              }}
                              onFormatChange={setFormatHexFont}
                            />
                          </Col>
                          <Col>
                            Hex: <span>{rgbStringFont}</span>
                          </Col>
                        </Space>
                      </Row>
                      {/* {errors.color && touched.color ? (
                            <div className="ft-14 mt-1 red">{errors.color}</div>
                          ) : null} */}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Timezone</label>
                      <select
                        className={`form-control form-select-user dropdown-height ${errors.timezone ? "error" : ""
                          }`}
                        name="timezone"
                        value={values.timezone}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      > <option value="">Select Timezone</option>
                        {timezoneList &&
                          timezoneList.map((record, index) => (
                            <option value={record} key={index}>
                              {record}
                            </option>
                          ))}</select>
                      {errors.timezone && (
                        <div className="ft-14 mt-1 red">{errors.timezone}</div>
                      )}
                    </div>
                  </div>

                  {/* <div className=" form-row">

                    <div className="form-group col-md-2">
                      <label className="form-label">Vehicle Permissions</label>
                      <FieldArray
                        name="permissions"
                        render={(arrayHelpers) => (
                          <div>
                            {userpermissions.vehicle &&
                              userpermissions.vehicle.map((permission) => (
                                <div key={permission.id}>
                                  <label
                                    htmlFor={`permissions-${permission.id}`}
                                  >
                                    <input
                                      name="permissions"
                                      type="checkbox"
                                      id={`permissions-${permission.id}`}
                                      value={permission.id}
                                      checked={values.permissions.includes(
                                        permission.id
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          arrayHelpers.push(permission.id);
                                        else {
                                          const idx =
                                            values.permissions.indexOf(
                                              permission.id
                                            );
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                    />{" "}
                                    {permission.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      />
                      {errors.permissions && (
                        <div className="ft-14 mt-1 red">
                          {errors.permissions}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-2">
                      <label className="form-label">Usage Permissions</label>
                      <FieldArray
                        name="permissions"
                        render={(arrayHelpers) => (
                          <div>
                            {userpermissions.usage &&
                              userpermissions.usage.map((permission) => (
                                <div key={permission.id}>
                                  <label
                                    htmlFor={`permissions-${permission.id}`}
                                  >
                                    <input
                                      name="permissions"
                                      type="checkbox"
                                      id={`permissions-${permission.id}`}
                                      value={permission.id}
                                      checked={values.permissions.includes(
                                        permission.id
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          arrayHelpers.push(permission.id);
                                        else {
                                          const idx =
                                            values.permissions.indexOf(
                                              permission.id
                                            );
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                    />{" "}
                                    {permission.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      />
                      {errors.permissions && (
                        <div className="ft-14 mt-1 red">
                          {errors.permissions}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-2">
                      <label className="form-label">Share Permissions</label>
                      <FieldArray
                        name="permissions"
                        render={(arrayHelpers) => (
                          <div>
                            {userpermissions.share &&
                              userpermissions.share.map((permission) => (
                                <div key={permission.id}>
                                  <label
                                    htmlFor={`permissions-${permission.id}`}
                                  >
                                    <input
                                      name="permissions"
                                      type="checkbox"
                                      id={`permissions-${permission.id}`}
                                      value={permission.id}
                                      checked={values.permissions.includes(
                                        permission.id
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          arrayHelpers.push(permission.id);
                                        else {
                                          const idx =
                                            values.permissions.indexOf(
                                              permission.id
                                            );
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                    />{" "}
                                    {permission.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      />
                      {errors.permissions && (
                        <div className="ft-14 mt-1 red">
                          {errors.permissions}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-2">
                      <label className="form-label">User Permissions</label>
                      <FieldArray
                        name="permissions"
                        render={(arrayHelpers) => (
                          <div>
                            {userpermissions.user &&
                              userpermissions.user.map((permission) => (
                                <div key={permission.id}>
                                  <label
                                    htmlFor={`permissions-${permission.id}`}
                                  >
                                    <input
                                      name="permissions"
                                      type="checkbox"
                                      id={`permissions-${permission.id}`}
                                      value={permission.id}
                                      checked={values.permissions.includes(
                                        permission.id
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          arrayHelpers.push(permission.id);
                                        else {
                                          const idx =
                                            values.permissions.indexOf(
                                              permission.id
                                            );
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                    />{" "}
                                    {permission.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      />
                      {errors.permissions && (
                        <div className="ft-14 mt-1 red">
                          {errors.permissions}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-2">
                      <label className="form-label">Profile Permissions</label>
                      <FieldArray
                        name="permissions"
                        render={(arrayHelpers) => (
                          <div>
                            {userpermissions.profile &&
                              userpermissions.profile.map((permission) => (
                                <div key={permission.id}>
                                  <label
                                    htmlFor={`permissions-${permission.id}`}
                                  >
                                    <input
                                      name="permissions"
                                      type="checkbox"
                                      id={`permissions-${permission.id}`}
                                      value={permission.id}
                                      checked={values.permissions.includes(
                                        permission.id
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          arrayHelpers.push(permission.id);
                                        else {
                                          const idx =
                                            values.permissions.indexOf(
                                              permission.id
                                            );
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                    />{" "}
                                    {permission.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      />
                      {errors.permissions && (
                        <div className="ft-14 mt-1 red">
                          {errors.permissions}
                        </div>
                      )}
                    </div>

                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-2">
                      <label className="form-label">Firmware Permissions</label>
                      <FieldArray
                        name="permissions"
                        render={(arrayHelpers) => (
                          <div>
                            {userpermissions.firmware &&
                              userpermissions.firmware.map((permission) => (
                                <div key={permission.id}>
                                  <label
                                    htmlFor={`permissions-${permission.id}`}
                                  >
                                    <input
                                      name="permissions"
                                      type="checkbox"
                                      id={`permissions-${permission.id}`}
                                      value={permission.id}
                                      checked={values.permissions.includes(
                                        permission.id
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          arrayHelpers.push(permission.id);
                                        else {
                                          const idx =
                                            values.permissions.indexOf(
                                              permission.id
                                            );
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                    />{" "}
                                    {permission.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      />
                      {errors.permissions && (
                        <div className="ft-14 mt-1 red">
                          {errors.permissions}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-2">
                      <label className="form-label">Serial Permissions</label>
                      <FieldArray
                        name="permissions"
                        render={(arrayHelpers) => (
                          <div>
                            {userpermissions.serial &&
                              userpermissions.serial.map((permission) => (
                                <div key={permission.id}>
                                  <label
                                    htmlFor={`permissions-${permission.id}`}
                                  >
                                    <input
                                      name="permissions"
                                      type="checkbox"
                                      id={`permissions-${permission.id}`}
                                      value={permission.id}
                                      checked={values.permissions.includes(
                                        permission.id
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          arrayHelpers.push(permission.id);
                                        else {
                                          const idx =
                                            values.permissions.indexOf(
                                              permission.id
                                            );
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                    />{" "}
                                    {permission.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      />
                      {errors.permissions && (
                        <div className="ft-14 mt-1 red">
                          {errors.permissions}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-2">
                      <label className="form-label">
                        Whitelabel Permissions
                      </label>
                      <FieldArray
                        name="permissions"
                        render={(arrayHelpers) => (
                          <div>
                            {userpermissions.label &&
                              userpermissions.label.map((permission) => (
                                <div key={permission.id}>
                                  <label
                                    htmlFor={`permissions-${permission.id}`}
                                  >
                                    <input
                                      name="permissions"
                                      type="checkbox"
                                      id={`permissions-${permission.id}`}
                                      value={permission.id}
                                      checked={values.permissions.includes(
                                        permission.id
                                      )}
                                      onChange={(e) => {
                                        if (e.target.checked)
                                          arrayHelpers.push(permission.id);
                                        else {
                                          const idx =
                                            values.permissions.indexOf(
                                              permission.id
                                            );
                                          arrayHelpers.remove(idx);
                                        }
                                      }}
                                    />{" "}
                                    {permission.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        )}
                      />
                      {errors.permissions && (
                        <div className="ft-14 mt-1 red">
                          {errors.permissions}
                        </div>
                      )}
                    </div>
                  </div> */}
                  {Helper.getPermissions("whitelabel-update", permissions && permissions, user.type) &&
                    <div className="form-row">
                      <div className="form-group d-flex justify-content-end col-md-12">
                        <button
                          type="submit"
                          className="btn-submit btn-user"
                          style={{ backgroundColor: user.background_color }}
                          disabled={LocalState.disable}
                        >
                          confirm Details
                          {LocalState.disable ? (
                            <span
                              className="ml-2 spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            ""
                          )}
                        </button>
                      </div>
                    </div>
                  }
                </Form>
              )}
            </Formik>
          )}

          {/* <div className="row">
              <div className="col-md-12 mb-4">
                Usages: <u>20</u>
              </div>
              <div className="col-md-12">
                Drivers behaviour score: <u>4.5</u>
              </div>
            </div> */}
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default EditWhiteLabel;
