import React from 'react';

const EmailNotificatio = () => {
  return (
    <div>
      <h3>Email Notification</h3>
    </div>
  );
};

export default EmailNotificatio;