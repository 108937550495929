import React, { useEffect, useContext, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../Header/Index.js";
import { Modal, Button, Icon, ButtonToolbar } from "rsuite";
// import { Modal,Button } from 'react-bootstrap';
import { Context } from "../Store/Store";
import Echo from "laravel-echo";
import Pagination from "react-js-pagination";
import Helper from "../Helper";
import { broadcastAuth, EventDoor, EventTrunk } from "../Api/ApiRoutes";
import { useHistory } from 'react-router-dom';
var { overlay } = require("../Helper");

var {
  MySharesWithMeAPI,
  deleteVehicle,
  alldeleteVechile,
  UpdateSoftwaree,
} = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");
const _ = require("lodash");
window.Pusher = require("pusher-js");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function SharesWithMeList() {
  const [state, dispatch] = useContext(Context);
  const [records, setrecords] = React.useState([]);
  const [listenerEvent, setListenerEvent] = React.useState(0);
  const [activePage, setactivePage] = React.useState(1);
  const [show, setShow] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [doorLoader, setDoorLoader] = React.useState(null);
  const [trunkLoader, setTrunkLoader] = React.useState(null);
  const [isSoftware, setIsSoftware] = React.useState(null);
  //const handleClose = () => setShow(false);
  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [result, setResult] = React.useState({});
  const [hidePagination, sethidePagination] = React.useState(false);
  const [locationKeys, setLocationKeys] = React.useState([])
  const [rsModal, setRsModal] = React.useState(false);
  const [sort_by, setSortBy] = React.useState(1);

  const [currentId, setCurrentId] = React.useState(null);

  var location = useLocation()
  const history = useHistory()

  const Modalclose = () => {
    setRsModal(false);
  };
  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };

  var user = Helper.getStorageData("SESSION");
  //console.log("userrrr",user);
  var permissions = user.permissions;
  //console.log("user:::::::::",user);

  const handleClose = () => {
    setShow(false);
    setDoorLoader(null);
    setTrunkLoader(null);
  };

  const isoToDate = (isoStr) => {
    if (isoStr === "") {
      return "--"
    }
    const date = new Date(isoStr);
    let localdDate = date.toLocaleDateString();

    var day = date.getDate();
    var month = date.getMonth() + 1; //months are zero based
    var year = date.getFullYear();

    var time = date.toLocaleTimeString().replace(/(.*)\D\d+/, '$1');

    let newTimeFormat = time + " " + day + "-" + month + "-" + year;
    return newTimeFormat;
  }

  const getVehicleList = useCallback((page, sort) => {
    Helper.overlay(true);
    apiRequest(
      MySharesWithMeAPI.method,
      MySharesWithMeAPI.url + `?page=${page || state.set_active_page}&sort_by=${sort || sort_by}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setrecords(response.data.data);
          setResult(response.data.meta);
          dispatch({ type: "SET_SEARCH_STATE", search_list: false });
          sethidePagination(false);
          Helper.overlay(false);
        } else {
          setErrorMsg(response.data.message)
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        setErrorMsg(error)
        Helper.overlay(false);
      });
  },[dispatch, sort_by, state.set_active_page]);

  useEffect(() => {
    if (location.hash !== "#search") {
      dispatch({ type: "SET_STATE", response: [] });
      getVehicleList(activePage);
    } else {
      Helper.overlay(false);
    }
  }, [activePage, dispatch, getVehicleList, location.hash, state.search_list]);

  useEffect(() => {
    if (location.pathname.includes('shares-with-me-list') && location.hash === "#search") {
      setrecords(state.data.data || [])
      setResult(state.data.meta || [])
      sethidePagination(true);
      dispatch({ type: "SET_SEARCH_STATE", pagination: false });
      Helper.overlay(false)
    }
    if (location.hash === "#error") {
      setrecords([]);
      setResult([]);
      sethidePagination(true);
      setErrorMsg('No record Found');
      Helper.overlay(false)
    }
  }, [dispatch, location, state.data, state.pagination]);


  function getcheckbox() {
    var array = [];
    var checkboxes = document.querySelectorAll(
      "input[name='subcheckbox']:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }
    console.log("array", array);
    let params = new FormData();
    params.append("ids", array);
    let delete_property = window.confirm(
      "Are you sure you want to delete these records!"
    );
    if (delete_property) {
      apiRequest(alldeleteVechile.method, alldeleteVechile.url, params)
        .then((response) => {
          let data = response.data;
          if (response.data.code == 200) {
            let data = records.filter((d) => {
              return d.id != currentId
            })
            setrecords(data);
            setRsModal(false);
          } else {
            console.log("Failure", response);
          }
        })
        .catch((error) => {
          //console.log("error", error);
          alert(error);
        });
    }
  }

  function handleMainCheckBox(e) {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    Array.from(document.querySelectorAll("input[name='subcheckbox']")).forEach(
      (checkbox) => {
        checkbox.checked = value;
      }
    );
  }

  const deleteRecord = (e) => {
    //let params = new FormData();
    //params.append("id", e.target.dataset.id);
    // let delete_property = window.confirm(
    //   "Are you sure you want to delete this record!"
    // );
    // if (delete_property) {

    apiRequest(deleteVehicle.method, deleteVehicle.url + `${currentId}`)
      .then((response) => {
        if (response.data.code == 200) {
          let data = records.filter((d) => {
            return d.id != currentId.trim()
          })
          setrecords(data);
          setRsModal(false);
          console.log("Success", response);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        //console.log("error", error);
        alert(error);
      });

    //}
  };

  const UpdateSoftware = (version_id, car_id) => {
    setIsSoftware(null);
    //let params = new FormData();
    //params.append("id", e.target.dataset.id);
    // let delete_property = window.confirm(
    //   "Are you sure you want to delete this record!"
    // );
    //if (delete_property) {
    //console.log("update function runungdddddd",e); return false;
    let params = new FormData();
    params.append("version", version_id);
    params.append("car_id", car_id);
    Localdispatch({ type: "DEFAULT", payload: true });
    Helper.overlay(true);
    apiRequest(UpdateSoftwaree.method, UpdateSoftwaree.url, params)
      .then((response) => {
        let data = response.data;
        //console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaa",response);
        if (response.data.code == 200) {
          setIsSoftware(car_id);
          //setrecords(response.data.data);
          //window.location.reload();
          //getVehicleList(activePage)
          console.log("Success", response);
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          dispatch({ type: "SET_STATE", response: data, advanceSearch: true });
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        //console.log("error", error.response);
        // alert(error.response.data.message);
        //console.log("error", error.response);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        //Localdispatch({ type: 'FETCH_ERROR', payload: error.response.data.data })
        //dispatch({type: 'SET_STATE', response:[]} );
        console.log("FETCH_ERROR");
        Helper.overlay(false);
        //alert(error.response.data.message);
        setShow(true);
        setErrorMsg(error.response.data.message);
      });

    //}
  };

  // useEffect(() => {
  useEffect(() => {
    // if(Helper.getPermissions(
    //   "vehicle-read",
    //   permissions && permissions,
    //   "admin"
    // ) || Helper.getPermissions("", [], user.type)){
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "app",
      wsHost: "dev.movitronic.com",
      wsPort: 6001,
      wssPort: 6001,
      forceTLS: true,
      useTLS: true,
      disableStats: true,
      encrypted: true,
      transports: ["websocket", "polling", "flashsocket"],
      authEndpoint: broadcastAuth.url,
      auth: { headers: { Authorization: "Bearer " + user.access_token } },
    });
    window.Echo.private(`movitronic`).listen(".usage", (e) => {
      // console.log(e);
      Helper.overlay(false);
      if (e.type == "trunk") {
        setTrunkLoader(null);
        Localdispatch({
          type: "FETCH_SUCCESS",
          payload: "Trunk opened",
        });
      } else {
        setDoorLoader(null);
        Localdispatch({
          type: "FETCH_SUCCESS",
          payload: "Vehicle status updated",
        });
      }
    });
  }, []);

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys)
        } else {
          setLocationKeys((keys) => [location.key, ...keys])
          history.push(`${location.pathname}`);
          dispatch({ type: "SET_SEARCH_STATE", search_list: true });
        }
      }
    })
  }, [dispatch, history, locationKeys])

  // useEffect(() => {
  //   if (state.data.length) {
  //     setrecords(state.data);
  //     sethidePagination(true);
  //   } else {
  //     console.log("Here");
  //     setrecords([]);
  //   }
  // }, [state.data]);

  const doorStatus = (e, carid, cmd) => {
    setDoorLoader({ "id": carid, "type": cmd });

    document.getElementById(e.target.id).disabled = true;
    var formData = new FormData();
    let command = cmd ? false : true;
    formData.append("cmd", command);
    formData.append("car_id", carid);
    Localdispatch({ type: "DEFAULT", payload: true });
    Helper.overlay(true);
    apiRequest(EventDoor.method, EventDoor.url, formData)
      .then((response) => {
        if (response.data.code == 200) {
          let data = response.data.data;
          // getVehicleList(activePage);
          document.getElementById(e.target.id).disabled = false;
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          // Helper.overlay(false);
          // setDoorLoader(null);
        } else if (response.data.code == 401) {
          document.getElementById(e.target.id).disabled = false;
        } else {
          document.getElementById(e.target.id).disabled = false;
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        //Localdispatch({ type: 'FETCH_ERROR', payload: error.response.data.data })
        //dispatch({type: 'SET_STATE', response:[]} );
        Helper.overlay(false);
        //alert(error.response.data.message);
        setShow(true);
        setErrorMsg(error.response.data.message);
      });
  };

  const trunkStatus = (e, carid, cmd) => {
    setTrunkLoader(carid);
    document.getElementById(e.target.id).disabled = true;
    var formData = new FormData();
    //let command = cmd ? false : true;
    let command = true;
    formData.append("cmd", command);
    formData.append("car_id", carid);
    Localdispatch({ type: "DEFAULT", payload: true });
    Helper.overlay(true);
    apiRequest(EventTrunk.method, EventTrunk.url, formData)
      .then((response) => {
        if (response.data.code == 200) {
          // console.log("Success", response.data.data);
          // setTrunkLoader(null);
          // getVehicleList(activePage);
          document.getElementById(e.target.id).disabled = false;
        } else if (response.data.code == 401) {
          document.getElementById(e.target.id).disabled = false;
          // console.log("Failure", response);
        } else {
          document.getElementById(e.target.id).disabled = false;
          // console.log("Failure", response);
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        //Localdispatch({ type: 'FETCH_ERROR', payload: error.response.data.data })
        //dispatch({type: 'SET_STATE', response:[]} );
        console.log("FETCH_ERROR");
        Helper.overlay(false);
        //alert(error.response.data.message);
        setShow(true);
        setErrorMsg(error.response.data.message);
      });
  };
  const handlePageChange = (pageNumber) => {
    //console.log(`active page is ${pageNumber}`);
    Helper.overlay(true)
    setactivePage(pageNumber);
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    apiRequest(
      MySharesWithMeAPI.method,
      MySharesWithMeAPI.url + `?page=${pageNumber}`
    )
      .then((response) => {
        let data = response.data;
        if (response.data.code == 200) {
          setrecords(response.data.data);
          Helper.overlay(false)
          //console.log("Success", response);
        } else {
          Helper.overlay(false)
          //console.log("Failure", response);
        }
      })
      .catch((error) => {
        Helper.overlay(false)
        //console.log("error", error);
        alert(error);
      });
  };
  const setDropDown = (e) => {
    setSortBy(e.target.value)
    getVehicleList(state.set_active_page, e.target.value)
  }
  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={show} onHide={handleClose}>
        
        <Modal.Body> <h4 className="text-center">{errorMsg}</h4>
        <Button variant="secondary" className="float-right" onClick={handleClose}>Close</Button>
        </Modal.Body>
      </Modal> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{errorMsg}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div id="content">
        <Header showSearch={true} />
        <div className="p-4">
          <div className="row border-bottom">
            <div className="col-md-8">
              <div className="d-flex flex-row mb-3">
                {/* <div className="p-2">
                    {(Helper.getPermissions(
                          "vehicle-delete",
                          permissions && permissions,
                          "admin"
                        ) ||
                          Helper.getPermissions("", [], user.type)) && (
                    <input type="checkbox" onChange={handleMainCheckBox}/>
                          )}
                    </div> */}
                <div className="p-2">
                  {/* <button className="btn btn-primary" onClick={getcheckbox}>Bulk Delete</button> */}
                </div>
                {/* {
                      Helper.getPermissions(
                        "vehicle-create",
                        permissions && permissions,
                        "admin"
                      ) || Helper.getPermissions("", [], user.type) && */}
                {/* <div className="p-2">
                      {(Helper.getPermissions(
                    "vehicle-create",
                    permissions && permissions,
                    "admin"
                  ) ||
                    Helper.getPermissions("", [], user.type)) && (
                        <NavLink
                          exact
                          to={`/admin/add-vehicle`}
                          className="btn white bg-orange"
                        >
                          New Vehicle
                        </NavLink>
                    )}
                      </div> */}
                {/* } */}
              </div>
            </div>

            <div className="col-lg-4 col-md-6  ">
              <div className="d-flex row align-items-center">
                <div className="col-md-12 col-lg-3 pl-lg-0 pr-lg-0">Results: {result.total ? result.total : '0'}</div>
                <div className="col-md-12 col-lg-9 pl-lg-0 pr-lg-0 row">
                <label class="col-3 mt-auto">Sort By:</label>
                  <select className="form-select form-control col-8" aria-label="Default select example" name="sort_by" onChange={setDropDown}>
                    <option selected={sort_by === 1 ? true : false} value={1}>Last created on top</option>
                    <option selected={sort_by === 2 ? true : false} value={2}>First created on top</option>
                    <option selected={sort_by === 3 ? true : false} value={3}>Alphabetically A-Z</option>
                    <option selected={sort_by === 4 ? true : false} value={4}>Alphabetically Z-A</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {records.length ? (
              records.map((record, index) => {
                return (
                  <div
                    className="col-md-12 mt-3 user border-bottom pb-3 ft-14 flex card-shadow card shadow bg-white rounded"
                    key={index}
                  >
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 mt-2 white-space-nowrap sm-center">
                        <div className="">
                          <span className="card-title text-uppercase">
                            <div className="d-flex">
                              <input
                                type="checkbox"
                                className=""
                                name="subcheckbox"
                                defaultValue={record.vehicle.detail.id}
                                id="exampleCheck1"
                              />
                              <div className="ml-2">
                                {record.vehicle.name}
                              </div>
                            </div>
                          </span>
                        </div>
                        <img
                          src={record.vehicle.file}
                          className="card-img-top img-fluid pt-2"
                          alt="..."
                          width="70"
                          height="70"
                          style={{ borderRadius: "50% !important" }}

                        />
                      </div>
                      <div className="col-md-12 col-lg-2 mt-2 white-space-nowrap">
                        <div className="d-flex">
                          Color:
                          <strong className="pl-1">
                            {record.vehicle && record.vehicle.colour}
                          </strong>
                        </div>


                        <div className="d-flex">
                          Status: {record.vehicle.status ? record.vehicle.status : "Sleep mode"}
                        </div>
                        <div className="d-flex">
                          Operator:
                          <span className="font-weight-bold pl-1">
                            <strong>
                              {" "}
                              <u>{record.vehicle.operator}</u>
                            </strong>
                          </span>
                        </div>
                        {record.vehicle.status !== "online" && record.vehicle.status !== "charged" && record.vehicle.status !== "pluggedin" ? (
                          <>
                            <div className="d-flex">
                              Year: <strong className="pl-1">  {record.vehicle.year}</strong>
                            </div>
                            <div className="d-flex">
                              Car Make: <strong className="pl-1"> {" "}
                                {record.vehicle.vehicle_company_name}</strong>
                            </div>

                          </>
                        )
                          : (" ")}
                        <div className="d-flex">
                          Hardware: <strong className="pl-1">{record.vehicle.hardware_version ? record.vehicle.hardware_version : "-"}</strong>
                        </div>

                        <div className="d-flex">
                          Device Code: <strong className="pl-1">   {record.vehicle.detail.device_code ? record.vehicle.detail.device_code : "-"}</strong>
                        </div>
                        <div className="d-flex">
                          Engine: <strong className="pl-1">  {record.vehicle.engine ? record.vehicle.engine : "-"}</strong>
                        </div>
                        <div className="d-flex">
                          Battery Voltage: <strong className="pl-1">  {record.vehicle.battery_voltage ? record.vehicle.battery_voltage : "-"}</strong>
                        </div>
                      </div>

                      <div className="col-md-12 col-lg-2 mt-2 white-space-nowrap">

                        <div className="d-flex">
                          Fuel level: <strong className="pl-1">{record.vehicle.detail.fuel ? record.vehicle.detail.fuel + "%" : "-"}</strong>
                        </div>

                        <div className="d-flex">
                          Central lock: <strong><span className="text-danger pl-1">{record.vehicle.door_button ? "Open" : "Closed"}</span></strong>
                        </div>
                        <div className="d-flex justify-content-between">
                          Tags: {record.vehicle.tags}
                        </div>
                        <div className="d-flex">
                          Shared:  <span><input
                            type="checkbox"
                            disabled
                            className="ml-2"
                            id="shared"
                            defaultChecked={record.share}
                          />
                            <label
                              className="form-check-label"
                              htmlFor="shared"
                            >
                            </label></span>
                        </div>
                        <div className="d-flex">
                          Active shares: <span className="font-weight-bold pl-1">
                            <u>{record.vehicle.active_shares}</u>
                          </span>
                        </div>
                        <div className="d-flex">
                          Firmware: <strong className="pl-1"> { record.vehicle.software_version ? record.vehicle.software_version : "-"}</strong>
                        </div>
                        <div className="d-flex">
                          Last online: <strong className="pl-1">  {record.vehicle.last_online ? record.vehicle.last_online : "-"}</strong>
                        </div>
                        {record.vehicle.status !== "online" && record.vehicle.status !== "charged" && record.vehicle.status !== "pluggedin" ? (
                          <>
                            <div className="d-flex">
                              Current user: <span className="font-weight-bold pl-1">
                                {record.vehicle?.usages?.current_user.name ? <u> {record.vehicle.usages.current_user.name} </u> : "-"}
                              </span>
                            </div>
                            <div className="d-flex">
                              License: <span className="font-weight-bold pl-1">
                                <u>{record.vehicle.license}</u>
                              </span>
                            </div>
                          </>
                        ) : ""}

                      </div>
                      <div className="col-md-12 col-lg-4 mt-2 white-space-nowrap">
                        <div className="d-flex">
                          Location: <span className="font-weight-bold pl-1">
                            {record.vehicle.location ? <u>{record.vehicle.location}</u> : "-"}
                            <NavLink
                              exact
                              to={`/admin/single-vehicle-map-list/${record.vehicle.id}`}
                            >
                              <i
                                className="fa fa-map-marker-alt pl-1"
                                style={{ color: "green" }}
                              ></i>
                            </NavLink>
                          </span>
                        </div>
                        <div className="d-flex">
                          Last usage: <span className="font-weight-bold pl-1">{record.vehicle.last_usage}</span>
                        </div>
                        <div className="d-flex">
                          Pevious usage:<span className="font-weight-bold pl-1">
                            {record.vehicle?.usages?.previos_usage.created_at ? <u>{record.vehicle.usages.previos_usage.created_at}</u> : "-"}
                          </span>
                        </div>
                        <div className="d-flex">
                          Current usage:<span className="font-weight-bold pl-1">
                            {record.vehicle.usages?.current_usage.created_at ? <u>{record.vehicle.usages.current_usage.created_at}</u> : "-"}
                          </span>
                        </div>
                        {record.vehicle.status !== "online" && record.vehicle.status !== "charged" && record.vehicle.status !== "pluggedin" ? (
                          <>
                            <div className="d-flex">
                              Car alarms:
                              <span className="text-danger pl-1">
                                {record.vehicle.alarms.car[0] ? record.vehicle.alarms.car.type : "-"}
                              </span>
                              <span>  {record.vehicle.alarms.car[0] ? " ( " + isoToDate(record.vehicle.alarms.car[0].updated_at) + " )" : ""} </span>
                            </div>

                            <div className="d-flex">
                              Device alarms:
                              <span className="text-danger pl-1">
                                {record.vehicle.alarms.device[0] ? record.vehicle.alarms.device[0].type : "-"}
                              </span>
                              <span>  {record.vehicle.alarms.device[0] ? " ( " + isoToDate(record.vehicle.alarms.device[0].updated_at) + " )" : ""} </span>
                            </div>
                          </>
                        ) : ""
                        }
                      </div>
                      <div className="col-md-12 col-lg-2 mt-2 d-flex align-items-center white-space-nowrap2">
                        <div className="card-body row">
                          <div className="col-md-6 col-lg-12 d-flex justify-content-end pt-2">
                            <button
                              id={`door${record.vehicle.id}`}
                              disabled={
                                doorLoader && record.vehicle.id === doorLoader.id ? true : false
                              }
                              className={`w-100 btn-danger text-uppercase`}
                              data-id={record.vehicle.id}
                              onClick={(e) =>
                                doorStatus(e, record.vehicle.id, 1)
                              }
                            >
                              {"Lock Doors"}
                              {doorLoader && record.vehicle.id === doorLoader.id && doorLoader.type == 1 ? (
                                <span
                                  className="ml-2 spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                            </button>{" "}
                          </div>
                          <div className="col-md-6 col-lg-12 d-flex justify-content-end pt-2">
                            <button
                              id={`door${record.vehicle.id}`}
                              disabled={
                                doorLoader && record.vehicle.id === doorLoader.id ? true : false
                              }
                              className={`btn-success text-uppercase w-100`}
                              data-id={record.vehicle.id}
                              onClick={(e) =>
                                doorStatus(e, record.vehicle.id, 0)
                              }
                            >
                              {"Unlock Doors"}
                              {doorLoader && record.vehicle.id === doorLoader.id && doorLoader.type == 0 ? (
                                <span
                                  className="ml-2 spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                            </button>{" "}
                          </div>
                          <div className="col-md-6 col-lg-12 d-flex justify-content-end pt-2">
                            <button
                              id={`trunk${record.vehicle.id}`}
                              disabled={
                                record.vehicle.id === trunkLoader ? true : false
                              }
                              className={`btn-success text-uppercase w-100`}
                              data-id={record.vehicle.id}
                              onClick={(e) =>
                                trunkStatus(
                                  e,
                                  record.vehicle.id,
                                  record.trunk_button
                                )
                              }
                            >
                              {`Open Trunk`}
                              {record.vehicle.id === trunkLoader ? (
                                <span
                                  className="ml-2 spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                );
              })
            ) : (
              (
                <div className="text-center ft-14 mt-3 font-weight-bold">
                  No Data Found...
                </div>
              )
            )}
          </div>
          <div className="row mt-3 ft-14">
            <div className="col-md-12">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  {!hidePagination && (
                    <Pagination
                      activePage={state.set_active_page}
                      itemsCountPerPage={result.to}
                      totalItemsCount={result.total ? result.total : 1}
                      pageRangeDisplayed={result.last_page}
                      itemClass="page-item"
                      linkClass="page-link"
                      onChange={handlePageChange}
                      activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                    />
                  )}
                  <li className="pl-3">
                    {/* <form className="form-inline">
                          <label className="my-1 mr-2">Results on page:</label>
                          <select
                            className="form-control p-0"
                            style={{ height: "30px" }}
                            defaultValue={"10"}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </form> */}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default SharesWithMeList;
