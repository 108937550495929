import React from "react";
import Header from "../Header/Index.js";
import { Redirect } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import Button from "../../commonComponents/Button.js";
var { apiRequest } = require("../Api/Service");
var { AddVehicleCompanyAPI } = require("../Api/ApiRoutes");
const Helper = require("../Helper");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function AddVehicleCompany() {
  const fileMaxSize = 10;
  const VehicleSchema = Yup.object().shape({
    name: Yup.string().required("This Field is Required"),
    file: Yup.mixed().required("This Field is Required")
      .test("fileSize", `File size exceeds the allowed limit of ${fileMaxSize} + 'MB'`, (value) => {
        if (!value) return true;
        return value && value.size / 1024 / 1024 <= fileMaxSize;
      })
  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);
  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;

  const handleSubmit = (values) => {
    const form_data = Helper.formData(values);
    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(AddVehicleCompanyAPI.method, AddVehicleCompanyAPI.url, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        //console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };
  const handleFieldBlur = (value) => {
    let error;
    if (value > fileMaxSize) {
      error = 'File size exceeds the allowed limit of ' + fileMaxSize + 'MB';
    } else {
      error = undefined
    }
    return error;
  };

  if (redirectToReferrer) {
    return <Redirect to={"/admin/company-vehicle-list/1"} />;
  }

  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
       <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        {Helper.getPermissions(
          "vehicle-create",
          permissions && permissions,
          "admin"
        ) || Helper.getPermissions("", [], user.type) ? (
          <div className="p-4">
            <h4 className="mb-3">Create Company</h4>
            <Formik
              validateOnChange={false}
              validationSchema={VehicleSchema}
              onSubmit={(values) => {
                // same shape as initial values
                handleSubmit(values);
              }}
              initialValues={{
                name: "",
                file: undefined,
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <form className="mt-2" onSubmit={handleSubmit}>
                  <FlashMessage
                    success={LocalState.success}
                    isSuccess={LocalState.isSuccess}
                    isError={LocalState.isError}
                    errors={LocalState.errors}
                  />
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Vehicle Company</label>
                      <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        placeholder="Vehicle Company"
                        style={{ height: "calc(1.5em + 0.75rem + 9px)" }}
                        className={`form-control ${errors.device_code ? "error" : ""
                          }`}
                      />
                      {errors.name && (
                        <div className="ft-14 mt-1 red">
                          {errors.name}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="file">
                        Vehicle Company picture
                      </label>
                      <input
                        id="file"
                        type="file"
                        name="file"
                        accept="images/*"
                        onChange={(event) => {
                          setFieldValue("file", event.currentTarget.files[0]);
                          const error = handleFieldBlur(event.currentTarget.files[0].size / 1024 / 1024);
                          errors.file = error;
                        }}
                        style={{ height: "calc(1.5em + 0.75rem + 9px)" }}
                        className={`form-control  ${errors.file ? "error" : ""
                          }`}
                      />
                      {errors.file && (
                        <div className="ft-14 mt-1 red">{errors.file}</div>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group d-flex justify-content-end col-md-12">
                    <Button
                      type={"submit"}
                      localState={LocalState}
                      title={'Save'}
                      size={"btn-sm"}
                    />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : (
          <div className="p-4">
            <div className="text-center ft-14 mt-3 font-weight-bold">
              You are not allowed to visit this screen...
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default AddVehicleCompany;
