import React, { useState, useEffect } from "react";
import Helper from "../Helper";
import moment from "moment";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
} from "@react-google-maps/api";

var { GetHeartBeatListing } = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");

const getPolylineColor = (path, index) => {
  for (let i = index; i < path.length; i++) {
    if (path[i].is_device_online) {
      return "red";
    } else {
      return "orange";
    }
  }
  return "red";
};

const MyPolyline = ({ path, index }) => {
  const strokeColor = getPolylineColor(path, index);
  return (
    <Polyline
      path={[path[index], path[index + 1]]}
      options={{
        strokeColor,
        strokeOpacity: 0,
        strokeWeight: 2,
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 4, // Adjust the scale to control the spacing between dots
            },
            offset: "0",
            repeat: "20px", // Adjust the repeat value to control the spacing between dots
          },
        ],
      }}
    />
  );
};

function VehocleTrip(props) {
  const [error, setError] = useState(null);
  const [resp, setResp] = useState([]);
  const [center, setCenter] = useState({});
  const [markers, setMarkers] = useState([]);
  const [path, setPaths] = useState([]);
  var user = Helper.getStorageData("SESSION");

  const today = moment().format("YYYY-MM-DD HH:mm:ss");
  const sevenDaysAgo = moment()
    .subtract(6, "days")
    .format("YYYY-MM-DD HH:mm:ss");
  const [selectedStartDate, setSelectedStartDate] = useState(sevenDaysAgo);
  const [selectedEndDate, setSelectedEndDate] = useState(today);
  const [selectedMarker, setSelectedMarker] = useState({
    marker: {},
    data: {},
  });

  const handleSubmitDates = () => {
    getVehicleList(
      moment(selectedStartDate).format("YYYY-MM-DD HH:mm:ss"),
      moment(selectedEndDate).format("YYYY-MM-DD HH:mm:ss")
    );
  };
  useEffect(() => {
    getVehicleList(sevenDaysAgo, today);
  }, []);
  const getVehicleList = (startDates, endDates) => {
    Helper.overlay(true);
    let vaid = props?.vid ? props.vid : "";
    apiRequest(
      GetHeartBeatListing.method,
      GetHeartBeatListing.url +
        `?vehicle_id=${vaid}&start=${startDates}&end=${endDates}&per_page=-1&is_map=1`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setError(null);
          setResp(response.data);
          initMap(response.data);
          Helper.overlay(false);
        } else {
          console.error("query failed");
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        setError(error.response.data.data.message);
        Helper.overlay(false);
      });
  };

  const mapContainerStyle = {
    width: "100%",
    height: "700px",
  };
  const initMap = (resp) => {
    //  useEffect(() => {
    if (resp.data) {
      const center = {
        lat: +resp?.data[0]?.lat, // Specify the initial center latitude
        lng: +resp?.data[0]?.long, // Specify the initial center longitude
      };
      setCenter(center);
      const markers =
        resp?.data?.length >= 2
          ? resp?.data
              .filter((val) => val?.lat != 0.0 && val?.long != 0.0)
              .map((val) => ({
                lat: +val?.lat,
                lng: +val?.long,
              }))
          : [];
      setMarkers(markers);

      // to show first and last lat/lng
      // const markers = resp?.data?.length >= 2
      //   ? [
      //     { "lat": +(resp.data[0]?.lat), "lng": +(resp.data[0]?.long) }, // First latitude and longitude
      //     { "lat": +(resp.data[resp.data.length - 1]?.lat), "lng": +(resp.data[resp.data.length - 1]?.long) } // Last latitude and longitude
      //   ]
      //   : [];
      // setMarkers(markers)

      const path = resp?.data
        ?.filter((val) => val?.lat != 0.0 && val?.long != 0.0)
        .map((val) => ({
          lat: +val?.lat,
          lng: +val?.long,
          is_device_online: val.is_device_online,
        }));
      setPaths(path);
    }
  };
  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setSelectedEndDate(event.target.value);
  };

  const handleMarkerClick = (marker, index) => {
    setSelectedMarker({ marker: marker, data: resp.data[index] });
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker({ marker: {}, data: {} });
  };

  return (
    <React.Fragment>
      <div id="content">
        <div className="d-flex justify-content-between pt-3">
          <div>
            <input
              type="datetime-local"
              id=" startDateTime"
              value={selectedStartDate}
              onChange={handleStartDateChange}
            />{" "}
            <input
              type="datetime-local"
              id="endDateTime"
              value={selectedEndDate}
              onChange={handleEndDateChange}
            />{" "}
            <button
              className="bg-orange btn btn-success btn-outline-light btn-sm ml-3 rounded-pill"
              onClick={handleSubmitDates}
              style={{ backgroundColor: user.background_color }}
            >
              Submit
            </button>
          </div>
          <div>Total Distance: {resp?.distance?.toFixed(3)}</div>
        </div>
        <div className="py-4">
          <div className="row">
            <div className="col-md-12">
              {error ? (
                error
              ) : (
                <>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={10} // Specify the initial zoom level
                    center={center}
                  >
                    {markers?.map((marker, index) => (
                      <Marker
                        key={index}
                        position={{ lat: marker.lat, lng: marker.lng }}
                        onClick={() => handleMarkerClick(marker, index)}
                      >
                        {selectedMarker.marker === marker && (
                          <InfoWindow onCloseClick={handleInfoWindowClose}>
                            <div>
                              <p>
                                <strong>lat :</strong> {marker.lat}
                              </p>
                              <p>
                                <strong>long :</strong> {marker.lng}
                              </p>
                              <p>
                                <strong>id :</strong> {selectedMarker.data?.id}
                              </p>
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                    ))}
                    {path.map((point, index) => (
                      <MyPolyline key={index} path={path} index={index} />
                    ))}
                  </GoogleMap>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default VehocleTrip;
