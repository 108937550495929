import React from "react";
import { Redirect } from "react-router-dom";
import Header from "../../Header/Index.js";

import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import FlashMessage from "../../FlashMessage/FlashMessage";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  AddOperator,
  AddWhiteLableOperator,
  UserPermissions,
  WhiteLablePermissions,
} from "../../Api/ApiRoutes.js";
import Button from "../../../commonComponents/Button.js";

var { apiRequest } = require("../../Api/Service");

const Helper = require("../../Helper");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function AddAdminUsersDetail() {
  const DetailSchema = Yup.object().shape({
    name: Yup.string().required("This Field is Required"),
    email: Yup.string().email().required("This Field is Required"),
    contact_no: Yup.number().required("This Field is Required"),
    password: Yup.string()
      .required("This Field is Required")
      .matches(/.{8,}/, "Minimum eight characters is Required")
      .matches(/(?=.*?[0-9])/, "at least one number is required")
      .matches(/(?=.*?[A-Z])|(?=.*?[a-z])/, "at least one letter is required"),
    password_confirmation: Yup.string()
      .required("This Field is Required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);
  const [userpermissions, setPermissions] = React.useState([]);
  var user = Helper.getStorageData("SESSION");
  var user_permissions = user.permissions;
  var createApiurl;
  var createApiMethod;
  if (user.type === "whitelabel") {
    createApiurl = AddWhiteLableOperator.method;
    createApiMethod = AddWhiteLableOperator.url;
  } else {
    createApiurl = AddOperator.method;
    createApiMethod = AddOperator.url;
  }
  const handleSubmit = (values) => {
    // const form_data = Helper.formData(values);
    const form_data = new FormData();
    form_data.append("name", values.name);
    form_data.append("email", values.email);
    form_data.append("contact_no", values.contact_no);
    form_data.append("password", values.password);
    form_data.append("password_confirmation", values.password_confirmation);
    form_data.append("image", values.image);
    form_data.append("type", 2);
    for (let index = 0; index < values.permissions.length; index++) {
      form_data.append("permissions[]", values.permissions[index]);
    }

    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(createApiurl, createApiMethod, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  React.useEffect(() => {
    if (user.type === "whitelabel") {
      apiRequest(WhiteLablePermissions.method, WhiteLablePermissions.url)
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data.data;
            setPermissions(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      apiRequest(UserPermissions.method, UserPermissions.url)
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data.data;
            setPermissions(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user.type]);

  if (redirectToReferrer) {
    if (user.type === "whitelabel") {
      return <Redirect to={"/admin/whitelabel-admin-user-list/1"} />;
    } else {
      return <Redirect to={"/admin/admin-user-list/1"} />;
    }
  }
  function formatString(inputString) {
    // Split the string by underscores
    const words = inputString.split("_");

    // Capitalize the first letter of each word
    const formattedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the formatted words with spaces
    const formattedString = formattedWords.join(" ");

    return formattedString;
  }

  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        {Helper.getPermissions(
          "admin-create",
          user_permissions && user_permissions,
          "admin"
        ) ||
        Helper.getPermissions(
          "whitelabel_admin-create",
          user_permissions && user_permissions,
          user.type
        ) ||
        Helper.getPermissions("", [], user.type) ? (
          <div className="p-4">
            <h4 className="mb-3">Add Admin</h4>
            <Formik
              validateOnChange={false}
              validationSchema={DetailSchema}
              onSubmit={async (values) => {
                // console.log("permissions: ", values);
                handleSubmit(values);
              }}
              initialValues={{
                name: "",
                email: "",
                contact_no: "",
                password: "",
                password_confirmation: "",
                image: undefined,
                permissions: [],
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form className="mt-2 user">
                  <FlashMessage
                    success={LocalState.success}
                    isSuccess={LocalState.isSuccess}
                    isError={LocalState.isError}
                    errors={LocalState.errors}
                  />
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Full name <span style={{ color: "red" }}>*</span>{" "}
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={values.name || ""}
                        onChange={handleChange}
                        placeholder="Your Name"
                        className={`form-control form-control-user ${
                          errors.name ? "error" : ""
                        }`}
                      />
                      {errors.name && (
                        <div className="ft-14 mt-1 red">{errors.name}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="email"
                        value={values.email || ""}
                        onChange={handleChange}
                        placeholder="Your Email"
                        autoComplete="new-password"
                        className={`form-control form-control-user ${
                          errors.email ? "error" : ""
                        }`}
                      />
                      {errors.email && (
                        <div className="ft-14 mt-1 red">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="password"
                        name="password"
                        value={values.password || ""}
                        onChange={handleChange}
                        autoComplete="new-password"
                        placeholder="**********"
                        className={`form-control form-control-user ${
                          errors.password ? "error" : ""
                        }`}
                      />
                      {errors.password && touched.password ? (
                        <div className="ft-14 mt-1 red">{errors.password}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Confirm password <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="password"
                        name="password_confirmation"
                        value={values.password_confirmation || ""}
                        onChange={handleChange}
                        placeholder="*********"
                        className={`form-control form-control-user ${
                          errors.password_confirmation ? "error" : ""
                        }`}
                      />
                      {errors.password_confirmation && (
                        <div className="ft-14 mt-1 red">
                          {errors.password_confirmation}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Phone number <span style={{ color: "red" }}>*</span>
                      </label>
                      <PhoneInput
                        country={"ee"}
                        // enableSearch={true}
                        name="contact_no"
                        onChange={(e, c) => {
                          values.contact_no = "+" + e;
                        }}
                        value={values.contact_no || ""}
                        inputProps={{
                          className: `w-100 pl-5 rounded-left form-control form-control-user ${
                            errors.contact_no ? "error" : ""
                          }`,
                        }}
                      />
                      {errors.contact_no && (
                        <div className="ft-14 mt-1 red">
                          {errors.contact_no}
                        </div>
                      )}
                    </div>
                    {/* <div className="form-group col-md-6">
                      <label className="form-label" htmlFor="avatar">
                        Profile picture
                      </label>
                      <input
                        id="image"
                        type="file"
                        name="image"
                        onChange={(event) => {
                          setFieldValue("image", event.currentTarget.files[0]);
                        }}
                        className={`form-control form-select-user  ${
                          errors.image ? "error" : ""
                        }`}
                      />
                      {errors.image && (
                        <div className="ft-14 mt-1 red">{errors.image}</div>
                      )}
                    </div> */}
                  </div>
                  {/* testing */}
                  <div className="form-row">
                    {Object.keys(userpermissions).map(
                      (permissionGroup, index) => (
                        <div className="form-group col-md-2">
                          <label className="form-label">
                            {formatString(permissionGroup)} Permissions
                          </label>
                          <FieldArray
                            name="permissions"
                            render={(arrayHelpers) => (
                              <div>
                                {userpermissions[permissionGroup] &&
                                  userpermissions[permissionGroup].map(
                                    (permission) => (
                                      <div key={permission.id}>
                                        <label
                                          htmlFor={`permissions-${permission.id}`}
                                        >
                                          <input
                                            name="permissions"
                                            type="checkbox"
                                            id={`permissions-${permission.id}`}
                                            value={permission.id}
                                            checked={values.permissions.includes(
                                              permission.id
                                            )}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                if (
                                                  permission.name !== "read"
                                                ) {
                                                  arrayHelpers.push(
                                                    userpermissions[
                                                      permissionGroup
                                                    ].find(
                                                      (p) => p.name === "read"
                                                    ).id
                                                  );
                                                }
                                                arrayHelpers.push(
                                                  permission.id
                                                );
                                              } else {
                                                const idx =
                                                  values.permissions.indexOf(
                                                    permission.id
                                                  );
                                                arrayHelpers.remove(idx);
                                              }
                                            }}
                                          />{" "}
                                          {permission.name}
                                        </label>
                                      </div>
                                    )
                                  )}
                              </div>
                            )}
                          />
                          {errors.permissions && (
                            <div className="ft-14 mt-1 red">
                              {errors.permissions}
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <div className="form-row">
                    <div className="form-group d-flex justify-content-end col-md-12">
                    <Button
                      type={"submit"}
                      localState={LocalState}
                      title={'Save'}
                      size={"btn-sm"}
                    />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <div className="p-4">
            <div className="text-center ft-14 mt-3 font-weight-bold">
              You are not allowed to visit this screen...
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default AddAdminUsersDetail;
