import { React } from "react";
import Helper from "../components/Helper";

const Button = ({ type, localState, title, size, onClick }) => {
    let user = Helper.getStorageData("SESSION");
    return (
        <button
            type={type}
            className={`text-white  btn btn-user rounded px-3 py-2 ${size}`}
            disabled={localState.disable}
            style={{ backgroundColor: user.background_color, borderColor: user.background_color }}
            onClick={onClick}
        >
            {title}
            {localState.disable ? (
                <span
                    className="ml-2 spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
            ) : (
                ""
            )}
        </button>
    )
}
export default Button