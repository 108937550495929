import React, { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Header from "../../Header/Index.js";
import { Formik, Form } from "formik";
import FlashMessage from "../../FlashMessage/FlashMessage.js";
import EditorConvertToHTML from "../Editor.js";

const Helper = require("../../Helper.js");

var { apiRequest } = require("../../Api/Service.js");
var {
  UserPermissions,
  PrivacyPolicyTemplate,
  UpdatePrivacyPolicyTemplate,
  whitelabelListEndpoint,
} = require("../../Api/ApiRoutes.js");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function EditPrivacyPolicy(props) {
  let _labelId = props.match.params.label_id;
  let _locale = props.match.params.locale;
  let user = Helper.getStorageData("SESSION");

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [getPrivacyPolicytemplate, setGetPrivacyPolicytemplate] =
    useState(false);
  const [privacyPolicytemplate, setPrivacyPolicytemplate] = useState([]);
  const [whitelabelList, setWhitelabelList] = useState([]);
  const [labelId, setLabelId] = useState(_labelId);
  const [locale, setLocale] = useState(_locale);

  const [showPrivacyTemplate, setShowPrivacyTemplate] = useState(false);

  const handlePrivacyTerms = useCallback(async () => {
    Helper.overlay(true);
    try {
      const _getPrivacyPolicyTemplate = await apiRequest(
        PrivacyPolicyTemplate.method,
        PrivacyPolicyTemplate.url + `${labelId}?locale=${locale}`
      );

      if (_getPrivacyPolicyTemplate.status === 200) {
        setPrivacyPolicytemplate(_getPrivacyPolicyTemplate);
        setShowPrivacyTemplate(true);
      }
      Helper.overlay(false);
    } catch (error) {
      console.log(error);
      Helper.overlay(false);
    }
  }, [labelId, locale]);

  const handleWhitelabelList = useCallback(() => {
    Helper.overlay(true);
    apiRequest(
      whitelabelListEndpoint.method,
      whitelabelListEndpoint.url + `per_page=-1`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setWhitelabelList(response.data.data);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    Helper.overlay(false);
  }, []);
  // useEffect(() => {
  // }, [handleWhitelabelList]);
  useEffect(() => {
    handleWhitelabelList();
    handlePrivacyTerms();
  }, [handlePrivacyTerms, handleWhitelabelList]);

  const handleSubmit = async (values) => {
    const privacyPolicyFormData = new FormData();

    if (user.type === "whitelabel") {
      privacyPolicyFormData.append("label_id", user.id);
    } else {
      privacyPolicyFormData.append("label_id", labelId);
    }

    privacyPolicyFormData.append("locale", locale);

    if (getPrivacyPolicytemplate !== false) {
      privacyPolicyFormData.append("content", getPrivacyPolicytemplate);
    } else {
      privacyPolicyFormData.append("content", privacyPolicytemplate.data);
    }

    try {
      Localdispatch({ type: "DEFAULT", payload: true });
      const updatePrivacyPolicyTemplateResponse = await apiRequest(
        UpdatePrivacyPolicyTemplate.method,
        UpdatePrivacyPolicyTemplate.url,
        privacyPolicyFormData
      );

      if (updatePrivacyPolicyTemplateResponse.data.code === 200) {
        Localdispatch({
          type: "FETCH_SUCCESS",
          payload: "success",
        });
      }
      setredirectToReferrer(true);
    } catch (error) {
      Localdispatch({
        type: "FETCH_ERROR",
        payload: error.response.data.data,
      });
      window.scrollTo(500, 0);
    }
  };

  React.useEffect(() => {
    apiRequest(UserPermissions.method, UserPermissions.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setPermissions(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setPermissions]);

  const handlePrivacyPolicyTemplate = (template) => {
    setGetPrivacyPolicytemplate(template);
  };

  const handleWhitelabelChange = (event) => {
    let id = event.target.value;
    setLabelId(id);
  };

  if (redirectToReferrer) {
    return (
      <Redirect
        to={{
          pathname: `/admin/privacy-policy/1`,
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />

        <div className="p-4">
          <h4 className="mb-3">Edit Privacy Policy</h4>
          <Formik
            validateOnChange={false}
            onSubmit={async (values) => {
              handleSubmit(values);
            }}
            initialValues={{
              label_id: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="mt-2 user">
                <FlashMessage
                  success={LocalState.success}
                  isSuccess={LocalState.isSuccess}
                  isError={LocalState.isError}
                  errors={LocalState.errors}
                />

                <div className="form-row">
                  {user.type !== "whitelabel" && (
                    <>
                      <div className="form-group col-md-6">
                        <label className="form-label">WhiteLabel</label>
                        <select
                          name="label_id"
                          onChange={(e) => {
                            values.vehicle_id = "";
                            handleWhitelabelChange(e);
                            handleChange(e);
                          }}
                          value={labelId}
                          className={`form-control hideArrow form-select-user dropdown-height ${
                            errors.label_id ? "error" : ""
                          }`}
                        >
                          {" "}
                          <option value="">Select WhiteLabel</option>
                          {whitelabelList &&
                            whitelabelList.map((record, index) => (
                              <option value={record.id} key={index}>
                                {record.name}
                              </option>
                            ))}
                        </select><div className="custom-arrow"></div>
                        {errors.label_id && (
                          <div className="ft-14 mt-1 red">
                            {errors.label_id}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-6">
                        <label className="form-label">Language</label>
                        <select
                          name="lang"
                          defaultValue={locale}
                          onChange={(e) => {
                            setLocale(e.target.value);
                            handleChange(e);
                          }}
                          required
                          className={`form-control hideArrow form-select-user dropdown-height ${
                            errors.label_id ? "error" : ""
                          }`}
                        >
                          {" "}
                          <option value="">Select Language</option>
                          <option value="en">English</option>
                          <option value="et">Estonian</option>
                        </select><div className="custom-arrow"></div>
                        {errors.lang && (
                          <div className="ft-14 mt-1 red">{errors.lang}</div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div>
                  {showPrivacyTemplate  && (
                    <h3>Privacy Policy</h3>
                  )}
                  {showPrivacyTemplate && (
                    <EditorConvertToHTML
                      tem={privacyPolicytemplate}
                      selTemplate={handlePrivacyPolicyTemplate}
                    />
                  )}
                </div>
                {showPrivacyTemplate && (
                  <div className="form-row mt-2">
                    <div className="form-group d-flex justify-content-end col-md-12">
                      <button
                        type="submit"
                        className="btn-submit"
                        style={{ backgroundColor: user.background_color }}
                        disabled={LocalState.disable}
                      >
                        confirm Details
                        {LocalState.disable ? (
                          <span
                            className="ml-2 spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditPrivacyPolicy;
