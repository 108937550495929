import React, { useEffect } from "react";
import { useState } from "react";
import Header from "../Header/Index.js";
import { Redirect } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import { Form } from "react-bootstrap";
import StationMap from '../Stations/Map.js';
import Button from "../../commonComponents/Button.js";
var { apiRequest } = require("../Api/Service");
var {
  AddVehicleAPI,
  VehicleMakeList,
  VehicleModelList,
  KeyModelList,
  CurrentUserAPI
} = require("../Api/ApiRoutes");
const Helper = require("../Helper");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const validateImageType = (value) => {
  if (value) {
    return SUPPORTED_FORMATS.includes(value.type);
  }
};
const validateImageSize = (value) => {
  if (value) {
    //console.log(value.size);
    let sizeInKb = value.size / 1024;
    let sizeInMB = sizeInKb / 1024;

    if (sizeInMB <= 10) {
      return true;
    }
  }
};
function AddVehicle() {
  const VehicleSchema = Yup.object().shape({
    device_code: Yup.string().required("This Field is Required"),
    name: Yup.string().required("This Field is Required"),
    year: Yup.string()
      .matches(/^\d{4}$/, "Car make year must be exactly 4 digits")
      .required("This Field is Required"),
    vehicle_model_id: Yup.string().required("This Field is Required"),
    vehicle_company_id: Yup.string().required("This Field is Required"),
    key_id: Yup.string().required("This Field is Required"),
    license: Yup.string().required("This Field is Required"),
    color: Yup.string().required("This Field is Required"),
    tags: Yup.string().required("This Field is Required"),
    door: Yup.string().required("This Field is Required"),
    door_button: Yup.string().required("This Field is Required"),
    key_holder: Yup.string().required("This Field is Required"),
    ignition: Yup.string().required("This Field is Required"),
    file: Yup.mixed()
      .required("This Field is Required")
      .test("fileType", "Unsupported File Format", (value) =>
        validateImageType(value)
      )
      .test("fileSize", "Max 10MB size", (value) => validateImageSize(value)), //.required("This Field is Required"),
    trunk_button: Yup.string().required("This Field is Required"),
    fuel_tank_capacity: Yup.string().required("This Field is Required"),
    fuel_tank_price: Yup.string().required("This Field is Required"),
    acriss_code: Yup.string().matches(/^[A-Z]{4}$/, "Code must be in specific required pattren").required("This Field is Required"),
  });

  const [makeList, setmakeList] = React.useState([]);
  const [modelList, setmodelList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [keyList, setkeyList] = React.useState([]);
  var user = Helper.getStorageData("SESSION");


  const handleFieldBlur = (value) => {
    let error;
    if (!value) {
      error = `This Field is Required`;
    } else {
      error = undefined;
    }
    return error;
  };
  React.useEffect(() => {
    apiRequest(VehicleMakeList.method, VehicleMakeList.url)
      .then((response) => {
        if (response.data.code === 200) {
          setmakeList(response.data.data);
        } else {
          //console.log("Failure", response);
        }
      })
      .catch((error) => {
        ////console.log(error);
      });
  }, []);

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);

  // eslint-disable-next-line no-unused-vars
  const [geofence, setGeofence] = React.useState({});
  const [getCords, setCords] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [getSeachLocation, setSeachLocation] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [showMap, setShowMap] = React.useState(false);

  const validateYear = (value) => {
    let error;
    const regex = /^[0-9]{4}$/;
    if (!value) {
      error = "Required";
    } else if (!regex.test(value)) {
      error = "Invalid (should be a 4-digit number)";
    } else {
      error = undefined;
    }
    return error;
  };
  const handleCar = (event) => {
    let id = event.target.value;

    apiRequest(
      VehicleModelList.method,
      VehicleModelList.url + `vehicle_company_id=${id}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setmodelList(response.data.data);
        } else {
          //console.log("Failure", response);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const handleModel = (event) => {
    let id = event.target.value;
    apiRequest(KeyModelList.method, KeyModelList.url + `vehicle_model_id=${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          setkeyList(response.data.data);
        } else {
          //console.log("Failure", response);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const handleSubmit = (values) => {
    const form_data = Helper.formData(values);
    //console.log(values);
    form_data.append("geofence", [JSON.stringify(getCords)]);
    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(AddVehicleAPI.method, AddVehicleAPI.url, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        //console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  const coordinateds = (cords) => {
    setCords(cords);
    console.log(cords);
  }
  // const searchLocation = (value) => {
  //   if (value !== '') {
  //     setShowMap(false)
  //     setSeachLocation(value);
  //   }
  // };

  const handleAcrissCodeFieldBlur = (value) => {
    let error;
    const smallCharRegex = /[a-z]/;
    const numberRegex = /[0-9]/;
    if (value.length > 4) {
      error = "Input must be 4 characters long"
    } else if (smallCharRegex.test(value)) {
      error = "All characters must be in uppercase"
    } else if (numberRegex.test(value)) {
      error = "Only alphabets allowed"
    } else error = undefined
    return error
  }

  useEffect(() => {
    apiRequest(
      CurrentUserAPI.method,
      CurrentUserAPI.url + `?show_all:1`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setUserList(response.data.data);
        } else {
          //console.log("Failure", response);
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }, [])

  if (redirectToReferrer) {
    return <Redirect to={"/admin/vehicle-list/1"} />;
  }

  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        {/* {Helper.getPermissions(
            "vehicle-create",
            permissions && permissions,
            "admin"
          ) || Helper.getPermissions("", [], user.type) ? ( */}
        <div className="p-4">
          <div className="text-left">
            <h1 className="h4 text-gray-900 mb-4">Create Vehicle</h1>
          </div>
          <Formik
            validateOnChange={false}
            validationSchema={VehicleSchema}
            onSubmit={(values) => {
              // same shape as initial values
              handleSubmit(values);
            }}
            initialValues={{
              device_code: "",
              name: "",
              vehicle_model_id: "",
              vehicle_company_id: "",
              year: "",
              key_id: "",
              user_id: "",
              license: "",
              color: "",
              tags: "",
              door: "",
              door_button: "",
              key_holder: "",
              ignition: "",
              file: undefined,
              trunk_button: "",
              acriss_code: "",
              fuel_tank_price: "",
              fuel_tank_capacity: ""
            }}
          >
            {({
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="mt-2 user" onSubmit={handleSubmit}>
                <FlashMessage
                  success={LocalState.success}
                  isSuccess={LocalState.isSuccess}
                  isError={LocalState.isError}
                  errors={LocalState.errors}
                />
                {/* <div className="p-2">
                      <NavLink
                        exact
                        to={`/admin/add-company-name`}
                        className="btn white bg-orange"
                      >
                        Add Company Vehicle
                      </NavLink>
                    </div> */}
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Device code</label>
                    <input
                      type="text"
                      name="device_code"
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.device_code = error;
                      }}
                      placeholder="Enter Device Code"
                      className={`font-weight-bold form-control form-control-user ${errors.device_code ? "error" : ""
                        }`}
                    />
                    {errors.device_code && (
                      <div className="ft-14 mt-1 red">{errors.device_code}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Vehicle Make</label>
                    <select
                      className={`form-control hideArrow form-select-user ${errors.vehicle_company_id ? "error" : ""
                        }`}
                      name="vehicle_company_id"
                      defaultValue={values.name}
                      onChange={(e) => {
                        setkeyList([]);
                        setmodelList([]);
                        values.vehicle_model_id = "";
                        values.key_id = "";
                        handleCar(e);
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.vehicle_company_id = error;
                      }}
                    >
                      <option value="" selected="selected">
                        Select Vehicle Make
                      </option>
                      {makeList &&
                        makeList.map((carMake, index) => (
                          <option value={carMake.id} key={index}>
                            {carMake.name}
                          </option>
                        ))}
                    </select>

                    <div className="custom-arrow" ></div>

                    {errors.vehicle_company_id && (
                      <div className="ft-14 mt-1 red">
                        {errors.vehicle_company_id}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Vehicle Model</label>
                    <select
                      className={`form-control hideArrow form-select-user ${errors.vehicle_model_id ? "error" : ""
                        }`}
                      name="vehicle_model_id"
                      onChange={(e) => {
                        setkeyList([]);
                        values.key_id = "";
                        handleModel(e);
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.vehicle_model_id = error;
                      }}
                    >
                      <option value="" selected="selected">
                        Select Vehicle Model
                      </option>
                      {modelList &&
                        modelList.map((modelName, index) => (
                          <option value={modelName.id} key={index}>
                            {modelName.name}
                          </option>
                        ))}
                    </select>
                    <div className="custom-arrow"></div>
                    {errors.vehicle_model_id && (
                      <div className="ft-14 mt-1 red">
                        {errors.vehicle_model_id}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Key Model</label>
                    <select
                      className={`form-control hideArrow form-select-user ${errors.key_id ? "error" : ""
                        }`}
                      name="key_id"
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.key_id = error;
                      }}
                    >
                      <option value="" selected="selected">
                        Select Key Model
                      </option>
                      {keyList &&
                        keyList.map((keyName, index) => (
                          <option value={keyName.id} key={index}>
                            {keyName.name}
                          </option>
                        ))}
                    </select>
                    <div className="custom-arrow"></div>
                    {errors.key_id && (
                      <div className="ft-14 mt-1 red">{errors.key_id}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Vehicle Make Year</label>
                    <input
                      type="text"
                      name="year"
                      value={values.year || ""}
                      onChange={(e) => {
                        handleChange(e);
                        const error = validateYear(e.target.value);
                        errors.year = error;
                      }}
                      placeholder="Vehicle Make Year"
                      className={`font-weight-bold form-control form-control-user ${errors.year ? "error" : ""
                        }`}
                    />

                    {errors.year && (
                      <div name="year" className="ft-14 mt-1 red">
                        {errors.year}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Licence plate</label>
                    <input
                      type="text"
                      name="license"
                      value={values.license || ""}
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.license = error;
                      }}
                      placeholder="Licence Plate"
                      className={`form-control font-weight-bold form-control-user ${errors.license ? "error" : ""
                        }`}
                    />
                    {errors.license && (
                      <div className="ft-14 mt-1 red">{errors.license}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Vehicle Name</label>
                    <input
                      type="text"
                      name="name"
                      value={values.name || ""}
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.name = error;
                      }}
                      placeholder="Vehicle Name"
                      className={`form-control font-weight-bold form-control-user ${errors.name ? "error" : ""
                        }`}
                    />
                    {errors.name && (
                      <div className="ft-14 mt-1 red">{errors.name}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Vehicle Image</label>
                    <input
                      id="file"
                      type="file"
                      name="file"
                      accept="image/jpeg, image/jpg, image/png, image/gif, image/svg"
                      onChange={(event) => {
                        const error = handleFieldBlur(event.target.value);
                        errors.file = error;
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                      className={`font-weight-bold form-control form-select-user  ${errors.file ? "error" : ""
                        }`}
                    />
                    {errors.file && (
                      <div className="ft-14 mt-1 red">{errors.file}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Vehicle Colour</label>
                    <input
                      type="text"
                      name="color"
                      value={values.color || ""}
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.color = error;
                      }}
                      placeholder="Vehicle Colour"
                      className={`form-control font-weight-bold form-control-user ${errors.color ? "error" : ""
                        }`}
                    />
                    {errors.color && (
                      <div className="ft-14 mt-1 red">{errors.color}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Tags</label>
                    <input
                      type="text"
                      name="tags"
                      value={values.tags || ""}
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.tags = error;
                      }}
                      placeholder="Sportscar, Eco, Supercar, Electric, Hybrid, Hydrogen"
                      className={`form-control font-weight-bold form-control-user ${errors.tags ? "error" : ""
                        }`}
                    />
                    {errors.tags && (
                      <div className="ft-14 mt-1 red">{errors.tags}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Doors</label>
                    <select
                      className={`form-control hideArrow form-select-user ${errors.door ? "error" : ""
                        }`}
                      name="door"
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.door = error;
                      }}
                    >
                      <option value="">Select Doors</option>
                      <option value="2">2</option>
                      <option value="4">4</option>
                    </select>
                    <div className="custom-arrow"></div>
                    {errors.door && (
                      <div className="ft-14 mt-1 red">{errors.door}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Open trunk button</label>
                    <select
                      className={` form-control hideArrow form-select-user${errors.trunk_button ? "error" : ""
                        }`}
                      name="trunk_button"
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.trunk_button = error;
                      }}
                    >
                      <option value="">Select Open trunk button</option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                    <div className="custom-arrow"></div>
                    {errors.trunk_button && (
                      <div className="ft-14 mt-1 red">
                        {errors.trunk_button}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Ignition</label>
                    <select
                      className={` form-control hideArrow form-select-user ${errors.ignition ? "error" : ""
                        }`}
                      name="ignition"
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.ignition = error;
                      }}
                    >
                      <option value="">Select Ignition</option>
                      <option value={1}>Push-button</option>
                      <option value={0}>Key-ignition</option>
                    </select>
                    <div className="custom-arrow"></div>
                    {errors.ignition && (
                      <div className="ft-14 mt-1 red">{errors.ignition}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Open Door button</label>
                    <select
                      className={`form-control hideArrow  form-select-user ${errors.door_button ? "error" : ""
                        }`}
                      name="door_button"
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.door_button = error;
                      }}
                    >
                      <option value="">Select Open Door button</option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                    <div className="custom-arrow"></div>
                    {errors.door_button && (
                      <div className="ft-14 mt-1 red">{errors.door_button}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Key Holder</label>
                    <select
                      className={`form-control hideArrow form-select-user ${errors.key_holder ? "error" : ""
                        }`}
                      name="key_holder"
                      value={values.key_holder}
                      onChange={handleChange}
                    >
                      <option value="">Select Key Holder</option>
                      <option value={1}>Yes</option>
                      <option value={0}>No</option>
                    </select>
                    <div className="custom-arrow"></div>
                    {errors.key_holder && (
                      <div className="ft-14 mt-1 red">
                        {errors.key_holder}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Car group (ACRISS code)</label>
                    <input
                      type="text"
                      name="acriss_code"
                      defaultValue={values.acriss_code}
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleAcrissCodeFieldBlur(e.target.value);
                        errors.acriss_code = error;
                      }}
                      placeholder="ACRISS code"
                      className={`form-control form-select-user ${errors.acriss_code ? "error" : ""
                        }`}
                    />
                    {errors.acriss_code && (
                      <div className="ft-14 mt-1 red">
                        {errors.acriss_code}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Fuel tank size</label>
                    <div className="input-group">
                      <input
                        type="number"
                        step="0.01"
                        name="fuel_tank_capacity"
                        defaultValue={values.fuel_tank_capacity}
                        onChange={(e) => {
                          handleChange(e);
                          const error = handleFieldBlur(e.target.value);
                          errors.fuel_tank_capacity = error;
                        }}
                        onInput={(e) => {
                          if (e.target.value < 0) {
                            e.target.value = 0;
                          }
                        }}
                        placeholder="Fuel tank size"
                        className={`form-control form-select-user ${errors.fuel_tank_capacity ? "error" : ""
                          }`}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text form-select-user"
                          id="basic-addon2"
                          style={{ padding: "11px" }}
                        >
                          Liters
                        </span>
                      </div>
                    </div>
                    {errors.fuel_tank_capacity && (
                      <div className="ft-14 mt-1 red">
                        {errors.fuel_tank_capacity}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Full tank price</label>
                    <div className="input-group">
                      <input
                        type="number"
                        name="fuel_tank_price"
                        defaultValue={values.fuel_tank_price}
                        onChange={(e) => {
                          handleChange(e);
                          const error = handleFieldBlur(e.target.value);
                          errors.fuel_tank_price = error;
                        }}
                        onInput={(e) => {
                          if (e.target.value < 0) {
                            e.target.value = 0;
                          }
                        }}
                        placeholder="Full tank price"
                        className={`form-control form-select-user ${errors.fuel_tank_price ? "error" : ""
                          }`}
                      />
                      <div className="input-group-append">
                        <span
                          className="input-group-text form-select-user"
                          style={{ padding: "11px" }}
                          id="basic-addon2"
                        >
                          Euros (€)
                        </span>
                      </div>
                    </div>
                    {errors.fuel_tank_price && (
                      <div className="ft-14 mt-1 red">
                        {errors.fuel_tank_price}
                      </div>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">

                    <label className="form-label">User</label>
                    <select
                      className={`form-control hideArrow form-select-user ${errors.user_id ? "error" : ""
                        }`}
                      name="user_id"
                      onChange={(e) => {
                        handleChange(e);
                        const error = handleFieldBlur(e.target.value);
                        errors.user_id = error;
                      }}
                    >
                      <option value="" selected="selected">
                        Select User
                      </option>
                      {userList &&
                        userList.map((user, index) => (
                          <option value={user.id} key={index}>
                            {user.name}
                          </option>
                        ))}
                    </select>
                    <div className="custom-arrow"></div>
                  </div>

                </div>
                {showMap && <StationMap search={getSeachLocation} gcords={coordinateds} sdata={geofence} s={showMap} />}
                <div className="form-row">
                  <div className="form-group d-flex justify-content-end col-md-12">
                    <Button
                      type={"submit"}
                      localState={LocalState}
                      title={'Save'}
                      size={"btn-sm"}
                    />
                  </div>
                </div>
                <style>
                  {`.form-control-user::placeholder {
            font-weight: normal;
          }`}
                </style>
              </Form>
            )}
          </Formik>
        </div>
        {/* // ) : (
          //   <div className="p-4">
          //     <div className="text-center ft-14 mt-3 font-weight-bold">
          //       You are not allowed to visit this screen...
          //     </div>
          //   </div>
          // )} */}
      </div>
      {/* </div> */}
    </React.Fragment >
  );
}

export default AddVehicle;
