import { useState, useCallback, useContext, useEffect } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Modal, Button, Icon } from "rsuite";
import Header from "../Header/Index.js";
import Helper from "../Helper";
import { Context } from "../Store/Store";
import { NavLink, useLocation } from "react-router-dom";
let {
  DeleteHelpAndSupport,
  GetHelpAndSupportSubCategoryList,
} = require("../Api/ApiRoutes");
let { apiRequest } = require("../Api/Service");

function HelpAndSupport() {
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [setResult] = useState({});
  const [dispatch] = useContext(Context);
  const [currentId, setCurrentId] = useState(null);
  const [rsModal, setRsModal] = useState(false);
  let location = useLocation();
  var user = Helper.getStorageData("SESSION");
  const getSubCategoryList = useCallback(() => {
    Helper.overlay(true);
    apiRequest(
      GetHelpAndSupportSubCategoryList.method,
      GetHelpAndSupportSubCategoryList.url
    )
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setSubCategoryList(data);
          dispatch({ type: "SET_SEARCH_STATE", search_list: false });
          setResult(response.data.meta);
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  }, [dispatch, setResult]);

  useEffect(() => {
    if (location.hash !== "#search") {
      getSubCategoryList();
    }
  }, [getSubCategoryList, location.hash]);

  const handleDelete = (category) => {
    apiRequest(
      DeleteHelpAndSupport.method,
      DeleteHelpAndSupport.url + `/${currentId}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          const data = subCategoryList.filter((obj) => {
            if (+obj.id === +currentId) {
              return false;
            } else {
              obj.subcategory = obj.subcategory.filter((subObj) => {
                return +subObj.id !== +currentId;
              });
              return true;
            }
          });
          setSubCategoryList(data);
          setRsModal(false);
          //console.log("Success", response);
        } else {
          //console.log("Failure", response);
        }
      })
      .catch((error) => {
        //console.log("error", error);
        setRsModal(false);
        alert(error.response.data.message);
      });
    //}
  };

  const Modalclose = () => {
    setRsModal(false);
  };
  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };

  return (
    <>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleDelete} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        <div>
          <div className="p-4">
            <div className="row">
              <div className="col-md-4">
                <div className="d-flex flex-row mb-3">
                  {Helper.getPermissions(
                    "help_and_support-create",
                    user?.permissions,
                    user.type
                  ) && (
                    <div className="p-2">
                      <NavLink
                        exact
                        to={`/admin/add-help-and-support-category`}
                        type="button"
                        className="btn white btnn-user bg-orange"
                        style={{ backgroundColor: user.background_color }}
                      >
                        Add New Category
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <ListGroup>
              {subCategoryList.map((category) => (
                <ListGroupItem key={category.setCurrentId}>
                  <div className="d-flex align-items-center justify-content-between p-2 fs-3">
                    {" "}
                    <div className="d-flex align-items-center">
                      <h4>{category.name} </h4>{" "}
                    </div>
                    <div className="d-flex align-items-center hover">
                      {Helper.getPermissions(
                        "help_and_support-create",
                        user?.permissions,
                        user.type
                      ) && (
                        <NavLink
                          className="pr-3"
                          exact
                          to={`/admin/add-help-and-support-Subcategory/${category?.name}/${category.id}`}
                        >
                          <button
                            className="pl-2 p-1 rounded white bg-orange"
                            style={{ backgroundColor: user.background_color }}
                          >
                            Add Subcategory
                            <i
                              className="fas fa-plus-circle pl-2"
                              style={{ fontSize: "12px", color: "white" }}
                              aria-hidden="true"
                            ></i>
                          </button>
                        </NavLink>
                      )}
                      {Helper.getPermissions(
                        "help_and_support-update",
                        user?.permissions,
                        user.type
                      ) && (
                        <NavLink
                          className="pr-3"
                          exact
                          to={`/admin/edit-help-and-support-category/${category.id}`}
                        >
                          <i
                            className="fas fa-pen"
                            style={{ fontSize: "20px" }}
                          ></i>
                        </NavLink>
                      )}
                      {Helper.getPermissions(
                        "help_and_support-delete",
                        user?.permissions,
                        user.type
                      ) && (
                        <i
                          data-id={category.id}
                          onClick={Modalopen}
                          className="fas fa-trash-alt hover"
                          style={{
                            fontSize: "20px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          aria-hidden="true"
                        ></i>
                      )}
                    </div>
                  </div>
                  <ListGroup>
                    {category?.subcategory?.map((subcategory) => (
                      <ListGroupItem key={subcategory?.id}>
                        <h4 style={{ textDecoration: "underline" }}>
                          {subcategory.name}{" "}
                        </h4>{" "}
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <h6>{subcategory?.title}</h6>
                          </div>
                          <div>
                            {/* <NavLink
                              className=""
                              exact
                              to={`/admin/edit-help-and-support-subcategory/${subcategory.id}`}
                            >
                              <button className="btn-info">Edit</button>
                            </NavLink> */}
                            {Helper.getPermissions(
                              "help_and_support-update",
                              user?.permissions,
                              user.type
                            ) && (
                              <NavLink
                                className="pr-3"
                                exact
                                to={`/admin/edit-help-and-support-subcategory/${subcategory.id}`}
                              >
                                <i
                                  className="fas fa-pen"
                                  style={{ fontSize: "15px" }}
                                ></i>
                              </NavLink>
                            )}
                            {Helper.getPermissions(
                              "help_and_support-delete",
                              user?.permissions,
                              user.type
                            ) && (
                              <i
                                data-id={subcategory.id}
                                onClick={Modalopen}
                                className="fas fa-trash-alt hover"
                                style={{
                                  fontSize: "15px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                aria-hidden="true"
                              ></i>
                            )}
                            {/* <button
                              className="btn-danger"
                              data-id={category.id}
                              onClick={Modalopen}
                            >
                              Delete
                            </button> */}
                          </div>
                        </div>
                        <p>{subcategory?.content}</p>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpAndSupport;
