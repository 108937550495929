/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from "react";
import { Context } from "../Store/Store";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../Header/Index.js";
import { Modal, Button, Icon } from "rsuite";
import Pagination from "react-js-pagination";
import { useHistory } from 'react-router-dom';
import Helper from "../Helper";
import CheckBox from "../../commonComponents/CheckBox.js";
var {
  AddWhitelabelListing,
  DeleteWhitelabelListing,
  AllDeleteWhitelabelListing,
  ListSearchApi,
} = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");

function WhiteLabelList() {
  const [records, setrecords] = React.useState([]);
  const [result, setResult] = React.useState({});
  const [rsModal, setRsModal] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(null);
  const [state, dispatch] = React.useContext(Context);
  const [locationKeys, setLocationKeys] = React.useState([])
  const [errorMsg, setErrorMsg] = React.useState("");
  const [sort_by, setSortBy] = React.useState(1);

  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;
  const history = useHistory()
  let location = useLocation()


  const Modalclose = () => {
    setRsModal(false);
  };
  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };

  const getWhiteLabelList = useCallback((page, sort) => {
    // if(Helper.getPermissions("user-read",permissions && permissions,"admin") || (user.type === 'superadmin')){
    Helper.overlay(true);
    apiRequest(AddWhitelabelListing.method, AddWhitelabelListing.url + `?page=${page}&sort_by=${sort ?? sort_by}`)
      .then((response) => {
        if (response.data.code === 200) {
          dispatch({ type: "SET_SEARCH_STATE", search_list: false });
          setResult(response.data.meta);
          setrecords(response.data.data);
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        setErrorMsg(error)
        Helper.overlay(false);
      });
    // }
  }, [dispatch, sort_by]);

  const handleSearchPageChange = useCallback((pageNumber) => {
    Helper.overlay(true);
    apiRequest(ListSearchApi.method, ListSearchApi.url + `whitelabel?name=${state.searchedName}&page=${pageNumber}`)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data;
          setrecords(data.data);
          setResult(data.meta);
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        history.push(`${location.pathname}#error`);
      });

  }, [history, location.pathname, state.searchedName])



  useEffect(() => {
    if (location.hash !== "#search" && !state.searchedName) {
      dispatch({ type: "SET_STATE", response: [] });
      getWhiteLabelList(state.set_active_page);
    }
    else {
      Helper.overlay(false);
    }
  }, [dispatch, location.hash, state.set_active_page]);

  useEffect(() => {
    if (location.pathname.includes('list-white-label') && location.hash === "#search" && state.searchedName) {
      dispatch({ type: "SET_STATE", response: [] });
      handleSearchPageChange(state.set_active_page)
    }
  }, [dispatch, location.hash, location.pathname, state.searchedName, state.set_active_page])


  useEffect(() => {
    if (location.hash === "#error") {
      setrecords([]);
      setResult([]);
      //setShow(true);
      setErrorMsg('No record Found');
    }
  }, [location.hash]);

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          let str = location.pathname
          let id = str.substr(str.length - 1)
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          getWhiteLabelList(+id);
          setLocationKeys(([_, ...keys]) => keys)
        } else {
          setLocationKeys((keys) => [location.key, ...keys])
          let str = location.pathname
          let id = str.substr(str.length - 1)
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          getWhiteLabelList(+id);
        }
      }
    })
  }, [dispatch, getWhiteLabelList, history, locationKeys])

  function getcheckbox() {
    var array = [];
    var checkboxes = document.querySelectorAll(
      "input[name='subcheckbox']:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
      checkboxes[i].checked = false;
    }

    let params = new FormData();
    params.append("ids", array);
    let delete_property = window.confirm(
      "Are you sure you want to delete these records!"
    );
    if (delete_property) {
      apiRequest(
        AllDeleteWhitelabelListing.method,
        AllDeleteWhitelabelListing.url,
        params
      )
        .then((response) => {
          if (response.data.code === 200) {
            let data = state.data;
            array.forEach((el) => {
              data = data.filter((d) => {
                // eslint-disable-next-line eqeqeq
                return d.id != el
              })
            })
            dispatch({ type: "SET_STATE", response: data });
            setRsModal(false);

          } else {
            console.log("Failure", response);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  function handleMainCheckBox(e) {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    Array.from(document.querySelectorAll("input[name='subcheckbox']")).forEach(
      (checkbox) => {
        checkbox.checked = value;
      }
    );
  }

  const deleteRecord = (e) => {
    //let params = new FormData();
    //params.append("id", e.target.dataset.id);
    // let delete_property = window.confirm(
    //   "Are you sure you want to delete this record!"
    // );
    // if (delete_property) {
    apiRequest(
      DeleteWhitelabelListing.method,
      DeleteWhitelabelListing.url + `${currentId}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          let data = records.filter((d) => {
            return d.id !== +currentId
          })
          setrecords(data)
          setRsModal(false);
          setResult((prev) => ({ ...prev, total: prev.total - 1 }));
        } else {
          console.log("Failure", response);
          setRsModal(false);

        }
      })
      .catch((error) => {
        setRsModal(false);
        alert(error.response.data.message);
      });
    //}
  };

  const handlePageChange = (pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    Helper.overlay(true);
    if (location.pathname.includes('list-white-label') && location.hash === "#search") {
      history.push(`/admin/list-white-label/${pageNumber}#search`);
    } else {
      history.push(`/admin/list-white-label/${pageNumber}`);
    }
  };

  const setDropDown = (e) => {
    setSortBy(e.target.value)
    getWhiteLabelList(state.set_active_page, e.target.value)
  }

  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header />
        {/* {((Helper.getPermissions('user-read',permissions && permissions ,'admin')) || (Helper.getPermissions('',[], user.type ))) ? */}
        <div className="p-4">
          <div className="row pb-2 d-flex justify-content-between align-items-center border-bottom">
            <div className="col-lg-8 col-md-6">
              <div className="d-flex flex-row mb-3">
                <div className="p-2 d-flex align-items-center">
                  <CheckBox onChange={handleMainCheckBox} />
                </div>
                <div className="p-2">
                  {(Helper.getPermissions(
                    "whitelabel-delete",
                    permissions && permissions,
                    "admin"
                  ) ||
                    Helper.getPermissions("", [], user.type)) && (
                      <button
                        className="btn btnn-user btn-primary"
                        onClick={getcheckbox}
                      >
                        Bulk Delete
                      </button>
                    )}
                </div>
                {
                  //Helper.getPermissions('usage-create',permissions) &&
                  <div className="p-2">
                    {Helper.getPermissions(
                      "whitelabel-create",
                      permissions && permissions,
                      user.type
                    ) && (
                        <NavLink
                          exact
                          to={`/admin/add-white-label`}
                          className="btn white btnn-user bg-orange" style={{ backgroundColor: user.background_color }}
                        >
                          New Company
                        </NavLink>
                      )}
                  </div>
                }
              </div>
            </div>

            <div className="col-lg-4 col-md-6  ">
              <div className="d-flex align-items-center row">
                <div className="col-md-12 col-lg-3 pl-lg-0 pr-lg-0">Results: {result.total ? result.total : '1'}</div>
                <div className="col-md-12 col-lg-9 pl-lg-0 pr-lg-0 row">
                  <label class="col-3 mt-auto">Sort By:</label>
                  <select className="form-select form-control col-8" aria-label="Default select example" name="sort_by" onChange={setDropDown}>
                    <option selected={sort_by === 1 ? true : false} value={1}>Last created on top</option>
                    <option selected={sort_by === 2 ? true : false} value={2}>First created on top</option>
                    <option selected={sort_by === 3 ? true : false} value={3}>Alphabetically A-Z</option>
                    <option selected={sort_by === 4 ? true : false} value={4}>Alphabetically Z-A</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {records.length ? (
              records.map((record, index) => (
                <div
                  className="card col-12 mt-3 shadow bg-white rounded"
                  key={index}
                >
                  <div className="row">
                    <div className="col-md-4 col-lg-2  col-6 pb-3 pt-2 my-auto">
                      <img
                        src={record.file}
                        className="card-img-top img-fluid"
                        alt="..."
                        width="100"
                        height="100"
                        style={{ borderRadius: "50% !important" }}
                      />
                    </div>
                    <div className="col-md-12 col-lg-8  col-sm-12 pb-3 pt-2">
                      <div className="row pt-2">
                        <div className="col-12">
                          <input
                            type="checkbox"
                            className=""
                            name="subcheckbox"
                            defaultValue={record.id}
                            id="exampleCheck1"
                          />
                          {Helper.getPermissions(
                            "whitelabel-update",
                            permissions && permissions,
                            "admin") ?
                            <NavLink
                              className="ml-3 form-check-label font-weight-bold"
                              exact
                              to={`/admin/list-white-label-edit/${record.id}`}
                            >{record.id}

                            </NavLink> : <span>{record.id}</span>}
                        </div>
                        {/* <div className="col-1 ">
                   
                          <ThreeDotsVertical  className="dropdown-toggle" data-toggle="dropdown" cursor ="pointer" size={20} />
                              <ul className="dropdown-menu">
                                  <li>
                                  {(Helper.getPermissions(
                                    "user-update",
                                    permissions && permissions,
                                    "admin"
                                  ) ||
                                    Helper.getPermissions("", [], user.type)) && (
                                    <NavLink
                                      className=""
                                      exact
                                      to={`/admin/list-white-label-edit/${record.id}`}
                                    >
                                      <button className="btn btn-block btnn-user btn-info">
                                        Edit
                                      </button>
                                    </NavLink>
                                  )}
                                  </li>
                                  <li>
                                    {(Helper.getPermissions(
                                        "admin-delete",
                                        permissions && permissions,
                                        "admin"
                                      ) ||
                                        Helper.getPermissions("", [], user.type)) && (
                                        <button
                                          data-id={record.id}
                                          onClick={Modalopen}
                                        >
                                          Delete
                                        </button>
                                      )}
                                  </li>
                              </ul>
                        </div> */}
                      </div>
                      <div className="row pt-2">
                        <div className="col-md-4 col-12">
                          Name: {" "}
                          <strong>{record.name}</strong>
                        </div>
                        <div className="col-md-4 col-12">
                          Color: {" "}
                          <strong>{record.color}</strong>
                        </div>
                        <div className="col-md-4 col-12">
                          Webhook URL: {" "}
                          <strong>{(record.webhook_url != null ? record.webhook_url : '-')}</strong>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-md-4 col-12">
                          TelePhone: {" "}
                          <strong>{record.contact_no}</strong>
                        </div>

                        <div className="col-md-4 col-12">
                          Email: {" "}
                          <strong>{record.email}</strong>
                        </div>
                        <div className="col-md-4 col-12">
                          Subdomain URL: {" "}
                          <strong>{(record.subdomain_url != null) ? record.subdomain_url : '-'}</strong>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <div className="col-md-4 col-12">
                          Logo color scheme: {" "}
                          <strong>{(record.logo_color_schema != null) ? record.logo_color_schema : '-'}</strong>
                        </div>

                        <div className="col-md-4 col-12">
                          Package ID: {" "}
                          <strong>{(record.package_id != null) ? record.package_id : '-'}</strong>
                        </div>
                        <div className="col-md-4 col-12">
                          Created At: {" "}
                          <strong>{Helper.getDateByTZ(record?.created_at)} (
                            {user?.timezone
                              ? user.timezone
                              : Helper.localTimezone()}
                            )</strong>
                        </div>

                      </div>
                      <div className="row pt-2">
                        <div className="col-md-6 col-12">
                          API Key: {" "}
                          <strong>{record.api_key}</strong>
                        </div>

                      </div>
                    </div>
                    <div className="col-md-12 col-sm-12  col-lg-2 my-auto">
                      <div className="row pt-3 pb-3">

                        <div className="col-md-6 col-sm-6 col-lg-12 pt-lg-3 pt-md-0 pt-2">
                          {(Helper.getPermissions(
                            "whitelabel-update",
                            permissions && permissions,
                            "admin"
                          ) ||
                            Helper.getPermissions("", [], user.type)) && (
                              <NavLink
                                className=""
                                exact
                                to={`/admin/list-white-label-edit/${record.id}`}
                              >
                                <button className="btn-success w-100">
                                  Edit
                                </button>
                              </NavLink>
                            )}
                        </div>
                        <div className="col-md-6  col-sm-6 col-lg-12 pt-lg-3 pt-md-0 pt-2">
                          {(Helper.getPermissions(
                            "whitelabel-delete",
                            permissions && permissions,
                            "admin"
                          ) ||
                            Helper.getPermissions("", [], user.type)) && (
                              <button
                                data-id={record.id}
                                onClick={Modalopen}
                                className="btn-danger w-100"
                              >
                                Delete
                              </button>
                            )}
                        </div>

                      </div>

                    </div>
                  </div>

                </div>
              ))
            ) : (
              errorMsg && (
                <div className="text-center ft-14 mt-3 font-weight-bold">
                  No Data Found...
                </div>
              )
            )}
          </div>
          <div className="row mt-3 ft-14">
            <div className="col-md-12">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <Pagination
                    activePage={state.set_active_page}
                    totalItemsCount={result.total ? result.total : 1}
                    pageRangeDisplayed={result.last_page}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={handlePageChange}
                    activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                  />
                  <li className="pl-3">
                    {/* <form className="form-inline">
                          <label className="my-1 mr-2">Results on page:</label>
                          <select
                            className="form-control p-0"
                            style={{ height: "30px" }}
                            defaultValue={"10"}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </form> */}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        {/* :
             <div className="p-4">
                <div className="text-center ft-14 mt-3 font-weight-bold">
                   You are not allowed to visit this screen...
                </div>
             </div>
             } */}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
export default WhiteLabelList;
