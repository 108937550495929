import React, { useEffect, useCallback } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import Header from "../Header/Index.js";
import Pagination from "react-js-pagination";
import Helper from "../Helper.js";
import { Context } from "../Store/Store.js";
import { useHistory } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import NotificationFilter from "../Filters/NotificationFilter.js";
var { getNotificationTemplates } = require("../Api/ApiRoutes.js");
var { apiRequest } = require("../Api/Service.js");

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                ...state,
                isSuccess: true,
                success: action.payload,
                disable: false,
            };
        case "FETCH_ERROR":
            return {
                ...state,
                isError: true,
                errors: action.payload,
                disable: false,
            };
        case "DEFAULT":
            return {
                disable: action.payload,
            };
        default:
            return state;
    }
};



const initialState = {
    isError: false,
    errors: [],
    isSuccess: false,
    success: "",
    disable: false,
};

function NotificationListing() {
    const [records, setrecords] = React.useState([]);
    const [result, setResult] = React.useState({});
    const [show, setShow] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [rsModal, setRsModal] = React.useState(false);
    const [hidePagination, sethidePagination] = React.useState(false);
    const [currentId, setCurrentId] = React.useState(null);
    const [state, dispatch] = React.useContext(Context);
    const history = useHistory();
    const [sort_by, setSortBy] = React.useState(1);

    const Modalclose = () => {
        setRsModal(false);
        setShow(false);
    };
    const Modalopen = (e) => {
        setCurrentId(e.target.dataset.id);
        setRsModal(true);
    };
    var user = Helper.getStorageData("SESSION");
    var permissions = user.permissions;
    var location = useLocation();

    const getEmailTemplateListing = useCallback(
        (page, sort) => {
            Helper.overlay(true);

            let notificationTempletUrl = getNotificationTemplates.url;
            if (user.type === "whitelabel") {
                notificationTempletUrl = notificationTempletUrl + '/' + user.label_id;
            }

            apiRequest(getNotificationTemplates.method, notificationTempletUrl)
                .then((response) => {
                    if (response.data) {
                        let data = response.data.data;
                        dispatch({ type: "SET_CALL_STATE", call_one: false });
                        console.log(data);
                        setrecords(data);
                        //dispatch({ type: "SET_SEARCH_STATE", search_list: false });
                        //sethidePagination(false);
                        //setResult(response.data.meta);
                        Helper.overlay(false);
                    }
                })
                .catch((error) => {
                    setErrorMsg(error);
                    Helper.overlay(false);
                });
            //}
        },
        [dispatch, permissions, sort_by, user.type]
    );

    useEffect(() => {
        if (location.hash !== "#search" && !state.searchedName) {
            dispatch({ type: "SET_STATE", response: [] });
            getEmailTemplateListing(state.set_active_page, sort_by);
        }
    }, [dispatch, location.hash, sort_by, state.searchedName, state.set_active_page]);


    useEffect(() => {

        if (location.hash === "#error") {
            setrecords([]);
            setResult([]);
            setErrorMsg('No record Found');
        }
    }, [dispatch, location, state.data, state.pagination]);


    const handlePageChange = (pageNumber) => {
        dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
        Helper.overlay(true);
        if (location.pathname.includes('user-list') && location.hash === "#search") {
            history.push(`/admin/user-list/${pageNumber}#search`);
        } else {
            history.push(`/admin/user-list/${pageNumber}`);
        }
    };

    const handelRecords = (data) => {
        setrecords(data);
    }


    return (
        <React.Fragment>
            <div id="content">
                <Header showSearch={false} showAdvanceSearch={false} />

                <div className="p-4">
                    <div className="row pb-2 d-flex justify-content-between align-items-center border-bottom">
                        <div className="col-md-6 col-8">
                            {Helper.getPermissions(
                                "app_notification-create",
                                permissions && permissions,
                                user.type
                            ) && (
                                    <NavLink
                                        exact
                                        to={`/admin/create-notification-template`}
                                        className="btn btnn-user white bg-orange" style={{ backgroundColor: user.background_color }}
                                    >
                                        Create Notification Template
                                    </NavLink>
                                )}
                        </div>
                        <div className="col-md-6 col-4 text-center">
                            <p>Results: {records.length ? records.length : '0'}</p>
                        </div>
                    </div>
                    {errorMsg && (" ")}
                    <div className="row">
                        {records && <NotificationFilter type='NOTIFICATION' search={handelRecords} />}
                    </div>
                    <div className="row">
                        {records.length ?
                            records.map((record, index) =>
                                <div className="card col-md-12 mt-3 shadow bg-white rounded" key={index}>

                                    <div className="row pt-3 pb-3">

                                        <div className="col-md-3 col-12">
                                            Template Name: <strong>{record.template_name}</strong>
                                        </div>


                                        <div className="col-md-3 col-12">
                                            Title:{" "}
                                            <span className="text-success">{record.title ? record.title : "-"}</span>
                                        </div>
                                        <div className="col-md-2 col-12">
                                            Whitelabel:{" "}
                                            <span className="text-success">{record.whitelabel?.name ? record.whitelabel.name : "-"}</span>
                                        </div>
                                        <div className="col-md-2 col-12">
                                            Language:{" "}
                                            <span className="text-success">{record.lang ? record.lang == 'en' ? 'EN' : 'ET' : '-'}</span>
                                            {" "} <ReactCountryFlag countryCode={record.lang == 'en' ? "GB" : 'EE'} svg />
                                        </div>
                                        <div className="col-md-2 col-12 pt-md-2">
                                            {Helper.getPermissions(
                                                "app_notification-update",
                                                permissions && permissions,
                                                user.type
                                            ) && (
                                                    <NavLink
                                                        exact
                                                        to={`/admin/edit-notification-template/${record.id}`}
                                                        className=""
                                                    >
                                                        <button className="btn-success w-100">
                                                            Edit Notification
                                                        </button>
                                                    </NavLink>
                                                )}
                                        </div>
                                    </div>

                                </div>

                            ) : (
                                <div className="text-center ft-14 mt-3 font-weight-bold w-100">
                                    No Data Found...
                                </div>
                            )}
                    </div>
                    <div className="row mt-3 ft-14">
                        <div className="col-md-12">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-end">
                                    {!hidePagination && records.length > 0 && (
                                        <Pagination
                                            activePage={state.set_active_page}
                                            // itemsCountPerPage={result.to}
                                            totalItemsCount={result.total ? result.total : 1}
                                            pageRangeDisplayed={result.last_page}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            onChange={handlePageChange}
                                            activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                                        />
                                    )}

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

            </div>
            {/* </div> */}
        </React.Fragment>
    );
}
export default NotificationListing;
