import React from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import { Context } from "../Store/Store";
import Helper from "../Helper.js";
import Button from "../../commonComponents/Button.js";
var { apiRequest } = require("../Api/Service");

var {
  UserPermissions,
  HardwareList,
  DeviceAddAPI,
} = require("../Api/ApiRoutes");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function AddDevicesCode() {

  const DetailSchema = Yup.object().shape({
    serial: Yup.string().required("This Field is Required"),
    device: Yup.mixed().required("This Field is Required"),
    manufacturing_date: Yup.mixed().required(),
    server_fallback_path: Yup.mixed().required("This Field is Required"),
    server_path: Yup.mixed().required("This Field is Required"),
    heartbeat_time: Yup.mixed().required("This Field is Required"),
  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);
  const [setPermissions] = React.useState([]);
  const [sethardwarelist, setHardwarelist] = React.useState([]);
  const [redirectId, setRedirectId] = React.useState(null);
  const [dispatch] = React.useContext(Context);
  var user = Helper.getStorageData("SESSION");

  const handleSubmit = (values) => {
    // const form_data = Helper.formData(values);
    const form_data = new FormData();
    form_data.append("serial", values.serial);
    form_data.append("device", values.device);
    form_data.append("manufacturing_date", Helper.convertToUTC(values.manufacturing_date));
    form_data.append("hardware_id", values.hardware_id);
    form_data.append("server_fallback_path", values.server_fallback_path);
    form_data.append("server_path", values.server_path);
    form_data.append("heartbeat_time", values.heartbeat_time);

    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(DeviceAddAPI.method, DeviceAddAPI.url, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message
          });
          setRedirectId(response.data.data.id)
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  React.useEffect(() => {
    apiRequest(UserPermissions.method, UserPermissions.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setPermissions(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setPermissions]);

  React.useEffect(() => {
    apiRequest(HardwareList.method, HardwareList.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setHardwarelist(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (redirectToReferrer) {
    // Localdispatch({
    //         type:"SET_CALL_STATE",call_one: true
    //  });
    return <Redirect to={{
      pathname: `/admin/devices-code-list/1`,
    }} />;
  }

  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        {/* {Helper.getPermissions(
            "admin-create",
            user_permissions && user_permissions,
            "admin"
          ) || Helper.getPermissions("", [], user.type) ? ( */}
        <div className="p-4">
          <h4 className="mb-3">Add Device Code</h4>
          <Formik
            validateOnChange={false}
            validationSchema={DetailSchema}
            onSubmit={async (values) => {
              // console.log("permissions: ", values);
              handleSubmit(values);
            }}
            initialValues={{
              serial: "",
              device: "",
              manufacturing_date: "",
              hardwarelist: [],
              hardware_id: "",
              server_fallback_path: "",
              server_path: "",
              heartbeat_time: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form className="mt-2 user">
                <FlashMessage
                  success={LocalState.success}
                  isSuccess={LocalState.isSuccess}
                  isError={LocalState.isError}
                  errors={LocalState.errors}
                />
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Device Code#</label>
                    <input
                      type="text"
                      name="serial"
                      value={values.serial || ""}
                      onChange={handleChange}
                      placeholder="Device Code"
                      className={`form-control form-control-user ${errors.serial ? "error" : ""
                        }`}
                    />
                    {errors.serial && (
                      <div className="ft-14 mt-1 red">{errors.serial}</div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Device/Product</label>
                    <input
                      type="text"
                      name="device"
                      value={values.device || ""}
                      onChange={handleChange}
                      placeholder="Device Product"
                      className={`form-control form-control-user ${errors.device ? "error" : ""
                        }`}
                    />
                    {errors.device && (
                      <div className="ft-14 mt-1 red">{errors.device}</div>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Hardware Version List</label>

                    <select
                      className={`form-control hideArrow form-select-user ${errors.id ? "error" : ""
                        }`}
                      name="hardware_id"
                      defaultValue={values.version}
                      onChange={(e) => {
                        //   setkeyList([]);
                        //   handleCar(e);
                        handleChange(e);
                      }}
                    >
                      <option value="" selected="selected">
                        Select Hardware Version
                      </option>
                      {sethardwarelist &&
                        sethardwarelist.map((HardwareList, index) => (
                          <option value={HardwareList.id} key={index}>
                            {HardwareList.version}
                          </option>
                        ))}
                    </select><div className="custom-arrow"></div>
                    {errors.id && (
                      <div className="ft-14 mt-1 red">{errors.id}</div>
                    )}
                  </div>

                  <div className="form-group col-md-6">
                    <label className="mr-sm-3 form-label">
                      Manufacturing date
                    </label>
                    <input
                      type="date"
                      name="manufacturing_date"
                      value={values.manufacturing_date || ""}
                      onChange={handleChange}
                      className={`form-control  form-select-user ${errors.manufacturing_date ? "error" : ""
                        }`}
                    />
                    {errors.manufacturing_date ? (
                      <div className="ft-14 mt-1 red">
                        {errors.manufacturing_date}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Server fallback Path</label>
                    <input
                      type="text"
                      name="server_fallback_path"
                      value={values.server_fallback_path || ""}
                      onChange={handleChange}
                      placeholder="Server fallback Path"
                      className={`form-control form-control-user ${errors.server_fallback_path ? "error" : ""
                        }`}
                    />
                    {errors.server_fallback_path && (
                      <div className="ft-14 mt-1 red">
                        {errors.server_fallback_path}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Server Path</label>
                    <input
                      type="text"
                      name="server_path"
                      value={values.server_path || ""}
                      onChange={handleChange}
                      placeholder="Server Path"
                      className={`form-control form-control-user ${errors.server_path ? "error" : ""
                        }`}
                    />
                    {errors.server_path && (
                      <div className="ft-14 mt-1 red">{errors.server_path}</div>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label className="form-label">Next Heartbeat Time (in seconds)</label>
                    <input
                      type="text"
                      name="heartbeat_time"
                      value={values.heartbeat_time || ""}
                      onChange={handleChange}
                      placeholder="Heartbeat Time"
                      className={`form-control form-control-user ${errors.heartbeat_time ? "error" : ""
                        }`}
                    />
                    {errors.heartbeat_time && (
                      <div className="ft-14 mt-1 red">
                        {errors.heartbeat_time}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group d-flex justify-content-end col-md-12">
                    <Button
                      type={"submit"}
                      localState={LocalState}
                      title={'Save'}
                      size={"btn-sm"}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {/* <div className="row">
                <div className="col-md-12 mb-4">
                  Usages: <u>20</u>
                </div>
                <div className="col-md-12">
                  Drivers behaviour score: <u>4.5</u>
                </div>
              </div> */}
        </div>
        {/* ) : (
            <div className="p-4">
              <div className="text-center ft-14 mt-3 font-weight-bold">
                You are not allowed to visit this screen...
              </div>
            </div>
          )} */}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default AddDevicesCode;
