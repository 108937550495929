import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Header from "../Header/Index.js";
import { useHistory } from 'react-router-dom';
import { Context } from "../Store/Store";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Modal } from 'antd';
import FlashMessage from "../FlashMessage/FlashMessage";
var { apiRequest } = require("../Api/Service");
var { UpdateUserAPI, EditUserAPI, GetVeriffData, utilityCountryList } = require("../Api/ApiRoutes");
const Helper = require("../Helper");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function EditUser(props) {
  const DetailSchema = Yup.object().shape({
    name: Yup.string().min(5, 'Too Short!').max(32, 'Too Long!').matches('[A-Z][a-z]*', 'First letter should be capital').required("This Field is Required"),
    email: Yup.string().email().required("This Field is Required"),
    contact_no: Yup.number().required("This Field is Required"),
    country: Yup.string().required("This Field is Required"),
    city: Yup.string().required("This Field is Required"),
    address: Yup.string().required("This Field is Required"),
    postal_code: Yup.string().required("This Field is Required"),
  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);
  const [userData, setuserData] = React.useState(null);
  const [userVeriffData, setuserVeriffData] = React.useState(null);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [locationKeys, setLocationKeys] = React.useState([])
  const [countries, setCountries] = React.useState([]);
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = React.useContext(Context);

  var user = Helper.getStorageData("SESSION");
  let user_id = props.match.params.id;

  const handleSubmit = (values) => {
    // const form_data = Helper.formData(values);
    const form_data = new FormData();
    form_data.append("name", values.name);
    form_data.append("email", values.email);
    form_data.append("id", user_id);
    form_data.append("contact_no", values.contact_no);
    form_data.append("password", values.password);
    form_data.append("password_confirmation", values.password_confirmation);
    form_data.append("image", values.image);
    form_data.append("city", values.city);
    form_data.append("country", values.country);
    form_data.append("address", values.address);
    form_data.append("postal_code", values.postal_code);

    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(UpdateUserAPI.method, UpdateUserAPI.url, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          dispatch({
            type: "SET_SEARCH_STATE",
            search_list: true,
            pagination: false,
            searchedName: null,
        });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };
  const containerStyle = {
    display: 'flex',
    overflowX: 'auto',
  };

  const thumbnailStyle = {
    width: '100px', // Adjust the width as needed
    height: 'auto', // Maintain the aspect ratio
    marginRight: '10px', // Add some spacing between thumbnails
    cursor: 'pointer',
  };
  
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };
  useEffect(() => {
    // if (
    //   Helper.getPermissions(
    //     "admin-update",
    //     user_permissions && user_permissions,
    //     "admin"
    //   ) ||
    //   Helper.getPermissions("", [], user.type)
    // ) {
    apiRequest(utilityCountryList.method, utilityCountryList.url)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data;
          setCountries(data);
        }
      })
      .catch((error) => {
        console.log(error);
    });
    apiRequest(EditUserAPI.method, EditUserAPI.url + user_id)
      .then((response) => {
        setuserData(response.data.data);
        if (response.data.code === 200) {
          //Get Veriff Data
          apiRequest(GetVeriffData.method, GetVeriffData.url + user_id)
            .then((response) => {
              setuserVeriffData(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    //}
  }, [user_id]);

  

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          dispatch({ type: "SET_SEARCH_STATE", pagination: true });
        } else {
          dispatch({ type: "SET_SEARCH_STATE", pagination: true });
        }
      }
    })
  }, [dispatch, history, locationKeys])

  if (redirectToReferrer) {
    return <Redirect to={"/admin/current-user-list/1"} />;
  }


  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        <div className="p-4">
          <h4 className="mb-3">Edit User</h4>
          {userData && (
            <Formik
              validateOnChange={false}
              validationSchema={DetailSchema}
              onSubmit={async (values) => {
                // console.log("permissions: ", values);
                handleSubmit(values);
              }}
              initialValues={{
                name: userData && userData.name,
                email: userData && userData.email,
                contact_no: userData && userData.contact_no,
                password: "",
                password_confirmation: "",
                image: userData && userData.image,
                permissions: [],
                country: userData?.country ? userData.country : "",
                city: userData?.city ? userData.city : "",
                address: userData?.address ? userData?.address : "",
                postal_code: userData?.postal_code ?  userData?.postal_code : ""
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form className="mt-2 user">
                  <FlashMessage
                    success={LocalState.success}
                    isSuccess={LocalState.isSuccess}
                    isError={LocalState.isError}
                    // errors={LocalState.errors}
                  />
                  <div className="form-row">
                    {/* <div className="form-group col-md-12 text-center">
                      <img
                        src={userData && userData.image}
                        alt="User"
                        style={{
                          height: "150px",
                          width: "150px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </div> */}
                    <div className="form-group col-md-6">
                      <label className="form-label">Full name</label>
                      <input
                        type="text"
                        name="name"
                        value={values.name || ""}
                        onChange={handleChange}
                        placeholder="Enter Your Name"
                        className={`form-control form-control-user  ${errors.name ? "error" : ""
                          }`}
                      />
                      {errors.name && (
                        <div className="ft-14 mt-1 red">{errors.name}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">E-mail</label>
                      <input
                        type="text"
                        name="email"
                        value={values.email || ""}
                        onChange={handleChange}
                        placeholder="Enter Your Email"
                        className={`form-control form-control-user  ${errors.email ? "error" : ""
                          }`}
                      />
                      {errors.email && (
                        <div className="ft-14 mt-1 red">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Password</label>
                      <input
                        type="password"
                        name="password"
                        value={values.password || ""}
                        onChange={handleChange}
                        placeholder="**********"
                        className={`form-control form-control-user  ${errors.password ? "error" : ""
                          }`}
                      />
                      {errors.password && touched.password ? (
                        <div className="ft-14 mt-1 red">{errors.password}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Repeat password</label>
                      <input
                        type="password"
                        name="password_confirmation"
                        value={values.password_confirmation || ""}
                        onChange={handleChange}
                        placeholder="*********"
                        className={`form-control form-control-user  ${errors.password_confirmation ? "error" : ""
                          }`}
                      />
                      {errors.password_confirmation && (
                        <div className="ft-14 mt-1 red">
                          {errors.password_confirmation}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Phone number</label>
                      <input
                        type="text"
                        name="contact_no"
                        value={values.contact_no || ""}
                        onChange={handleChange}
                        placeholder="Enter Your Contact"
                        className={`form-control form-control-user  ${errors.contact_no ? "error" : ""
                          }`}
                      />
                      {errors.contact_no && (
                        <div className="ft-14 mt-1 red">
                          {errors.contact_no}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Postal Code <span style={{ color: "red" }}>*</span></label>
                      <input
                        type="text"
                        name="postal_code"
                        value={values.postal_code || ""}
                        onChange={handleChange}
                        placeholder="Your Postal Code"
                        className={`form-control form-control-user ${errors.postal_code ? "error" : ""
                          }`}
                      />
                      {errors.postal_code && (
                        <div className="ft-14 mt-1 red">{errors.postal_code}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Country <span style={{ color: "red" }}>*</span> </label>
                      <select
                        name="country"
                        value={values.country || ""}
                        onChange={(e) => {
                            values.country = "";
                            handleChange(e);
                        }}
                        className={`form-control hideArrow form-select-user dropdown-height ${errors.country ? "error" : ""
                            }`}
                      >
                        {" "}
                        {countries &&
                            countries.map((record, index) => (
                                <option value={record} key={index}>
                                    {record}
                                </option>
                            ))}
                      </select>
                      <div className="custom-arrow"></div>
                      {/* <input
                        type="text"
                        name="country"
                        value={values.country || ""}
                        onChange={handleChange}
                        maxLength={32}
                        placeholder="Your Country"
                        className={`form-control form-control-user ${errors.country ? "error" : ""
                          }`}
                      /> */}
                      {errors.country && (
                        <div className="ft-14 mt-1 red">{errors.country}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">City <span style={{ color: "red" }}>*</span></label>
                      <input
                        type="text"
                        name="city"
                        value={values.city || ""}
                        onChange={handleChange}
                        placeholder="Your City"
                        className={`form-control form-control-user ${errors.city ? "error" : ""
                          }`}
                      />
                      {errors.city && (
                        <div className="ft-14 mt-1 red">{errors.city}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Address <span style={{ color: "red" }}>*</span> </label>
                      <input
                        type="text"
                        name="address"
                        value={values.address || ""}
                        onChange={handleChange}
                        maxLength={32}
                        placeholder="Your Address"
                        className={`form-control form-control-user ${errors.address ? "error" : ""
                          }`}
                      />
                      {errors.address && (
                        <div className="ft-14 mt-1 red">{errors.address}</div>
                      )}
                    </div>
                   
                  </div>
                  <div className="form-row">
                    <div className="form-group d-flex justify-content-end col-md-12">
                      <button
                        type="submit"
                        className="btn-submit btn-user" style={{ backgroundColor: user.background_color }}
                        disabled={LocalState.disable}
                      >
                        confirm Details
                        {LocalState.disable ? (
                          <span
                            className="ml-2 spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          <div className="row">
            <div className="col-md-12 mb-3">
              <h3>User Verif Data</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <strong>First Name:  </strong>{userVeriffData && userVeriffData.person && userVeriffData.person.person ? (userVeriffData.person.person.firstName ? userVeriffData.person.person.firstName : '-') : '-'}
            </div>
            <div className="col-md-12 mb-3">
              <strong>Last Name: </strong>{userVeriffData && userVeriffData.person && userVeriffData.person.person ? (userVeriffData.person.person.lastName ? userVeriffData.person.person.lastName : '-') : '-'}
            </div>
            <div className="col-md-12 mb-3">
              <strong>Date of Birth: </strong>{userVeriffData && userVeriffData.person && userVeriffData.person.person ? (userVeriffData.person.person.dateOfBirth ? userVeriffData.person.person.dateOfBirth : '-') : '-'}
            </div>
            <div className="col-md-12 mb-3">
              <strong>Document Country: </strong>{userVeriffData && userVeriffData.person && userVeriffData.person.document ? (userVeriffData.person.document.country ? userVeriffData.person.document.country : '-') : '-'}
            </div>
            <div className="col-md-12 mb-3">
              <strong>Document Type: </strong>{userVeriffData && userVeriffData.person && userVeriffData.person.document ? (userVeriffData.person.document.type ? userVeriffData.person.document.type : '-') : '-'}
            </div>
            <div className="col-md-12 mb-3">
              <strong>Document Number: </strong>{userVeriffData && userVeriffData.person && userVeriffData.person.document ? (userVeriffData.person.document.number ? userVeriffData.person.document.number : '-') : '-'}
            </div>
            <div className="col-md-12 mb-3">
              <strong>Expires at: </strong>{userVeriffData && userVeriffData.person && userVeriffData.person.document ? (userVeriffData.person.document.validUntil ? userVeriffData.person.document.validUntil : '-') : '-'}
            </div>
            <div className="col-md-12 mb-3">
              <strong>Usages: </strong> {userData && userData.usages ? userData.usages : '-'}
            </div>
            <div className="col-md-12 mb-3">
              <strong>Drivers behaviour score:</strong> {userData && userData.score}
            </div>
            <div className="col-md-12 mb-3">
              <div style={containerStyle} className="image-container">
                {userVeriffData && userVeriffData.media.map((imageUrl, index) => (
                  <img
                    key={index}
                    src={imageUrl}
                    alt={`user ${index + 1}`}
                    style={thumbnailStyle}
                    onClick={() => openModal(imageUrl)}
                    
                  />
                ))}
              </div>
              <Modal
                visible={!!selectedImage}
                title="Image Preview"
                footer={null}
                onCancel={closeModal}
              >
                <div style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
                  <img
                    alt="Preview"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      height: 'auto',
                      width: 'auto',
                      objectFit: 'contain',
                    }}
                    src={selectedImage}
                  />
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default EditUser;
