import React, { Component } from 'react';
import { CKEditor, ImageInsert } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


class EditorConvertToBookingHTML extends Component {
   constructor(props) {
        super(props);
        console.log(props.tem);
        const contentBlocks = props.tem;
        const editorState = props.editorState;
        const minheight = props.minheight;
        const maxheight = props.maxheight;
        //this.state='';
        this.state = contentBlocks
        this.editorState = editorState

        this.minheight = minheight
        this.maxheight = maxheight
        
    }
    
    render() {
        const editorContent  = this.state;
        const editorState  = this.editorState;

        const minheight  = this.minheight;
        const maxheight  = this.maxheight;

        console.log(editorContent);

        // const editorConfiguration = {
        //   plugins: [
        //     GeneralHtmlSupport
        //   ],
        //   htmlSupport: {
        //     allow: [
        //       {
        //         name: /^.*$/,
        //         styles: true,
        //         attributes: true,
        //         classes: true
        //       }
        //     ]
        //   },
        // };

        return (
        
            <div className="App" style={{ minHeight: minheight,maxHeight: maxheight}}>
                <style></style>
                <CKEditor
                    editor={ ClassicEditor }
                    data={editorContent}
                    disabled={editorState}
                    //config={editorConfiguration} // Pass the custom configuration here
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        this.props.selTemplate(data);
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                      //this.props.selTemplate(editor);
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            </div>
        );
    }
}
export default EditorConvertToBookingHTML;