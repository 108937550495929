    var routes = [
       
        {
          "route" : '/admin/shares-list',
        },
        {
          "route" : '/admin/shares-my-list',
        },
        {
            "route" : '/admin/user-list',
        },
        {
          "route" : '/admin/current-user-list',
        },
        {
          "route" : '/admin/admin-user-list',
          "moduleName" : 'admin/all',
        },
        {
          "route" : '/admin/my-admin-user-list',
          "moduleName" : 'admin',
        },
        {
          "route" : '/admin/software-upload-list',
          "moduleName" : 'device/fw',
        },
        {
            "route" : '/admin/devices-code-list',
        },
        {
          "route" : '/admin/list-white-label',
        },
        {
          "route" : '/admin/company-vehicle-list',
          "moduleName" : 'vehicle_company',
        },
        {
          "route" : '/admin/add-model-name-listing',
          "moduleName" : 'vehicle_model',
        },
        {
          "route" : '/admin/company-key-list',
          "moduleName" : 'vehicle_key',
        },
        {
          "route" : '/admin/usage-my-list',
          "moduleName" : 'usage/own',
        },
        {
          "route" : '/admin/usage-list',
          "moduleName" : 'usage/list',
        },
        {
          "route" : '/admin/shares-with-me-list',
          "moduleName" : 'shares/sharedwithme',
        },
        {
          "route" : '/admin/vehicle-list',
        },
        {
          "route" : '/admin/all-vehicle-list',
        },
        {
          "route" : '/admin/booking-list',
        },
        {
          "route" : '/admin/booking-active-list',
        },
        {
          "route" : '/admin/booking-future-list',
        },
        {
          "route" : '/admin/booking-vehicle-return-list',
        },
        {
          "route" : '/admin/booking-expired-list',
        },
        {
          "route" : '/admin/contract-listing',
        },
        {
          "route" : '/admin/station-listing',
          "moduleName" : 'stations',
        },
        {
          "route" : '/whitelabel/admin-list',
          "moduleName" : 'whitelabel_admin',
        },
        {
          "route" : '/admin/whitelabel-admin-user-list',
          "moduleName" : 'whitelabel_admin',
        },
      ]

module.exports = { routes }