let baseUrl = process.env.REACT_APP_API_URL; 
export const SignUpStep1 = {
  method: "POST",
  url: baseUrl + "/register",
};
export const broadcastAuth = {
  method: "POST",
  url: baseUrl + "/broadcasting/auth",
};
export const EventTrunk = {
  method: "POST",
  url: baseUrl + "/event/trunk/",
};
export const EventDoor = {
  method: "POST",
  url: baseUrl + "/event/door/",
};
export const SignUpStep2 = {
  method: "POST",
  url: baseUrl + "/confirm/code",
};
export const Login = {
  method: "POST",
  url: baseUrl + "/login",
};
export const ForgotPassword = {
  method: "POST",
  url: baseUrl + "/password/forgot",
};
export const PasswordReset = {
  method: "POST",
  url: baseUrl + "/password/reset",
};
export const PasswordChange = {
  method: "POST",
  url: baseUrl + "/user/password/change",
};
export const ResendOTP = {
  method: "POST",
  url: baseUrl + "/resend/otp",
};
export const VehicleListAPI = {
  method: "GET",
  url: baseUrl + "/vehicles",
};
export const AllVehicleListAPI = {
  method: "GET",
  url: baseUrl + "/vehicles/all",
};
export const UserDetailAPI = {
  method: "GET",
  url: baseUrl + "/user/get-user-detail",
};
export const KeyRegnAPI = {
  method: "POST",
  url: baseUrl + "/key/regenerate",
};
export const DeviceListAPI = {
  method: "GET",
  url: baseUrl + "/serial",
};
export const DeviceAddAPI = {
  method: "POST",
  url: baseUrl + "/serial/create",
};
export const DeviceUpdateAPI = {
  method: "POST",
  url: baseUrl + "/serial/update/",
};
export const DeviceEditAPI = {
  method: "GET",
  url: baseUrl + "/serial/get/",
};
export const DeviceDeleteAPI = {
  method: "DELETE",
  url: baseUrl + "/serial/delete/",
};
export const SoftwareListAPI = {
  method: "GET",
  url: baseUrl + "/device/fw",
};
export const SoftwareAddAPI = {
  method: "POST",
  url: baseUrl + "/device/fw/upload",
};
export const deviceDeleteQueueAPI = {
  method: "DELETE",
  url: baseUrl + "/device/delete-queue-command/",
};
export const deviceQueueListAPI = {
  method: "GET",
  url: baseUrl + "/device/get-queue-command-list",
};

export const SoftwareDeleteAPI = {
  method: "DELETE",
  url: baseUrl + "/device/fw/delete/",
};
export const EditSoftwareAPI = {
  method: "GET",
  url: baseUrl + "/device/fw/get/",
};
export const UpdateSoftwareAPI = {
  method: "POST",
  url: baseUrl + "/device/fw/update/",
};
export const UpdateSoftwaree = {
  method: "POST",
  url: baseUrl + "/event/update/software",
};
export const CurrentUserAPI = {
  method: "GET",
  url: baseUrl + "/user",
};

export const AddVehicleAPI = {
  method: "POST",
  url: baseUrl + "/vehicles/create",
};

export const AddUser = {
  method: "POST",
  url: baseUrl + "/user/create",
};

export const UserListAPI = {
  method: "GET",
  url: baseUrl + "/user/all",
};

export const OperatorListAPI = {
  method: "GET",
  url: baseUrl + "/admin/all",
};
export const MyOperatorListAPI = {
  method: "GET",
  url: baseUrl + "/admin",
};

export const OperatorListWhitelistAPI = {
  method: "GET",
  url: baseUrl + "/whitelabel_admin",
};

export const VerifyUser = {
  method: "POST",
  url: baseUrl + "/user/userdetails",
};
export const AddShareAPI = {
  method: "POST",
  url: baseUrl + "/shares/create",
};
export const GetVehicles = {
  method: "GET",
  url: baseUrl + "/shares/getBothVehicle",
};
export const ShareListAPI = {
  method: "GET",
  url: baseUrl + "/shares",
};
export const ShareMyListAPI = {
  method: "GET",
  url: baseUrl + "/shares/myshares",
};
export const MySharesWithMeAPI = {
  method: "GET",
  url: baseUrl + "/shares/sharedwithme",
};
export const AddOperator = {
  method: "POST",
  url: baseUrl + "/admin/create",
};
export const AddWhiteLableOperator = {
  method: "POST",
  url: baseUrl + "/whitelabel_admin/create",
};
export const EditVehicle = {
  method: "GET",
  url: baseUrl + "/vehicles/get/",
};

export const VehicleMakeList = {
  method: "GET",
  url: baseUrl + "/vehicle_company/list",
};
export const VehicleMakeCompany = {
  method: "GET",
  url: baseUrl + "/vehicle_company",
};
export const VehicleModelList = {
  method: "GET",
  url: baseUrl + "/vehicle_model?",
};
export const VehicleModelListingName = {
  method: "GET",
  url: baseUrl + "/vehicle_model",
};
export const UsageListAPI = {
  method: "GET",
  url: baseUrl + "/usage/list",
};
export const UsageListMyAPI = {
  method: "GET",
  url: baseUrl + "/usage/own",
};
export const alldeleteUsage = {
  method: "POST",
  url: baseUrl + "/usage/deleteall/",
};

export const deleteUsage = {
  method: "DELETE",
  url: baseUrl + "/usage/delete/",
};

export const UsageDetailAPI = {
  method: "GET",
  url: baseUrl + "/usage/get/data",
};
export const VehicleUsageAPI = {
  method: "GET",
  url: baseUrl + "/vehicles/get-usages/",
};
export const SearchApi = {
  method: "POST",
  url: baseUrl + "/vehicles/search" ,
};
export const ListSearchApi = {
  method: "GET",
  url: baseUrl + "/" ,
};
export const KeyModelList = {
  method: "GET",
  url: baseUrl + "/vehicle_key/list?",
};
export const KeyModelListing = {
  method: "GET",
  url: baseUrl + "/vehicle_key",
};
export const UserPermissions = {
  method: "GET",
  url: baseUrl + "/user/permissions/list",
};
export const HardwareList = {
  method: "GET",
  url: baseUrl + "/device/fw/hardwares",
};
export const AddVehicleCompanyAPI = {
  method: "POST",
  url: baseUrl + "/vehicle_company/create",
};
export const DeleteVehicleAPI = {
  method: "DELETE",
  url: baseUrl + "/vehicle_company/delete/",
};

export const DeleteVehicleModelListingAPI = {
  method: "DELETE",
  url: baseUrl + "/vehicle_model/delete/",
};

export const DeleteVehicleKeyAPI = {
  method: "DELETE",
  url: baseUrl + "/vehicle_key/delete/",
};

export const AddVehicleModelAPI = {
  method: "POST",
  url: baseUrl + "/vehicle_model/create",
};

export const AddVehicleKeyAPI = {
  method: "POST",
  url: baseUrl + "/vehicle_key/create",
};
export const EditAdminAPI = {
  method: "GET",
  url: baseUrl + "/admin/get/",
};
export const EditWhiteLabelAdminAPI = {
  method: "GET",
  url: baseUrl + "/whitelabel_admin/get/",
};
export const UpdateAdminAPI = {
  method: "POST",
  url: baseUrl + "/admin/update",
};
export const UpdateWhitelabelAdminAPI = {
  method: "POST",
  url: baseUrl + "/whitelabel_admin/update",
};
export const EditUserAPI = {
  method: "GET",
  url: baseUrl + "/user/get/",
};
export const EditCompanyAPI = {
  method: "POST",
  url: baseUrl + "/vehicle_company/get/",
};
export const EditModelAPI = {
  method: "POST",
  url: baseUrl + "/vehicle_model/get/",
};

export const EditKeyAPI = {
  method: "POST",
  url: baseUrl + "/vehicle_key/get/",
};
export const UpdateCompanyAPI = {
  method: "POST",
  url: baseUrl + "/vehicle_company/update/",
};

export const UpdateModelAPI = {
  method: "POST",
  url: baseUrl + "/vehicle_model/update/",
};

export const UpdateKeyAPI = {
  method: "POST",
  url: baseUrl + "/vehicle_key/update/",
};

export const UpdateUserAPI = {
  method: "POST",
  url: baseUrl + "/user/update",
};
export const deleteVehicle = {
  method: "DELETE",
  url: baseUrl + "/vehicles/delete/",
};
export const deleteShare = {
  method: "DELETE",
  url: baseUrl + "/shares/delete/",
};
// alldeleteUser:{
//     'method' : 'POST',
//     url: baseUrl + '/user/deleteall',
// },
export const alldeleteVechile = {
  method: "POST",
  url: baseUrl + "/vehicles/deleteAll",
};
export const alldeleteUser = {
  method: "POST",
  url: baseUrl + "/user/deleteall",
};
export const alldeleteVehicleCompany = {
  method: "POST",
  url: baseUrl + "/vehicle_company/deleteAll",
};
export const alldeleteAdmin = {
  method: "POST",
  url: baseUrl + "/admin/delete/all",
};
export const alldeleteWhiteLableAdmin = {
  method: "DELETE",
  url: baseUrl + "/whitelabel_admin/delete/",
};
export const alldeleteShare = {
  method: "POST",
  url: baseUrl + "/shares/deleteall",
};
export const deleteUser = {
  method: "DELETE",
  url: baseUrl + "/user/delete/",
};
export const deleteAdmin = {
  method: "DELETE",
  url: baseUrl + "/admin/delete/",
};
export const UpdateVehicleApI = {
  method: "POST",
  url: baseUrl + "/vehicles/update/",
};

export const UpdateDetailsApI = {
  method: "POST",
  url: baseUrl + "/user/profile/update",
};
export const UpdateEmailApI = {
  method: "POST",
  url: baseUrl + "/user/email-update",
};
export const VerifyEmailApI = {
  method: "POST",
  url: baseUrl + "/user/verify-code",
};
export const VerifyUserEmailApI = {
  method: "POST",
  url: baseUrl + "/verify/login/otp",
};
export const VerifyPhoneApI = {
  method: "POST",
  url: baseUrl + "/user/number/add",
};
export const ShareEditApI = {
  method: "GET",
  url: baseUrl + "/shares/get/",
};
export const UpdateShareAPI = {
  method: "POST",
  url: baseUrl + "/shares/update/",
};
export const InvitationAPI = {
  method: "POST",
  url: baseUrl + "/generate-link",
};

export const AddWhitelabelListing = {
  method: "GET",
  url: baseUrl + "/whitelabel",
};

export const SearchListAPI = {
  method: "POST",
  url: baseUrl + "/whitelabel/search",
};

export const Addwhitelabeltovechile = {
  method: "POST",
  url: baseUrl + "/vehicles/add/label",
};

export const Updatewhitelabeltovechile = {
  method: "POST",
  url: baseUrl + "/whitelabel/remove/",
};

export const Sharewhitelabeltovechile = {
  method: "POST",
  url: baseUrl + "/whitelabel/share/",
};

export const AddWhitelabel = {
  method: "POST",
  url: baseUrl + "/whitelabel/create",
};

export const EditWhitelabelListing = {
  method: "POST",
  url: baseUrl + "/whitelabel/get/",
};

export const WhitelabelListingDetail = {
  method: "POST",
  url: baseUrl + "/whitelabel/get-detail",
};

export const UpdateWhitelabelListing = {
  method: "POST",
  url: baseUrl + "/whitelabel/update/",
};

export const AllDeleteWhitelabelListing = {
  method: "POST",
  url: baseUrl + "/whitelabel/deleteAll",
};

export const DeleteWhitelabelListing = {
  method: "DELETE",
  url: baseUrl + "/whitelabel/delete/",
};
export const BaseUrl = {
  method: "GET",
  url: baseUrl,
};
export const GenerateSerialEncryptionKey = {
  method: "GET",
  url: baseUrl + "/serial/generate/key",
};
export const StatusUpdate = {
  method: "POST",
  url: baseUrl + "/user/update-status",
};

export const sendCommandApi = {
  method: "POST",
  url: baseUrl + "/device/custom-command",
};

export const Mobi1otApi = {
  method: "POST",
  url: baseUrl + "/iot",
};

export const UpdatAlarmSettinsgApi = {
  method: "POST",
  url: baseUrl + "/system-configuration/update-alarms",
};

export const getAlarmSettingsApi = {
  method: "GET",
  url: baseUrl + "/system-configuration/get-alarm-values",
};

export const UploadSerialConfigurationApi = {
  method: "POST",
  url: baseUrl + "/serial/upload-config",
};
export const UpdateSerialConfigurationApi = {
  method: "POST",
  url: baseUrl + "/serial/update-config",
};

export const updateDeviceWakeup = {
  method: "POST",
  url: baseUrl + "/device/keep-alive",
};

export const CreateHelpAndSupport = {
  method: "POST",
  url: baseUrl + "/help_and_support/create",
};

export const UpdateHelpAndSupport = {
  method: "POST",
  url: baseUrl + "/help_and_support/update",
};

export const DeleteHelpAndSupport = {
  method: "DELETE",
  url: baseUrl + "/help_and_support/delete",
};

export const GetHelpAndSupportCategoryList = {
  method: "Get",
  url: baseUrl + "/help_and_support/category-list",
};

export const GetHelpAndSupportSubCategoryList = {
  method: "Get",
  url: baseUrl + "/help_and_support",
};

export const EditHelpAndSupportSubCategoryList = {
  method: "Get",
  url: baseUrl + "/help_and_support/get/",
};
export const GetHeartBeatListing = {
  method: "Get",
  url: baseUrl + "/heartbeat",
};
export const HeartBeatExport = {
  method: "Get",
  url: baseUrl + "/heartbeat/export",
};
export const alldeleteVehicleModelAPI = {
  method: "POST",
  url: baseUrl + "/vehicle_model/deleteAll",
};
export const whitelabelListEndpoint = {
  method: "GET",
  url: baseUrl + "/whitelabel?",
};
export const getWhitelabelVehicles = {
  method: "GET",
  url: baseUrl + "/bookings/get-vehicles?",
};

export const CreateBooking = {
  method: "Post",
  url: baseUrl + "/bookings/create",
};

export const GetBookingList = {
  method: "Get",
  url: baseUrl + "/bookings",
};

export const DeleteBooking = {
  method: "DELETE",
  url: baseUrl + "/bookings/delete/",
};

export const UpdateBooking = {
  method: "POST",
  url: baseUrl + "/bookings/update/",
};

export const UploadBookingImage = {
  method: "POST",
  url: baseUrl + "/bookings/upload/image",
};

export const DeleteBookingImage = {
  method: "DELETE",
  url: baseUrl + "/bookings/delete/image/",
};

export const AllDeleteVehicleKey = {
  method: "POST",
  url: baseUrl + "/vehicle_key/deleteAll",
};

export const GetAllContract = {
  method: "GET",
  url: baseUrl + "/contracts",
};

export const DeleteContract = {
  method: "DELETE",
  url: baseUrl + "/contracts/delete/",
};

export const GetTemplate = {
  method: "GET",
  url: baseUrl + "/contracts/get-template/",
};

export const UpdateTemplate = {
  method: "POST",
  url: baseUrl + "/contracts/update/",
};

export const getPlaceholder = {
  method: "GET",
  url: baseUrl + "/contracts/get-template-placeholders/",
};


export const GetInsuranceTemplate = {
  method: "GET",
  url: baseUrl + "/contracts/get-insurance-template/",
};

export const getInsuracePlaceholder = {
  method: "GET",
  url: baseUrl + "/contracts/get-extra-insurance-placeholders/",
};

export const GetVeriffData = {
  method: "GET",
  url: baseUrl + "/user/get-veriff/",
};

export const GetAllStations = {
  method: "GET",
  url: baseUrl + "/stations",
};

export const DeleteStation = {
  method: "DELETE",
  url: baseUrl + "/stations/delete/",
};

export const GetStation = {
  method: "GET",
  url: baseUrl + "/stations/get-detail/",
};

export const GetWhiteLabelStation = {
  method: "GET",
  url: baseUrl + "/stations/get/",
};
export const CreateStation = {
  method: "POST",
  url: baseUrl + "/stations/create/",
};
export const UpdateStation = {
  method: "POST",
  url: baseUrl + "/stations/update/",
};

export const DownLoadContract = {
  method: "GET",
  url: baseUrl + "/bookings/download-contract/",
};

export const SendContractviaEmail = {
  method: "POST",
  url: baseUrl + "/bookings/send-contract/",
};

export const GetVechileAlarm = {
  method: "GET",
  url: baseUrl + "/alarms",
};

export const VechileAlarmDelete = {
  method: "DELETE",
  url: baseUrl + "/alarms/delete/",
};

export const VechileAlarmDeleteAll = {
  method: "DELETE",
  url: baseUrl + "/alarms/delete-all/",
};

export const ActiveShares = {
  method: "GET",
  url: baseUrl + "/vehicles/active-shares/",
};

export const ExtraWhitelableuserLsting = {
  method: "GET",
  url: baseUrl + "/extras/",
};
export const UpdateExtra = {
  method: "POST",
  url: baseUrl + "/extras/update/",
};

export const WhiteLableAdminsForSuperAdmin = {
  method: "GET",
  url: baseUrl + "/whitelabel_admin/list",
};

export const WhiteLablePermissions = {
  method: "GET",
  url: baseUrl + "/whitelabel_admin/permissions/list/",
};

export const TimeZoneList = {
  method: "GET",
  url: baseUrl + "/utility/timezone",
};

export const userActivity = {
  method: "POST",
  url: baseUrl + "/user/update-last-activity",
};

export const getDriverName = {
  method: "GET",
  url: baseUrl + "/user/get-name/",
};


export const getEmailTemplates = {
  method: "GET",
  url: baseUrl + "/email-template/get-all-list",
};

export const getEmailTypes = {
  method: "GET",
  url: baseUrl + "/email-template/type/",
};

export const getEmailTemplateByTypeId = {
  method: "GET",
  url: baseUrl + "/email-template/list/",
};

export const createTemplate = {
  method: "POST",
  url: baseUrl + "/email-template/store/",
};

export const sendTestTemplate = {
  method: "POST",
  url: baseUrl + "/email-template/test/",
};

export const PrivacyPolicyListing = {
  method: "GET",
  url: baseUrl + "/privacy_policy",
};

export const PrivacyPolicyTemplate = {
  method: "GET",
  url: baseUrl + "/privacy_policy/get-template/",
};

export const UpdatePrivacyPolicyTemplate = {
  method: "POST",
  url: baseUrl + "/privacy_policy/update",
};

export const DeletePrivacyPolicy = {
  method: "DELETE",
  url: baseUrl + "/privacy_policy/delete/",
};

export const TermsOfUseTemplateListing = {
  method: "GET",
  url: baseUrl + "/terms_of_use",
};

export const TermsOfUseTemplate = {
  method: "GET",
  url: baseUrl + "/terms_of_use/get-template/",
};


export const UpdateTermsOfUseTemplate = {
  method: "POST",
  url: baseUrl + "/terms_of_use/update",
};

export const DeleteTermOfUse = {
  method: "DELETE",
  url: baseUrl + "/terms_of_use/delete/",
};

export const getNotificationTemplates = {
  method: "GET",
  url: baseUrl + "/notification-template/get-all-list",
};

export const getNotificationTypes = {
  method: "GET",
  url: baseUrl + "/notification-template/type/",
};

export const getNotificationTemplateByTypeId = {
  method: "GET",
  url: baseUrl + "/notification-template/list/",
};

export const createNotificationTemplate = {
  method: "POST",
  url: baseUrl + "/notification-template/store/",
};

export const saveNotificationSettings = {
  method: "POST",
  url: baseUrl + "/notification-type/update-type-status",
};

export const searchEmailTemplate = {
  method: "POST",
  url: baseUrl + "/email-template/search",
};

export const searchNotificationTemplate = {
  method: "POST",
  url: baseUrl + "/notification-template/search",
};

export const utilityCountryList = {
  method: "GET",
  url: baseUrl + "/utility/countries",
};
