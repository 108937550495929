import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
  data: [],
  metaData: [],
  user: {},
  call_one : false,
  search_list : false,
  pagination: false,
  advanceSearch: false,
  set_active_page: 1,
  start_date: null,
  end_date: null,
  searchedName: null,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default Store;
