import React from "react";
import { Redirect } from "react-router-dom";
import Header from "../../Header/Index.js";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import FlashMessage from "../../FlashMessage/FlashMessage.js";
var { apiRequest } = require("../../Api/Service.js");
var { UpdateAdminAPI, EditAdminAPI } = require("../../Api/ApiRoutes.js");

const Helper = require("../../Helper.js");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: true,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function EditAdmin(props) {
  const DetailSchema = Yup.object().shape({
    //  name: Yup.string().required("This Field is Required"),
    //  email: Yup.string().email().required("This Field is Required"),
    //  contact_no: Yup.number().required("This Field is Required"),
    //  password: Yup.string().required("This Field is Required"),
    //  password_confirmation: Yup.string().required("This Field is Required")
    //  .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);
  const [userData, setuserData] = React.useState({});
  var user = Helper.getStorageData("SESSION");
  var user_permissions = user.permissions;
  let user_id = props.match.params.id;

  const handleSubmit = (values) => {
    const form_data = new FormData();
    form_data.append("name", values.name);
    form_data.append("email", values.email);
    form_data.append("id", user_id);
    form_data.append("contact_no", values.contact_no);
    form_data.append("password", values.password);
    form_data.append("password_confirmation", values.password_confirmation);
    form_data.append("type", 2);
    form_data.append("image", values.image);
    for (let index = 0; index < values.permissions.length; index++) {
      form_data.append("permissions[]", values.permissions[index]);
    }

    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(UpdateAdminAPI.method, UpdateAdminAPI.url, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.message,
        });
        window.scrollTo(500, 0);
      });
  };

  const isAdminUpdateAllowed = React.useMemo(() => {
    return Helper.getPermissions(
      "admin-update",
      user_permissions && user_permissions,
      "admin"
    );
  }, [user_permissions])

  const canGetPermissions = React.useMemo(() => {
    return Helper.getPermissions("", [], user.type);
  }, [user.type])

  React.useEffect(() => {
    if (isAdminUpdateAllowed || canGetPermissions) {
      apiRequest(EditAdminAPI.method, EditAdminAPI.url + user_id)
        .then((response) => {
          if (response.data.code === 200) {
            const data = response.data.data;
            setuserData(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [canGetPermissions, isAdminUpdateAllowed, user_id]);


  function formatString(inputString) {
    // Split the string by underscores
    const words = inputString.split('_');

    // Capitalize the first letter of each word
    const formattedWords = words.map((word) =>
      word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the formatted words with spaces
    const formattedString = formattedWords.join(' ');

    return formattedString;
  }

  if (redirectToReferrer) {
    return <Redirect to={"/admin/admin-user-list/1"} />;
  }
  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        {/* {Helper.getPermissions(
            "admin-update",
            user_permissions && user_permissions,
            "admin"
          ) || (Helper.getPermissions('', [], user.type)) ? ( */}
        <div className="p-4">
          <h4 className="mb-3">Edit Admin</h4>
          {userData && userData.user_details && (
            <Formik
              validateOnChange={false}
              validationSchema={DetailSchema}
              onSubmit={async (values) => {
                handleSubmit(values);
              }}
              initialValues={{
                name: userData.user_details && userData.user_details.name,
                email: userData.user_details && userData.user_details.email,
                contact_no:
                  userData.user_details && userData.user_details.contact_no,
                password: "",
                password_confirmation: "",
                image: userData.user_details && userData.user_details.image,
                permissions: Object.entries(userData)
                  .filter(([key]) => key !== "user_details" && key !== "usages")
                  .reduce((acc, [key, permissions]) => {
                    permissions.forEach(permission => {
                      if (permission.is_checked) {
                        acc.push(permission.id);
                      }
                    });
                    return acc;
                  }, [])
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form className="mt-2 user">
                  <FlashMessage
                    success={LocalState.success}
                    isSuccess={LocalState.isSuccess}
                    isError={LocalState.isError}
                    errors={LocalState.errors}
                  />
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Full Name</label>
                      <input
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Your name"
                        className={`form-control form-control-user ${errors.name ? "error" : ""
                          }`}
                      />
                      {errors.name && (
                        <div className="ft-14 mt-1 red">{errors.name}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Email</label>
                      <input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        placeholder="Your Email"
                        className={`form-control form-control-user ${errors.email ? "error" : ""
                          }`}
                      />
                      {errors.email && (
                        <div className="ft-14 mt-1 red">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Password</label>
                      <input
                        type="password"
                        name="password"
                        value={values.password || ""}
                        onChange={handleChange}
                        placeholder="**********"
                        className={`form-control form-control-user ${errors.password ? "error" : ""
                          }`}
                      />
                      {errors.password && touched.password ? (
                        <div className="ft-14 mt-1 red">{errors.password}</div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Confirm password</label>
                      <input
                        type="password"
                        name="password_confirmation"
                        value={values.password_confirmation || ""}
                        onChange={handleChange}
                        placeholder="*********"
                        className={`form-control form-control-user${errors.password_confirmation ? "error" : ""
                          }`}
                      />
                      {errors.password_confirmation && (
                        <div className="ft-14 mt-1 red">
                          {errors.password_confirmation}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Phone number</label>
                      <PhoneInput
                        country={"ee"}
                        // enableSearch={true}
                        name="contact_no"
                        onChange={(e, c) => {
                          values.contact_no = '+' + e
                        }}
                        value={values.contact_no || ""}
                        inputProps={{
                          className: `w-100 pl-5 rounded-left form-control form-control-user ${errors.contact_no ? 'error' : ''}`,
                        }}
                      />
                      {errors.contact_no && (
                        <div className="ft-14 mt-1 red">
                          {errors.contact_no}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    {Object.entries(userData)
                      .filter(([key]) => key !== "user_details" && key !== "usages")
                      .map(([key, permissionGroup]) => {
                        return (
                          <div className="form-group col-md-2">
                            <label className="form-label">{formatString(key)} Permissions</label>
                            <FieldArray
                              name="permissions"
                              render={(arrayHelpers) => (
                                permissionGroup?.map((permission) => {
                                  return <div key={permission.id}>
                                    <label
                                      htmlFor={`permissions-${permission.id}`}
                                    >
                                      <input
                                        name="permissions"
                                        type="checkbox"
                                        id={`permissions-${permission.id}`}
                                        value={permission.id}
                                        checked={values.permissions.includes(
                                          permission.id
                                        )}
                                        onChange={(e) => {
                                          if (e.target.checked)
                                            arrayHelpers.push(permission.id);
                                          else {
                                            const idx =
                                              values.permissions.indexOf(
                                                permission.id
                                              );
                                            arrayHelpers.remove(idx);
                                          }
                                        }}
                                      />{" "}
                                      {permission.name}
                                    </label>
                                  </div>
                                })

                              )}
                            />
                          </div>
                        )
                      })}
                  </div>
                  <div className="form-row">
                    <div className="form-group d-flex justify-content-end col-md-12">
                      <button
                        type="submit"
                        className="btn-submit btn-user"
                        disabled={LocalState.disable}
                      >
                        confirm Details
                        {LocalState.disable ? (
                          <span
                            className="ml-2 spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          <div className="row">
            <div className="col-md-12 mb-4">
              Usages: <u>{userData.usages ? userData.usages : "-"}</u>
            </div>
            <div className="col-md-12">
              Drivers behaviour score: <u>{userData.user_details ? userData.user_details.score : "-"}</u>
            </div>
          </div>
        </div>
        {/* // ) : (
          //   <div className="p-4">
          //     <div className="text-center ft-14 mt-3 font-weight-bold">
          //       You are not allowed to visit this screen...
          //     </div>
          //   </div>
        // )} */}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default EditAdmin;
