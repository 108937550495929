import React, { useEffect } from "react";
import Header from "../Header/Index.js";
import { Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FlashMessage from "../FlashMessage/FlashMessage";
import Button from "../../commonComponents/Button.js";
let { apiRequest } = require("../Api/Service");
let {
  CreateHelpAndSupport,
  whitelabelListEndpoint,
} = require("../Api/ApiRoutes");
const Helper = require("../Helper");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function AddHelpAndSupportCategory() {
  const VehicleSchema = Yup.object().shape({
    name: Yup.string().required("This Field is Required"),
    label_id: Yup.string().required("This Field is Required"),
  });

  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [redirectToReferrer, setredirectToReferrer] = React.useState(false);
  const [whitelabelList, setWhitelabelList] = React.useState([]);

  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;

  const handleWhitelabelList = (event) => {
    if (user.type !== "whitelabel") {
      apiRequest(
        whitelabelListEndpoint.method,
        whitelabelListEndpoint.url + `per_page=-1`
      )
        .then((response) => {
          if (response.data.code === 200) {
            setWhitelabelList(response.data.data);
          } else {
            console.log("Failure", response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    handleWhitelabelList();
  }, []);
  const handleSubmit = (values) => {
    const form_data = Helper.formData(values);
    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(CreateHelpAndSupport.method, CreateHelpAndSupport.url, form_data)
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setTimeout(() => {
            setredirectToReferrer(true);
          }, 2000);
        }
      })
      .catch((error) => {
        //console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        window.scrollTo(500, 0);
      });
  };

  if (redirectToReferrer) {
    return <Redirect to={"/admin/help-and-support/1"} />;
  }

  return (
    <React.Fragment>
      {/* <div className="wrapper d-flex align-items-stretch">
       <Sidebar /> */}
      <div id="content">
        <Header showSearch={false} showAdvanceSearch={false} />
        {Helper.getPermissions(
          "help_and_support-create",
          permissions && permissions,
          user.type
        ) ? (
          <div className="p-4">
            <Formik
              validateOnChange={false}
              validationSchema={VehicleSchema}
              onSubmit={(values) => {
                // same shape as initial values
                handleSubmit(values);
              }}
              initialValues={{
                name: "",
                label_id: user.type === "whitelabel" ? user.label_id : "",
                lang: "en",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form className="mt-2" onSubmit={handleSubmit}>
                  <FlashMessage
                    success={LocalState.success}
                    isSuccess={LocalState.isSuccess}
                    isError={LocalState.isError}
                    errors={LocalState.errors}
                  />
                  <div className="form-row">
                    {user.type !== "whitelabel" && (
                      <div className="form-group col-md-6">
                        <label className="form-label">WhiteLabel</label>
                        <select
                          name="label_id"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={`form-control form-select-user dropdown-height ${
                            errors.label_id ? "error" : ""
                          }`}
                        >
                          {" "}
                          <option value="">Select WhiteLabel</option>
                          {whitelabelList &&
                            whitelabelList.map((record, index) => (
                              <option value={record.id} key={index}>
                                {record.name}
                              </option>
                            ))}
                        </select>
                        {errors.label_id && (
                          <div className="ft-14 mt-1 red">
                            {errors.label_id}
                          </div>
                        )}
                      </div>
                    )}
                    {user.type === "whitelabel" && (
                      <input
                        type="hidden"
                        name="label_id"
                        value={values.label_id}
                      />
                    )}
                    <div className="form-group col-md-6">
                      <label className="form-label">Category name</label>
                      <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        placeholder="Category name"
                        className={`form-control ${
                          errors.device_code ? "error" : ""
                        }`}
                      />
                      {errors.device_code && (
                        <div className="ft-14 mt-1 red">
                          {errors.device_code}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <select
                        className={`form-control  dropdown-height ${
                          errors.lang ? "error" : ""
                        }`}
                        name="lang"
                        value={values.lang}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option value="en">English</option>
                        <option value="et">Estonia</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div
                      className="form-group d-flex justify-content-end col-md-12"
                     
                    >
                      <Button
                      type={"submit"}
                      localState={LocalState}
                      title={'Save'}
                      size={"btn-sm"}
                    />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <div className="p-4">
            <div className="text-center ft-14 mt-3 font-weight-bold">
              You are not allowed to visit this screen...
            </div>
          </div>
        )}
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}

export default AddHelpAndSupportCategory;
