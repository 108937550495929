import React, { useEffect, useContext, useCallback } from "react";
import { NavLink, useLocation, useHistory, useParams } from "react-router-dom";
import Header from "../Header/Index.js";
import { Formik, Form } from "formik";
import { ToastContainer, toast } from "react-toastify";
import AdvanceSearch from "../Header/AdvanceVehicleSearch";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { Context } from "../Store/Store";
import { Modal, Button, Icon } from "rsuite";
import Echo from "laravel-echo";
import Pagination from "react-js-pagination";
import Helper from "../Helper";
import { broadcastAuth, EventDoor, EventTrunk, ListSearchApi } from "../Api/ApiRoutes";
import CheckBox from "../../commonComponents/CheckBox.js";

var {
  AllVehicleListAPI,
  Updatewhitelabeltovechile,
  Addwhitelabeltovechile,
  SearchListAPI,
  AddWhitelabelListing,
  Sharewhitelabeltovechile,
  deleteVehicle,
  alldeleteVechile,
  UpdateSoftwaree,
} = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");
window.Pusher = require("pusher-js");

const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function AllVehicleList(props) {
  const DetailSchema = Yup.object().shape({
    hprice: Yup.string().required("This Field is Required"),
    dprice: Yup.string().required("This Field is Required"),
  });

  const [state, dispatch] = useContext(Context);
  const [records, setrecords] = React.useState([]);
  const [whiterecords, setwhiterecords] = React.useState([]);
  const [wresult, whitelabelsetResult] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [doorLoader, setDoorLoader] = React.useState(null);
  const [trunkLoader, setTrunkLoader] = React.useState(null);
  const [setIsSoftware] = React.useState(null);
  const [result, setResult] = React.useState({});
  const [rsModal, setRsModal] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(null);
  const [whitelabelModal, setWhitelabelModal] = React.useState(false);
  const [sharewhitelabelModal, setshareWhitelabelModal] = React.useState(false);
  const [currentLabelId, setCurrentLabelId] = React.useState(null);
  const [AdvanceSearchShow, setAdvanceSearchShow] = React.useState(false);
  const [locationKeys, setLocationKeys] = React.useState([]);
  const [sort_by, setSortBy] = React.useState(1);

  const history = useHistory();
  let location = useLocation();
  const { id } = useParams()
  const Modalclose = () => {
    setRsModal(false);
  };
  const Modalopen = (e) => {
    setCurrentId(e.target.dataset.id);
    setRsModal(true);
  };

  /*
    TZ convert to given date format
  */

  const getWhiteLabelList = () => {
    Helper.overlay(true);

    apiRequest(AddWhitelabelListing.method, AddWhitelabelListing.url)
      .then((response) => {
        if (response.data.code === 200) {
          setwhiterecords(response.data.data);
          whitelabelsetResult(response.data.meta);
          Helper.overlay(false);
        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        //console.log(error);
        Helper.overlay(false);
      });
  };

  const whitelabelModalclose = () => {
    setWhitelabelModal(false);
  };
  const whitelabelModalopen = (e) => {
    getWhiteLabelList();
    setCurrentId(e.target.dataset.id);
    setWhitelabelModal(true);
    setCurrentLabelId(e.target.dataset.label);
  };

  const sharewhitelabelModalclose = () => {
    setshareWhitelabelModal(false);
  };
  // const sharewhitelabelModalopen = (e) => {
  //   setCurrentId(e.target.dataset.id);
  //   setshareWhitelabelModal(true);
  // };

  var user = Helper.getStorageData("SESSION");
  var permissions = user.permissions;

  const handleClose = () => {
    setShow(false);
    setDoorLoader(null);
    setTrunkLoader(null);
  };

  const AdvanceVehicleSearch = (resData) => {
    //console.log(resData);
    if (resData.length === 0) {
      setrecords([]);
      setResult([]);
    } else {
      setrecords(resData.data.data);
      setResult(resData.data.meta);
    }
  };

  const handleLabel = (id) => {
    setCurrentLabelId(id);
  };

  const getVehicleList = useCallback(
    (page, sort) => {
      Helper.overlay(true);
      apiRequest(
        AllVehicleListAPI.method,
        AllVehicleListAPI.url + `?page=${page}&sort_by=${sort ?? sort_by}`
      )
        .then((response) => {
          if (response.data.code === 200) {
            setrecords(response.data.data);
            setResult(response.data.meta);
            dispatch({ type: "SET_SEARCH_STATE", search_list: false });
            Helper.overlay(false);
          } else {
            setErrorMsg(response.data.message);
            Helper.overlay(false);
          }
        })
        .catch((error) => {
          setErrorMsg(error);
          Helper.overlay(false);
        });
    },
    [dispatch, sort_by]
  );
  const getVehicleById = useCallback((id) => {
    Helper.overlay(true)
    apiRequest(
      AllVehicleListAPI.method,
      AllVehicleListAPI.url +
      `?vehicle_id=${id}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data;
          setrecords(data.data)
          setResult(data.meta);
          dispatch({ type: "SET_SEARCH_STATE", search_list: false });
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        setErrorMsg(error)
        console.error("FETCH_ERROR");
        Helper.overlay(false);
      });
  }, [dispatch])

  const handleSearchPageChange = useCallback((pageNumber) => {
    Helper.overlay(true);
    apiRequest(ListSearchApi.method, ListSearchApi.url + `vehicles/all?name=${state.searchedName}&page=${pageNumber}`)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data;
          setrecords(data.data);
          setResult(data.meta);
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
        history.push(`${location.pathname}#error`);
      });

  }, [history, location.pathname, state.searchedName])

  function whiteSearch(e) {
    let val = e.target.value;

    apiRequest(SearchListAPI.method, SearchListAPI.url + `?search=${val}`)
      .then((response) => {
        if (response.data.code === 200) {
          setwhiterecords(response.data.data);

          Helper.overlay(false);
        } else {
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        Helper.overlay(false);
      });
  }

  useEffect(() => {
    //if(Helper.getPermissions('vehicle-read',permissions)){
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "app",
      wsHost: "dev.movitronic.com",
      wsPort: 6001,
      wssPort: 6001,
      forceTLS: true,
      useTLS: true,
      disableStats: true,
      encrypted: true,
      transports: ["websocket", "polling", "flashsocket"],
      authEndpoint: broadcastAuth.url,
      auth: { headers: { Authorization: "Bearer " + user.access_token } },
    });
    window.Echo.private(`movitronic`).listen(".heartbeat", (e) => {
      // console.log(e);
    });

    window.Echo.private(`movitronic`).listen(".usage", (e) => {
      // console.log(e);

      Helper.overlay(false);

      if (e.type === "trunk") {
        setTrunkLoader(null);
        Localdispatch({
          type: "FETCH_SUCCESS",
          payload: "Trunk opened",
        });
      } else {
        setDoorLoader(null);
        Localdispatch({
          type: "FETCH_SUCCESS",
          payload: "Vehicle status updated",
        });
      }
    });

    window.Echo.private(`movitronic`).listen(".fw_update_apply", (e) => { });

    // window.Echo.channel(`private-movitronic`).listen("fw_update_apply", (e) => {
    //   console.log("beforeEventttttttttttttttttttttttttttttt", e);
    //   if (e.data) {
    //     console.log("afterEvent", e);
    //     getVehicleList();
    //     setListenerEvent(e.data);
    //   } else {
    //     setListenerEvent(e.data);
    //     getVehicleList();
    //   }
    // });

    // console.log("pusherresultttttttttttttt", window.Echo);

    Helper.overlay(true);
  }, [user.access_token]);

  useEffect(() => {
    if (props.location.hash !== "#vehicleId" && props.location.hash !== "#search" && !state.searchedName) {
      dispatch({ type: "SET_STATE", response: [] });
      getVehicleList(state.set_active_page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.hash, state.searchedName, state.set_active_page]);

  useEffect(() => {
    if (location.pathname.includes('all-vehicle-list') && location.hash === "#search" && state.searchedName) {
      dispatch({ type: "SET_STATE", response: [] });
      handleSearchPageChange(state.set_active_page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.hash, state.searchedName, state.set_active_page])


  useEffect(() => {

    if (location.pathname.includes('all-vehicle-lis') && location.hash === "#vehicleId") {
      getVehicleById(id)
    }

    if (location.hash === "#error") {
      setrecords([]);
      setResult([]);
      //setShow(true);
      setErrorMsg("No record Found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, state.advanceSearch, state?.data, state.pagination]);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          let str = location.pathname;
          let id = str.substr(str.length - 1);
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          if (location.pathname.includes("all-vehicle-list")) {
            getVehicleList(+id);
          }
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          let str = location.pathname;
          let id = str.substr(str.length - 1);
          dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: +id });
          if (location.pathname.includes("all-vehicle-list")) {
            getVehicleList(+id);
          }
          setLocationKeys((keys) => [location.key, ...keys]);
        }
      }
    });
  }, [dispatch, getVehicleList, history, locationKeys, state.active_page]);

  const doorStatus = (e, carid, cmd) => {
    setDoorLoader({ id: carid, type: cmd });
    document.getElementById(e.target.id).disabled = true;
    var formData = new FormData();
    let command = cmd ? false : true;
    formData.append("cmd", command);
    formData.append("car_id", carid);
    Localdispatch({ type: "DEFAULT", payload: true });
    Helper.overlay(true);

    apiRequest(EventDoor.method, EventDoor.url, formData)
      .then((response) => {
        if (response.data.code === 200) {
          // getVehicleList(state.set_active_page.set_active_page.set_active_page);
          document.getElementById(e.target.id).disabled = false;
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          // Helper.overlay(false);
          //
          // setDoorLoader(null);
        } else if (response.data.code === 401) {
          document.getElementById(e.target.id).disabled = false;
        } else {
          document.getElementById(e.target.id).disabled = false;
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        //Localdispatch({ type: 'FETCH_ERROR', payload: error.response.data.data })
        //dispatch({type: 'SET_STATE', response:[]} );
        Helper.overlay(false);

        //alert(error.response.data.message);
        setShow(true);
        setErrorMsg(error.response.data.message);
      });
  };

  const trunkStatus = (e, carid, cmd) => {
    setTrunkLoader(carid);
    document.getElementById(e.target.id).disabled = true;
    var formData = new FormData();
    //let command = cmd ? false : true;
    let command = true;
    formData.append("cmd", command);
    formData.append("car_id", carid);
    Localdispatch({ type: "DEFAULT", payload: true });
    Helper.overlay(true);

    apiRequest(EventTrunk.method, EventTrunk.url, formData)
      .then((response) => {
        if (response.data.code === 200) {
          // console.log("Success", response.data.data);
          // setTrunkLoader(null);
          // getVehicleList(state.set_active_page.set_active_page.set_active_page);
          document.getElementById(e.target.id).disabled = false;
        } else if (response.data.code === 401) {
          document.getElementById(e.target.id).disabled = false;
          // console.log("Failure", response);
        } else {
          document.getElementById(e.target.id).disabled = false;
          // console.log("Failure", response);
        }
      })
      .catch((error) => {
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        //Localdispatch({ type: 'FETCH_ERROR', payload: error.response.data.data })
        //dispatch({type: 'SET_STATE', response:[]} );
        console.log("FETCH_ERROR");
        Helper.overlay(false);

        //alert(error.response.data.message);
        setShow(true);
        setErrorMsg(error.response.data.message);
      });
  };

  const handlePageChange = (pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    if (location.pathname.includes('all-vehicle-list') && location.hash === "#search") {
      history.push(`/admin/all-vehicle-list/${pageNumber}#search`);
    } else {
      history.push(`/admin/all-vehicle-list/${pageNumber}`);
    }
    // Helper.overlay(true);
    // apiRequest(
    //   AllVehicleListAPI.method,
    //   AllVehicleListAPI.url + `?page=${pageNumber}&sort_by=${sort_by}`
    // )
    //   .then((response) => {
    //     if (response.data.code === 200) {
    //       setrecords(response.data.data);
    //       setResult(response.data.meta);
    //       console.log("Success", response);
    //       Helper.overlay(false);
    //       history.push(`/admin/all-vehicle-list/${pageNumber}`);
    //     } else {
    //       Helper.overlay(false);
    //       console.log("Failure", response);
    //     }
    //   })
    //   .catch((error) => {
    //     Helper.overlay(false);
    //   });
  };

  const handlePageChangewhitelabel = (pageNumber) => {
    apiRequest(
      AddWhitelabelListing.method,
      AddWhitelabelListing.url + `?page=${pageNumber}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          setwhiterecords(response.data.data);
          //console.log("Success", response);
        } else {
          //console.log("Failure", response);
        }
      })
      .catch((error) => {
        //console.log("error", error);
        alert(error);
      });
  };
console.debug(records, "records")
  const deleteRecord = (e) => {
    //let params = new FormData();
    //params.append("id", e.target.dataset.id);
    // let delete_property = window.confirm(
    //   "Are you sure you want to delete this record!"
    // );
    // if (delete_property) {
    apiRequest(deleteVehicle.method, deleteVehicle.url + `${currentId}`)
      .then((response) => {
        if (response.data.code === 200) {
          let data = records.filter((d) => {
            return d.id !== +currentId;
          });
          setrecords(data);
          setRsModal(false);
          setResult(prev => ({
            ...prev,
            total: prev.total - 1
          }));
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
        setRsModal(false);
      });
    //}
  };

  // eslint-disable-next-line no-unused-vars
  const UpdateSoftware = (version_id, car_id) => {
    setIsSoftware(null);
    //let params = new FormData();
    //params.append("id", e.target.dataset.id);
    // let delete_property = window.confirm(
    //   "Are you sure you want to delete this record!"
    // );
    //if (delete_property) {
    //console.log("update function runungdddddd",e); return false;
    let params = new FormData();
    params.append("version", version_id);
    params.append("car_id", car_id);

    Helper.overlay(true);
    apiRequest(UpdateSoftwaree.method, UpdateSoftwaree.url, params)
      .then((response) => {
        let data = response.data;
        //console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaa",response);
        if (response.data.code === 200) {
          setIsSoftware(car_id);
          //setrecords(response.data.data);
          //window.location.reload();
          //getVehicleList(state.set_active_page.set_active_page.set_active_page)
          console.log("Success", response);
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          dispatch({ type: "SET_STATE", response: data, advanceSearch: true });
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        //console.log("error", error.response);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        //Localdispatch({ type: 'FETCH_ERROR', payload: error.response.data.data })
        //dispatch({type: 'SET_STATE', response:[]} );
        console.log("FETCH_ERROR");
        Helper.overlay(false);

        //alert(error.response.data.message);
        setShow(true);
        setErrorMsg(error.response.data.message);
      });

    //}
  };

  // eslint-disable-next-line no-unused-vars
  const AddWhiteLabelwithVehicle = (car_id) => {
    //setIsSoftware(null);
    let params = new FormData();
    params.append("vehicle_id", currentId);
    params.append("label_id", car_id);
    //Localdispatch({ type: 'DEFAULT', payload: true})

    Helper.overlay(true);
    apiRequest(
      Addwhitelabeltovechile.method,
      Addwhitelabeltovechile.url,
      params
    )
      .then((response) => {
        if (response.data.code === 200) {
          //setIsSoftware(car_id);
          toast.success(response.data.message);
          //getVehicleList(state.set_active_page.set_active_page.set_active_page)
          setWhitelabelModal(false);
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        //console.log("error", error.response);
        //alert(error.response.data.message);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        //Localdispatch({ type: 'FETCH_ERROR', payload: error.response.data.data })
        //dispatch({type: 'SET_STATE', response:[]} );
        console.log("FETCH_ERROR");
        Helper.overlay(false);

        //alert(error.response.data.message);
        //setShow(true);
        //setErrorMsg(error.response.data.data.label_id);
        toast.error(error.response.data.data.label_id);
      });

    //}
  };

  const UpdatehiteLabelwithVehicle = (car_id, vid) => {
    //console.log(vid,"sfsfsfsfsdfsdfsdfsd");
    //setIsSoftware(null);
    let params = new FormData();
    params.append("vehicle_id", vid);
    params.append("label_id", car_id);
    //Localdispatch({ type: 'DEFAULT', payload: true})

    Helper.overlay(true);
    apiRequest(
      Updatewhitelabeltovechile.method,
      Updatewhitelabeltovechile.url + vid,
      params
    )
      .then((response) => {
        if (response.data.code === 200) {
          //setIsSoftware(car_id);
          toast.success(response.data.message);
          getVehicleList(state.set_active_page);
          getWhiteLabelList();
          setWhitelabelModal(false);
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          Helper.overlay(false);
        }
      })
      .catch((error) => {
        //console.log("error", error.response);
        //alert(error.response.data.message);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error?.response?.data?.data,
        });
        //Localdispatch({ type: 'FETCH_ERROR', payload: error.response.data.data })
        //dispatch({type: 'SET_STATE', response:[]} );
        console.log("FETCH_ERROR");
        Helper.overlay(false);

        //alert(error.response.data.message);
        //setShow(true);
        //setErrorMsg(error.response.data.data.label_id);
        toast.error(error.response?.data?.message);
      });

    //}
  };

  function handleMainCheckBox(e) {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    Array.from(document.querySelectorAll("input[name='subcheckbox']")).forEach(
      (checkbox) => {
        checkbox.checked = value;
      }
    );
  }

  const handleSubmit = (values) => {
    // const form_data = Helper.formData(values);
    const form_data = new FormData();
    // form_data.append("hprice", values.hprice);
    // form_data.append("dprice", values.dprice);
    form_data.append("vehicle_id", currentId);
    form_data.append("label_id", currentLabelId);
    //form_data.append('contact_no', values.contact_no);
    //form_data.append('color', values.color);
    //form_data.append('file', values.file);

    Localdispatch({ type: "DEFAULT", payload: true });
    apiRequest(
      Sharewhitelabeltovechile.method,
      Sharewhitelabeltovechile.url,
      form_data
    )
      .then((response) => {
        if (response.data.code === 200) {
          Localdispatch({
            type: "FETCH_SUCCESS",
            payload: response.data.message,
          });
          setshareWhitelabelModal(false);
          setWhitelabelModal(false);
          getVehicleList(state.set_active_page.set_active_page);

          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        Localdispatch({
          type: "FETCH_ERROR",
          payload: error.response.data.data,
        });
        toast.error(error.response.data.message);
        window.scrollTo(500, 0);
      });
  };

  function getcheckbox() {
    var array = [];
    var checkboxes = document.querySelectorAll(
      "input[name='subcheckbox']:checked"
    );
    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
      checkboxes[i].checked = false;
    }

    let params = new FormData();
    params.append("ids", array);
    let delete_property = window.confirm(
      "Are you sure you want to delete these records!"
    );
    if (delete_property) {
      apiRequest(alldeleteVechile.method, alldeleteVechile.url, params)
        .then((response) => {
          if (response.data.code === 200) {
            let data = records;
            array.forEach((el) => {
              data = data.filter((d) => {
                return d.detail.id !== el;
              });
            });
            setrecords(data);
            setRsModal(false);
          } else {
            console.log("Failure", response);
          }
        })
        .catch((error) => {
          //console.log("error", error);
          alert(error);
        });
    }
  }
  // const ShowList = () => {
  //   if (AdvanceSearchShow === true) {
  //     setAdvanceSearchShow(false);
  //     getVehicleList(state.set_active_page);
  //   }
  // };

  const setDropDown = (e) => {
    setSortBy(e.target.value);
    getVehicleList(state.set_active_page, e.target.value);
  };

  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={show} onHide={handleClose}>
        
        <Modal.Body> <h4 className="text-center">{errorMsg}</h4>
        <Button variant="secondary" className="float-right" onClick={handleClose}>Close</Button>
        </Modal.Body>
      </Modal> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{errorMsg}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop="static"
        show={whitelabelModal}
        onHide={whitelabelModalclose}
        size="xs"
      >
        <Modal.Header>
          <Modal.Title className="pb-3">Assign to</Modal.Title>
          <input
            type="search"
            className="form-control"
            onChange={whiteSearch}
            placeholder="Search.."
          />
        </Modal.Header>
        <Modal.Body className=" ">
          {currentLabelId && (
            <div
              className="d-flex media-list big px-3 mt-3 justify-content-between align-items-center"
              onClick={() =>
                UpdatehiteLabelwithVehicle(currentLabelId, currentId)
              }
            >
              <div>
                <div className="media">
                  <i
                    className="fa fa-question-circle  my-1 fa-3x"
                    aria-hidden="true"
                  ></i>
                  <div className="media-body align-self-center">
                    <h5 className="mt-0 ml-2 fs"> Unassgin</h5>
                  </div>
                </div>
              </div>
            </div>
          )}

          {whiterecords.length ? (
            whiterecords.map((record, index) => (
              <label className="big">
                <div
                  key={index}
                  htmlFor={`radio${record.id}`}
                  className="d-flex media-list big px-3 mt-3 justify-content-between align-items-center"
                >
                  <div>
                    <div className="media">
                      <img
                        src={record.file}
                        className="mr-2 user-image my-1"
                        alt="img"
                      />
                      <div className="media-body align-self-center">
                        <strong className="mt-0 fs">{record.name}</strong>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input
                        type="radio"
                        defaultChecked={currentId === record.vehicle_id && true}
                        checked={currentLabelId === record.id && true}
                        className="form-check-input"
                        name="radios"
                        id={`radio${record.id}`}
                        onChange={() => handleLabel(record.id)}
                      />
                    </div>
                  </div>
                </div>
              </label>
            ))
          ) : (
            <div className="text-center ft-14 mt-3 font-weight-bold">
              No Data Found...
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="row mt-3  ft-14">
            <div className="col-md-12">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  {whiterecords.length > 0 && (
                    <Pagination
                      activePage={state.set_active_page}
                      itemsCountPerPage={wresult.to}
                      totalItemsCount={wresult.total ? wresult.total : 1}
                      pageRangeDisplayed={wresult.last_page}
                      itemClass="page-item"
                      linkClass="page-link"
                      onChange={handlePageChangewhitelabel}
                      activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                    />
                  )}
                </ul>
              </nav>
            </div>
          </div>
          <Button onClick={whitelabelModalclose} appearance="subtle">
            <b className="text-primary">Cancel</b>
          </Button>

          <button
            className=" btn btn-successs  ml-1"
            disabled={!currentLabelId && true}
            data-id={currentId}
            onClick={handleSubmit}
          >
            Assign
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop="static"
        show={sharewhitelabelModal}
        onHide={sharewhitelabelModalclose}
        size="lg"
      >
        <Modal.Body>
          <div className="p-4">
            <h4 className="mb-3">Share WhiteLabel</h4>
            <Formik
              validateOnChange={false}
              validationSchema={DetailSchema}
              onSubmit={async (values) => {
                handleSubmit(values);
              }}
              initialValues={{
                hprice: "",
                dprice: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                isSubmitting,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form className="mt-2 user">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label className="form-label">H Price</label>
                      <input
                        type="text"
                        name="hprice"
                        value={values.hprice || ""}
                        onChange={handleChange}
                        placeholder="Enter H Price"
                        className={`form-control form-control-user ${errors.hprice ? "error" : ""
                          }`}
                      />
                      {errors.hprice && (
                        <div className="ft-14 mt-1 red">{errors.hprice}</div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">D Price</label>
                      <input
                        type="text"
                        name="dprice"
                        value={values.dprice || ""}
                        onChange={handleChange}
                        placeholder="Enter Your D Price"
                        className={`form-control form-control-user ${errors.dprice ? "error" : ""
                          }`}
                      />
                      {errors.dprice && (
                        <div className="ft-14 mt-1 red">{errors.dprice}</div>
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group d-flex justify-content-end col-md-12">
                      <button
                        type="submit"
                        className="btn-submit btn-user"
                        disabled={LocalState.disable}
                      >
                        confirm Details
                        {LocalState.disable ? (
                          <span
                            className="ml-2 btn-user spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            {/* <div className="row">
                <div className="col-md-12 mb-4">
                  Usages: <u>20</u>
                </div>
                <div className="col-md-12">
                  Drivers behaviour score: <u>4.5</u>
                </div>
              </div> */}
          </div>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={sharewhitelabelModalclose} appearance="subtle">
            <b className="text-danger">Cancel</b>
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          {" "}
          <h4 className="text-center">{errorMsg}</h4>
          <Button
            variant="secondary"
            className="float-right"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal> */}
      {/* <div className="wrapper d-flex align-items-stretch">
        <Sidebar /> */}
      <div id="content">
        <Header showSearch={true} showAdvanceSearch={false} />
        {Helper.getPermissions(
          "vehicle-read",
          permissions && permissions,
          "admin"
        ) || Helper.getPermissions("", [], user.type) ? (
          <div className="p-4 user">
            <div className="row border-bottom">
              <div className="col-md-4 p-2 text-center font-weight-bold">
                {/* <NavLink className="black" exact to={`/admin/vehicle-list`}>
                Show My List
                </NavLink>
                {` | `} */}
                {/* { (Helper.getPermissions(
                  "vehicle-read",
                  permissions && permissions,
                  "admin"
                )  ||
                  Helper.getPermissions("", [], user.type)) && (
                <NavLink className="black" exact to={`/admin/all-vehicle-list`}>
                  Show All List
                </NavLink>
                  )}
                {` | `}
                <NavLink className="black" exact to={`/admin/vehicle-map-list`}>
                  Show on Map
                </NavLink>
                {` | `}
                <NavLink className="black" exact to={`/admin/vehicle-map-all-list`}>
                  Show All on Map
                </NavLink> */}
              </div>

              <div className="col-md-4">
                <div className="d-flex flex-row mb-3">
                  <div className="pl-4 pt-1">
                    {/* <form className="form-inline">
                      <label className="my-1 mr-2">Sort:</label>
                      <select
                        className="form-control p-0"
                        style={{ height: "30px" }}
                        defaultValue={"Last seen online first"}
                      >
                        <option value="Last seen online first">
                          Last seen online first
                        </option>
                        <option value="Last used first">Last used first</option>
                        <option value="Error">Error</option>
                        <option value="Olderst firmware first">
                          Olderst firmware first
                        </option>
                      </select>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 mb-2 border">
                    {AdvanceSearchShow && (
                      <AdvanceSearch
                        AdvanceVehicleSearch={AdvanceVehicleSearch}
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="row col-12">
                    <div className="col-lg-8 mt-2">
                      <div className="d-flex flex-row mb-3">
                        <div className="p-2 ">
                          <CheckBox onChange={handleMainCheckBox} />
                        </div>
                        <div className="p-2">
                          {(Helper.getPermissions(
                            "vehicle-delete",
                            permissions && permissions,
                            "admin"
                          ) ||
                            Helper.getPermissions("", [], user.type)) && (
                              <button
                                className="btn btnn-user  btn-primary"
                                onClick={getcheckbox}
                              >
                                Bulk Delete
                              </button>
                            )}
                        </div>
                        <div className="p-2">
                          <button
                            className="btn btnn-user  btn-primary"
                            onClick={() =>
                              setAdvanceSearchShow((prev) => !prev)
                            }
                          >
                            Advanced Search
                          </button>
                        </div>
                        <div className="p-2">
                          {(Helper.getPermissions(
                            "vehicle-create",
                            permissions && permissions,
                            "admin"
                          ) ||
                            Helper.getPermissions("", [], user.type)) && (
                              <NavLink
                                exact
                                to={`/admin/add-vehicle`}
                                className="btn btnn-user white bg-orange" style={{ backgroundColor: user.background_color }}
                              >
                                New Vehicle
                              </NavLink>
                            )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-4 ml-5">
                      <span
                          className="p-2 black font-weight-bold"
                          style={{cursor:"pointer"}}
                          onClick={ShowList }
                        >
                        SHOW LIST  
                        </span>
                        |
                        <NavLink
                          className="p-2 black font-weight-bold"
                          to=""
                          exact
                        >
                        SHOW ON MAP  
                        </NavLink>
                                </div> */}
                    <div className="col-lg-4 d-flex align-items-center justify-content-center">
                      <div className="pb-2 pl-3">Results: {result?.total}</div>
                      <div className="pb-2 pl-3 row">
                        <label className="col-4 mt-auto">Sort By:</label>
                        <select
                          className="form-select form-control col-8"
                          aria-label="Default select example"
                          name="sort_by"
                          onChange={setDropDown}
                        >
                          <option
                            selected={sort_by === 1 ? true : false}
                            value={1}
                          >
                            Last used on top
                          </option>
                          <option
                            selected={sort_by === 2 ? true : false}
                            value={2}
                          >
                            Last online on top
                          </option>
                          <option
                            selected={sort_by === 3 ? true : false}
                            value={3}
                          >
                            Last created on top
                          </option>
                          <option
                            selected={sort_by === 4 ? true : false}
                            value={4}
                          >
                            First created on top
                          </option>
                          <option
                            selected={sort_by === 5 ? true : false}
                            value={5}
                          >
                            Alphabetically A-Z
                          </option>
                          <option
                            selected={sort_by === 6 ? true : false}
                            value={6}
                          >
                            Alphabetically Z-A
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  {records.length ? (
                    records.map((record, index) => {
                      return (
                        <div
                          className="col-md-12 mt-3 user border-bottom pb-3 ft-14 flex card card-shadow card shadow bg-white rounded"
                          key={index}
                        >
                          <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 mt-2 white-space-nowrap sm-center">
                              <h6 className="card-title text-uppercase">
                                <div className="">
                                  <input
                                    type="checkbox"
                                    className=""
                                    name="subcheckbox"
                                    defaultValue={record.detail.id}
                                    id="exampleCheck1"
                                  />
                                  {(Helper.getPermissions(
                                    "vehicle-update",
                                    permissions && permissions,
                                    "admin"
                                  ) ||
                                    Helper.getPermissions(
                                      "",
                                      [],
                                      user.type
                                    )) ? (
                                    <NavLink
                                      className="ml-2 form-check-label font-weight-bold text-wrap"
                                      exact
                                      to={`/admin/edit-vehicle-details/${record.id}`}
                                    >
                                      {record.name}
                                    </NavLink>
                                  ) : (
                                    <span className="ml-2 form-check-label font-weight-bold text-wrap">{record.name}</span>
                                  )}

                                </div>
                              </h6>
                              <img
                                src={record.file}
                                className="card-img-top img-fluid"
                                alt="..."
                                width="70"
                                height="70"
                              />
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-2 mt-2 white-space-nowrap">
                              <div className="d-flex justify-content-between">
                                Tags: {record.tags}
                              </div>

                              <div className="d-flex">
                                Status:{" "}
                                {record.status ? record.status : "Sleep mode"}
                              </div>

                              <div className="">
                                Operator:{" "}
                                <span className="font-weight-bold">
                                  {user.type === "whitelabel" ? <> {" "}
                                    <strong>
                                      {record.operator}
                                    </strong></> :
                                    <NavLink
                                      exact
                                      to={`/admin/current-user-list/${record.operator}/#userId`}
                                    >
                                      {" "}
                                      <strong>
                                        {" "}
                                        <u>
                                          {" "}
                                          {record.operator}{" "}
                                        </u>{" "}
                                      </strong>
                                    </NavLink>
                                  }
                                </span>
                              </div>
                              {/* <div className="">
                                Hardware:{" "}
                                <strong>
                                  {" "}
                                  {record.hardware_version
                                    ? record.hardware_version
                                    : "-"}
                                </strong>
                              </div> */}
                              <div className="">
                                Firmware:{" "}
                                <strong>
                                  {" "}
                                  {record.software_version
                                    ? record.software_version
                                    : "-"}{" "}
                                </strong>
                              </div>
                              {/* <div className="">
                                Device Code:{" "}
                                {record.device_id && record?.detail?.device_code ? (
                                  user.type === "whitelabel" ? <>
                                    <strong>
                                      {record?.detail?.device_code}
                                    </strong>
                                  </> :
                                    <NavLink
                                      exact
                                      to={`/admin/edit-device-code/${record.device_id}`}
                                    >
                                      {" "}
                                      <strong>
                                        {" "}
                                        <u>
                                          {" "}
                                          {record?.detail?.device_code}{" "}
                                        </u>{" "}
                                      </strong>
                                    </NavLink>
                                ) : (
                                  "-"
                                )}
                              </div> */}
                              {/* <div className="d-flex">
                                Shared:{" "}
                                <span>
                                  <input
                                    type="checkbox"
                                    disabled
                                    className="ml-2"
                                    id="shared"
                                    defaultChecked={record.share}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="shared"
                                  ></label>
                                </span>
                              </div> */}
                              <div className="d-flex justify-content-between">
                                Color: {record.colour}
                              </div>
                              <div className="">
                                Current user:{" "}
                                <span className="font-weight-bold">
                                  {record.usages.current_user.name ? (
                                    user.type === "whitelabel" ? <><strong>
                                      {record.usages.current_user.name}
                                    </strong></> :
                                      <NavLink
                                        exact
                                        to={`/admin/user-list/${record?.usages?.current_user?.id}/#userId`}
                                      >
                                        {" "}
                                        <strong>
                                          {" "}
                                          <u>
                                            {" "}
                                            {record.usages.current_user.name}{" "}
                                          </u>{" "}
                                        </strong>
                                      </NavLink>
                                  ) : (
                                    "-"
                                  )}
                                </span>
                              </div>
                              <div className="">
                                Fuel level:{" "}
                                <strong>
                                  {record.detail.fuel != null
                                    ? record.detail.fuel + "%"
                                    : "-"}
                                </strong>
                              </div>

                              <div className="">
                                Central lock:{" "}
                                <strong>
                                  <span className={`${record.door_button ? "text-success" : "text-danger"}`}>
                                    {record.door_button ? "Open" : "Closed"}
                                  </span>
                                </strong>
                              </div>
                            </div>
                            <hr className="d-xs-block d-md-none full-width"></hr>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-2 mt-2 white-space-nowrap">

                              <div className="">
                                Active shares:{" "}
                                <span className="font-weight-bold">
                                  {user.type === "whitelabel" ? <>
                                    {" "}
                                    <strong>
                                      {record?.active_shares}
                                    </strong>
                                  </> :
                                    <NavLink
                                      exact
                                      to={`/admin/shares-my-list/${record.id}/#active_share`}
                                    >
                                      {" "}
                                      <strong>
                                        {" "}
                                        <u>
                                          {" "}
                                          {record?.active_shares}{" "}
                                        </u>{" "}
                                      </strong>
                                    </NavLink>
                                  }
                                </span>
                              </div>
                              <div className="">
                                Vehicle Battery:{" "}
                                <span className="font-weight-bold">
                                  {record?.detail.supply_voltage
                                    ? record?.detail.supply_voltage + 'V'
                                    : "-"}
                                </span>
                              </div>
                              <div className="">
                                Internal battery:{" "}
                                <span className="font-weight-bold">
                                  {record?.detail.battery_voltage
                                    ? record?.detail.battery_voltage + 'V'
                                    : "-"}
                                </span>
                              </div>
                              <div className="">
                                Engine:{" "}
                                <strong>
                                  {" "}
                                  {record.engine != null ? record.engine : "-"} RPM
                                </strong>
                              </div>
                              <div className="">
                                Year: <strong> {record.year}</strong>
                              </div>
                              <div className="">
                               Registration plate:{" "}
                                <strong>
                                {record.license}
                                </strong>
                              </div>
                              <div className="">
                                Car Make:
                                {user.type === "whitelabel" ? <>
                                  {" "}
                                  <strong>
                                    {record.vehicle_company_name}
                                  </strong>
                                </> :
                                  <NavLink
                                    exact
                                    to={`/admin/company-vehicle-list/${record.vehicle_company_name}/#company_name`}
                                  >
                                    {" "}
                                    <strong>
                                      {" "}
                                      <u>
                                        {" "}
                                        {record.vehicle_company_name}{" "}
                                      </u>{" "}
                                    </strong>
                                  </NavLink>
                                }
                              </div>
                            </div>
                            <hr className="d-sm-block d-md-none full-width"></hr>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 mt-2 white-space-nowrap">
                              <div className="">
                                Location:{" "}
                                <span className="text-wrap font-weight-bold ">
                                  {record.location ? (
                                    <u> {record.location} </u>
                                  ) : (
                                    "-"
                                  )}
                                  <NavLink
                                    exact
                                    to={`/admin/single-vehicle-map-list/${record.id}`}
                                  >
                                    <i
                                      className="fa fa-map-marker-alt pl-1"
                                      style={{ color: "green" }}
                                    ></i>
                                  </NavLink>
                                </span>
                              </div>
                              <div className="">
                                Last online:{" "}
                                <span className="text-wrap">
                                  {" "}
                                  {record?.last_online
                                    ? record.last_online
                                    : "-"}{" "}
                                </span>
                              </div>
                              <div className="">
                                Last usage:{" "}
                                <span className="font-weight-bold text-wrap">
                                  {Helper.getDateByTZ(record.last_usage)} ({user?.timezone ? user.timezone : Helper.localTimezone()})
                                </span>
                              </div>
                              {/* <div className="">
                                Previous usage:{" "}
                                <span className="font-weight-bold text-wrap">
                                  {record.usages.previos_usage.created_at ? (
                                    <u>
                                      {Helper.getDateByTZ(record.usages.previos_usage.created_at)} ({user?.timezone ? user.timezone : Helper.localTimezone()})
                                    </u>
                                  ) : (
                                    "-"
                                  )}
                                </span>
                              </div> */}
                              <div className="">
                                Current usage:{" "}
                                <span className="font-weight-bold text-wrap">
                                  {record.usages.current_usage.created_at ? (
                                    <u>
                                      {Helper.getDateByTZ(record.usages.current_usage.created_at)}  ({user?.timezone ? user.timezone : Helper.localTimezone()})
                                    </u>
                                  ) : (
                                    "-"
                                  )}
                                </span>
                              </div>

                              {record.status !== "online" &&
                                record.status !== "charged" &&
                                record.status !== "pluggedin" ? (
                                <>
                                  <div className="d-flex white-space-nowrap">
                                    Car alarms: {" "}
                                    {Object.keys(record.alarms.car).length > 0 && record.alarms.car ? <>
                                      <span className="text-danger pl-1 pr-1">
                                        {record.alarms.car.type}
                                      </span> {" "}
                                      {/* <span className=" text-danger pr-1">
                                        {" "} (
                                        {isoToDate(
                                          record?.alarms?.car?.updated_at
                                        )}
                                        )
                                      </span> {" "} */}
                                      <span className="text-danger"> ({record.alarms.car.priority} priority)</span>
                                    </> : <>-</>
                                    }
                                  </div>
                                  <div className="">
                                    Device alarms:{" "}
                                    <span className="text-danger text-wrap">
                                      {Object.keys(record.alarms.device).length > 0
                                        ? <>
                                          <span className="text-danger pl-1 pr-1">
                                            {record.alarms.device.type}
                                          </span> {" "}
                                          <span className="text-danger"> ({record.alarms.device.priority} priority)</span>
                                        </>
                                        : "-"}
                                    </span>
                                  </div>
                                </>
                              ) : (
                                " "
                              )}
                              <div className="">
                                Odometer:{" "}
                                <span className="text-danger text-wrap">
                                  {(record.odometer !== '0' && record.odometer !== null)
                                    ? record.odometer
                                    : "-"}
                                </span>

                              </div>
                              <div className="col-md-6 ml-n3">
                                {Helper.getPermissions(
                                  "label-create",
                                  permissions && permissions,
                                  "admin"
                                ) ||
                                  (user.type === "superadmin" && (
                                    <>
                                      {Object.keys(record.label).length !==
                                        0 && (
                                          <div>
                                            <div className="media d-flex align-items-center">
                                              <img
                                                src={record.label.file}
                                                className="mr-2 user-image"
                                                width="20px"
                                                data-label={record.label.id}
                                                data-id={record.id}
                                                onClick={whitelabelModalopen}
                                                alt="img"
                                              />
                                              <strong
                                                className="badge label-assg badge-success mt-2"
                                                data-label={record.label.id}
                                                data-id={record.id}
                                                onClick={whitelabelModalopen}
                                              >
                                                Assigned:{record.label.name}
                                              </strong>
                                            </div>
                                          </div>
                                        )}
                                    </>
                                  ))}
                              </div>

                              <div className="d-flex">
                                {Object.keys(record.label).length === 0 && (
                                  <div>
                                    <div className="media d-flex align-items-center">
                                      <strong
                                        className="badge label-assg badge-danger "
                                        data-id={record.id}
                                        data-label={null}
                                        onClick={whitelabelModalopen}
                                      >
                                        {" "}
                                        Unassigned
                                      </strong>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <hr className="d-sm-block d-md-none full-width"></hr>
                            <div className="ol-xs-12 col-sm-12 col-md-12 col-lg-3 mt-2 white-space-nowrap">
                              <div className="card-body row pr-0 pt-3">
                                <div className="col-md-12 col-lg-12 d-flex justify-content-end pt-2">
                                  <button
                                    id={`door${record.id}`}
                                    disabled={
                                      doorLoader && record.id === doorLoader.id
                                        ? true
                                        : false
                                    }
                                    className={`w-100 btn-danger`}
                                    data-id={record.id}
                                    onClick={(e) => doorStatus(e, record.id, 1)}
                                  >
                                    {"Lock Doors"}
                                    {doorLoader &&
                                      record.id === doorLoader.id &&
                                      doorLoader.type === 1 ? (
                                      <span
                                        className="ml-2 spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ) : (
                                      ""
                                    )}
                                  </button>{" "}
                                </div>
                                <div className="col-md-12 col-lg-12 d-flex justify-content-end pt-2">
                                  <button
                                    id={`door${record.id}`}
                                    disabled={
                                      doorLoader && record.id === doorLoader.id
                                        ? true
                                        : false
                                    }
                                    className={`w-100 btn-success`}
                                    data-id={record.id}
                                    onClick={(e) => doorStatus(e, record.id, 0)}
                                  >
                                    {"Unlock Doors"}
                                    {doorLoader &&
                                      record.id === doorLoader.id &&
                                      doorLoader.type === 0 ? (
                                      <span
                                        className="ml-2 spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ) : (
                                      ""
                                    )}
                                  </button>{" "}
                                </div>
                                <div className="col-md-12 col-lg-12 d-flex justify-content-end pt-2">
                                  <button
                                    id={`trunk${record.id}`}
                                    disabled={
                                      record.id === trunkLoader ? true : false
                                    }
                                    className={`w-100 btn-success`}
                                    data-id={record.id}
                                    onClick={(e) =>
                                      trunkStatus(
                                        e,
                                        record.id,
                                        record.trunk_button
                                      )
                                    }
                                  >
                                    {`Open Trunk`}
                                    {record.id === trunkLoader ? (
                                      <span
                                        className="ml-2 spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ) : (
                                      ""
                                    )}
                                  </button>
                                </div>
                                <div className="col-md-12 col-lg-12 d-flex justify-content-end pt-2">
                                  {(Helper.getPermissions(
                                    "vehicle-update",
                                    permissions && permissions,
                                    "admin"
                                  ) ||
                                    Helper.getPermissions(
                                      "",
                                      [],
                                      user.type
                                    )) && (
                                      <NavLink
                                        className="w-100 btn-info d-flex justify-content-center"
                                        exact
                                        to={`/admin/edit-vehicle-details/${record.id}`}
                                      >
                                        {" "}
                                        Edit
                                      </NavLink>
                                    )}
                                </div>
                                <div className="col-md-12 col-lg-12 d-flex justify-content-center pt-2">
                                  {(Helper.getPermissions(
                                    "vehicle-delete",
                                    permissions && permissions,
                                    "admin"
                                  ) ||
                                    Helper.getPermissions(
                                      "",
                                      [],
                                      user.type
                                    )) && (
                                      <button
                                        className="btn-danger w-100 w-md-50"
                                        data-id={record.id}
                                        onClick={Modalopen}
                                      >
                                        Delete
                                      </button>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center ft-14 mt-3 font-weight-bold">
                      No Data Found...
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-3 ft-14">
              <div className="col-md-12">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    {records.length > 0 && (
                      <Pagination
                        activePage={state.set_active_page}
                        totalItemsCount={result.total ? result.total : 1}
                        pageRangeDisplayed={result.last_page}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handlePageChange}
                        activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                      />
                    )}
                    <li className="pl-3" key={4}>
                      {/* <form className="form-inline">
                        <label className="my-1 mr-2">Results on page:</label>
                        <select
                          className="form-control p-0"
                          style={{ height: "30px" }}
                          defaultValue={"10"}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </form> */}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-4">
            <div className="text-center ft-14 mt-3 font-weight-bold">
              You are not allowed to visit this screen...
            </div>
          </div>
        )}
      </div>
      {/* </div> */}

      <ToastContainer />
    </React.Fragment>
  );
}
export default AllVehicleList;
