import React, { useCallback, useState, useEffect,useRef  } from "react";

var map;
let polyline;
function StationMap(props) {
    const [isLoaded, setLoaded] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [mapState, setMapState] = useState(false);
//   const [mapState, setMapState] = useState({ isLoaded: false, mapLoaded: false });

    const pacInputRef = useRef(null);
    
    var markers = [];
    var line = [];
    var service;
    let search = props.search;
    let systemData =   props.sdata;
   var coordinates = [];
   
    if (systemData) {
        if (systemData.length > 0 && isLoaded === false && mapLoaded === true) {
            setLoaded(true)
            console.log("k");
           
            
            //  new window.google.maps.event.addListener(map, 'click', function(event) {
            //     addMarker(event.latLng);
            //     console.log(event.latLng)
            // });
            
        }
    }
   
    // let m = [{lat:40.72587107327272,lng:-73.9622195071485},{lat:40.727226245152764,lng:-74.03823852539062}]
    
    if(search !== '' && search.length > 5) {
        const request = {
        query: search,
        fields: ["name", "geometry"],
        };

        // map = new window.google.maps.Map(document.getElementById("map"), {
        //     center: { lat: 40.7128, lng: -74.0060 }, // Set the initial center (e.g., New York City)
        //     zoom: 12, 
        // });

        if (typeof window.google.maps.places !== "undefined") {
        //     //props.gcords([]);
            

            service =  new window.google.maps.places.PlacesService(map);
            service.findPlaceFromQuery(request, (results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
                    map.setCenter(results[0].geometry.location);
                }
            });
        }
    }
    //new google.maps.LatLng( -34.397,150.644),

    
    const checkUpdate = useCallback(() => {
        setMapLoaded(true);
        console.log(mapLoaded);
        if(mapLoaded){
            initMap();
            props.mapIsLoaded();
        }
    },[mapLoaded, props])

    useEffect(() => {
        const timeoutId = setTimeout(checkUpdate, 1000);
        return () => clearTimeout(timeoutId);
     }, [checkUpdate])

    const initMap = () => {

        if(mapState){
            return;
        }
        if(mapLoaded){
            setMapState(true);
            console.log(document.getElementById("map"));

            if (systemData.length > 0){
                map = new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: systemData[0].lat, lng: systemData[0].long }, // Set the initial center (e.g., New York City)
                zoom: 15, 
                });

                systemData.forEach((element, index, array) => {
                    console.log(element.lat); 
                    console.log(element.long); 

                    const marker = new window.google.maps.LatLng( element.lat,element.long);
                    
                    addMarker(marker);
                });
            }
            else{
                map = new window.google.maps.Map(document.getElementById("map"), {
                    center: { lat: 40.7128, lng: -74.0060 }, // Set the initial center (e.g., New York City)
                    zoom: 15, 
                });
            }
           
            const input = document.getElementById("pac-input");
            const searchBox =new window.google.maps.places.SearchBox(pacInputRef.current);

            map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(pacInputRef.current);
            // Bias the SearchBox results towards current map's viewport.
            map.addListener("bounds_changed", () => {
                searchBox.setBounds(map.getBounds());
            });

            searchBox.addListener("places_changed", () => {
                const places = searchBox.getPlaces();

                if (places.length === 0) {
                    return;
                }

                

                // For each place, get the icon, name and location.
                const bounds = new window.google.maps.LatLngBounds();

                places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry");
                    return;
                }

     
                if (place.geometry.viewport) {
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
                });

                map.fitBounds(bounds);
            });
        

            new window.google.maps.event.addListener(map, 'click', function(event) {
                addMarker(event.latLng);
                console.log(event.latLng)
            });

            document
                .getElementById("delete-markers")
                .addEventListener("click", resetMap);
        }
        
        
        
    };

    function setMapOnAll(map) {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
        }

        for (let i=0; i<line.length; i++) 
        {                              
            line[i].setMap(map); //or line[i].setVisible(false);
        }
    }
    function resetMap(){
        setMapOnAll(null);
        markers = [];
        props.gcords([]);
        coordinates =[];
    }
    function addMarker(location) {
       
        const marker = new window.google.maps.Marker({
            position: location,
            map: map,
            title: 'Marker',
            icon: '/assets/pin-16.png',
        });

        markers.push(marker);
        var lat  = marker.getPosition().lat();
        var lng = marker.getPosition().lng();
        let temp = { "lat": lat, "long": lng };

        coordinates.push(temp);

        props.gcords(coordinates);
        drawDottedLine();
    }

    function drawDottedLine() {
        const lineSymbol = {
            path: 'M 0,-1 0,1',
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
        };

        polyline = new window.google.maps.Polyline({
            path: markers.map(marker => marker.getPosition()),
            icons: [
                {
                    icon: lineSymbol,
                    offset: '0',
                    repeat: '20px',
                },
            ],
            map: map,
        });

        line.push(polyline);
    }
  return (
   
      <div id="content">

        <div className="p-4">
            <div className="row">
                <div className="col-md-6">
                    <input id="delete-markers" type="button" value="Delete Markers" />
                </div>
                <div className="col-md-6">
                 <input
                        ref={pacInputRef}
                        id="pac-input"
                        className={`controls`}
                        type="text"
                        placeholder="Search Box"
                        />
                
                </div>
            </div>
          <div className="row">
            <div className="col-md-12">
                 {mapLoaded && 
                    <div id="map" style={{ width: "100%", height: "100vh" }}></div>
                 }
            </div>
          </div>
        </div>
      </div>
  );
}
export default StationMap;