import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Store from "./Store/Store";
import PublicRoutes from "./Routes/PublicRoutes.jsx";
import PrivateRoutes from "./Routes/PrivateRoutes.jsx";
import "rsuite/dist/styles/rsuite-default.css";
import { broadcastAuth,userActivity } from "./Api/ApiRoutes";
import Echo from "laravel-echo";
import Helper from "./Helper";
const _ = require("lodash");
var { apiRequest } = require("./Api/Service");

function App() {
  const isAuth = !_.isEmpty(localStorage.getItem("SESSION")) ? true : false;
  useEffect(() => {
    if (isAuth) {
      var user = Helper.getStorageData("SESSION");
      window.Echo = new Echo({
        broadcaster: process.env.REACT_APP_PUSHER_BROADCASTER,
        key: process.env.REACT_APP_PUSHER_KEY,
        wsHost: process.env.REACT_APP_PUSHER_BASE_URL,
        wsPort: process.env.REACT_APP_PUSHER_PORT,
        wssPort: process.env.REACT_APP_PUSHER_PORT,
        forceTLS: process.env.REACT_APP_PUSHER_FORCE_TLS,
        useTLS: process.env.REACT_APP_PUSHER_USE_TLS,
        disableStats: process.env.REACT_APP_PUSHER_DISABLE_STATS,
        encrypted: process.env.REACT_APP_PUSHER_ENCRYPTED,
        transports: ["websocket", "polling", "flashsocket"],
        authEndpoint: broadcastAuth.url,
        auth: { headers: { Authorization: "Bearer " + user.access_token } },
      });
    }
  }, [isAuth]);

    function idleLogout() {
      var t;
      window.onload = resetTimer;
      window.onmousemove = resetTimer;
      window.onmousedown = resetTimer;    // catches touchscreen presses as well
      window.ontouchstart = resetTimer;   // catches touchscreen swipes as well
      window.ontouchmove = resetTimer;    // required by some devices
      window.onclick = resetTimer;        // catches touchpad clicks as well
      window.onkeydown = resetTimer;
      window.addEventListener('scroll', resetTimer, true); // improved; see comments

      function logouUser() {
        var user = Helper.getStorageData("SESSION");
        if(user !== undefined){
            apiRequest(userActivity.method, userActivity.url)
            .then((response) => {
            if (response.data.code === 200) {
              localStorage.removeItem("SESSION");
              window.location.href = "/";
            }
          })
          .catch((error) => {

          });
        }
        else{
          console.log("user not loggedin")
        }
          
      }

      function resetTimer() {
          clearTimeout(t);
          t = setTimeout(logouUser, 7200000);  // 7200000 time is in milliseconds
      }
    }

    idleLogout();
  return (
    <Store>
      <Router>
        {!isAuth && <PublicRoutes isAuth={isAuth} />}
        {isAuth && <PrivateRoutes isAuth={isAuth} />}
      </Router>
    </Store>
  );
}

export default App;
