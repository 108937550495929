import React, { useEffect, useCallback } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import Header from "../Header/Index.js";
import { Modal, Button, Icon } from "rsuite";
import Pagination from "react-js-pagination";
import Helper from "../Helper";
import { Context } from "../Store/Store";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
var { GetAllContract, DeleteContract, ListSearchApi } = require("../Api/ApiRoutes");
var { apiRequest } = require("../Api/Service");
const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                ...state,
                isSuccess: true,
                success: action.payload,
                disable: false,
            };
        case "FETCH_ERROR":
            return {
                ...state,
                isError: true,
                errors: action.payload,
                disable: false,
            };
        case "DEFAULT":
            return {
                disable: action.payload,
            };
        default:
            return state;
    }
};



const initialState = {
    isError: false,
    errors: [],
    isSuccess: false,
    success: "",
    disable: false,
};

function ContractLising() {
    const [records, setrecords] = React.useState([]);
    const [result, setResult] = React.useState({});
    const [show, setShow] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [rsModal, setRsModal] = React.useState(false);
    const [hidePagination, sethidePagination] = React.useState(false);
    const [currentId, setCurrentId] = React.useState(null);
    const [state, dispatch] = React.useContext(Context);
    const history = useHistory();
    const [sort_by, setSortBy] = React.useState(1);

    const Modalclose = () => {
        setRsModal(false);
        setShow(false);
    };
    const Modalopen = (e) => {
        setCurrentId(e.target.dataset.id);
        setRsModal(true);
    };
    var user = Helper.getStorageData("SESSION");
    var permissions = user.permissions;
    var location = useLocation();

    const getContractListing = useCallback(
        (page, sort) => {
            // if (
            //     Helper.getPermissions(
            //         "serial-read",
            //         permissions && permissions,
            //         "admin"
            //     ) ||
            //     user.type === "superadmin"
            // ) {
            Helper.overlay(true);
            apiRequest(GetAllContract.method, GetAllContract.url + `?page=${1}&sort_by=${sort_by}`)
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data;
                        dispatch({ type: "SET_CALL_STATE", call_one: false });
                        setrecords(data);
                        dispatch({ type: "SET_SEARCH_STATE", search_list: false });
                        sethidePagination(false);
                        setResult(response.data.meta);
                        Helper.overlay(false);
                    }
                })
                .catch((error) => {
                    setErrorMsg(error);
                    Helper.overlay(false);
                });
            //}
        },
        [dispatch, sort_by]
    );

    const handleSearchPageChange = useCallback((pageNumber) => {
        Helper.overlay(true);
        apiRequest(ListSearchApi.method, ListSearchApi.url + `contracts?name=${state.searchedName}&page=${pageNumber}`)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data;
                    setrecords(data.data);
                    setResult(data.meta);
                    Helper.overlay(false);
                }
            })
            .catch((error) => {
                Helper.overlay(false);
                history.push(`${location.pathname}#error`);
            });

    }, [history, location.pathname, state.searchedName])

    useEffect(() => {
        if (location.hash !== "#search" && !state.searchedName) {
            dispatch({ type: "SET_STATE", response: [] });
            getContractListing(state.set_active_page, sort_by);
        }
    }, [dispatch, getContractListing, location.hash, sort_by, state.searchedName, state.set_active_page]);

    useEffect(() => {
        if (location.pathname.includes('contract-listing') && location.hash === "#search" && state.searchedName) {
            dispatch({ type: "SET_STATE", response: [] });
            handleSearchPageChange(state.set_active_page)
        }
    }, [dispatch, location.hash, state.searchedName, state.set_active_page])

    useEffect(() => {
        // if (location.pathname.includes('contract-listing') && location.hash === "#search") {
        //     if (state.data.data && state.data.data.length === 0) {
        //         setrecords([]);
        //         setResult([]);
        //         setErrorMsg('No record Found');
        //         Helper.overlay(false);
        //     }
        //     else {
        //         sethidePagination(true);
        //         setrecords(state.data.data || []);
        //         setResult(state.data.meta || []);
        //         dispatch({ type: "SET_SEARCH_STATE", pagination: false });
        //         Helper.overlay(false);
        //     }
        // }
        if (location.hash === "#error") {
            setrecords([]);
            setResult([]);
            //setShow(true);
            setErrorMsg('No record Found');
        }
    }, [dispatch, location, state.data, state.pagination]);

    const deleteRecord = (e) => {

        apiRequest(DeleteContract.method, DeleteContract.url + `${currentId}`)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = records.filter((d) => {
                        return d.id != currentId;
                    });
                    setrecords(data);
                    setRsModal(false);
                    console.log("Success", response);
                } else {
                    console.log("Failure", response);
                }
            })
            .catch((error) => {
                console.log("error", error);
                setShow(true);
                setRsModal(false);
                setErrorMsg(error.response.data.message);
            });

    };

    const handlePageChange = (pageNumber) => {
        dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
        Helper.overlay(true);
        if (location.pathname.includes('user-list') && location.hash === "#search") {
            history.push(`/admin/user-list/${pageNumber}#search`);
        } else {
            history.push(`/admin/user-list/${pageNumber}`);
        }
    };
    const setDropDown = (e) => {
        setSortBy(e.target.value);
    };

    const editTemplate = (id) => {
        const Helper = require("../Helper");
        let userObj = Helper.getStorageData("SESSION");
        let userLoggedInToken = userObj.access_token;
        window.open(`https://dev.movitronic.com/admin-react-template/` + id + '/' + userLoggedInToken, '_blank', "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1000,height=1000");

    }
    return (
        <React.Fragment>
            <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
                <Modal.Body>
                    <Icon
                        icon="remind"
                        style={{
                            color: "#ffb300",
                            fontSize: 24,
                        }}
                    />
                    {"  "}
                    Are you sure you want to delete this record?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={deleteRecord} appearance="primary">
                        Ok
                    </Button>
                    <Button onClick={Modalclose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={Modalclose}>
                <Modal.Body>
                    <h5>{errorMsg}</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={Modalclose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <div id="content">
                <Header />

                <div className="p-4">
                    <div className="row pb-2 d-flex justify-content-between align-items-center border-bottom">
                        <div className="col-lg-12 col-md-6">
                            <div className="d-flex flex-row justify-content-between  mb-3">
                                {Helper.getPermissions(
                                    "contract-create",
                                    permissions && permissions,
                                    user.type) &&
                                    <div className="p-2">
                                        <NavLink
                                            exact
                                            to={`/admin/create-contract`}
                                            className="btn btnn-user white bg-orange" style={{ backgroundColor: user.background_color }}
                                        >
                                            Create Contract
                                        </NavLink>

                                    </div>
                                }

                                <div className="col-lg-4 col-md-6 ">
                                    <div className="d-flex row align-items-center">
                                        <div className="col-md-12 col-lg-3 pl-lg-0 pr-lg-0">Results: {result.total ? result.total : '1'}</div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {errorMsg && (" ")}
                    <div className="row">
                        {records.length ?
                            records.map((record, index) =>
                                <div className="card col-md-12 mt-3 shadow bg-white rounded" key={index}>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-10  col-sm-12 pb-3 pt-2">
                                           
                                            <div className="row pt-3">

                                                <div className="col-md-3 col-12">
                                                    Name: <strong>{record?.whitelabel?.name ?? ''}</strong>
                                                </div>


                                                <div className="col-md-5 col-12 pr-0">
                                                    created by Email:{" "}
                                                    <span className="text-success">{record.created_by.email ? record.created_by.email : "-"}</span>
                                                </div>
                                                <div className="col-md-3 col-12 pl-0">
                                                    Contact:{" "}
                                                    <span className="text-success">{record.created_by.contact ? record.created_by.contact : "-"}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-sm-12  col-lg-2 ">
                                            <div className="row pt-3 pb-3">
                                                <div className="col-md-6 col-sm-6 col-lg-12 pt-lg-2">
                                                    {Helper.getPermissions(
                                                        "contract-update",
                                                        permissions && permissions,
                                                        user.type) &&
                                                        <NavLink
                                                            exact
                                                            to={`/admin/edit-contract/` + record.label_id + '/' + record.id}
                                                            className=""
                                                        >
                                                            <button className="btn-success w-100">
                                                                Edit Contract
                                                            </button>
                                                        </NavLink>
                                                    }

                                                </div>
                                                <div className="col-md-6  col-sm-6 col-lg-12 pt-lg-3 pt-md-0 pt-2">
                                                    {Helper.getPermissions(
                                                        "contract-delete",
                                                        permissions && permissions,
                                                        user.type) &&
                                                        <button data-id={record.id} onClick={Modalopen} className="btn-danger w-100">
                                                            Delete
                                                        </button>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            ) : (
                                errorMsg && (
                                    <div className="text-center ft-14 mt-3 font-weight-bold">
                                        No Data Found...
                                    </div>
                                )
                            )}
                    </div>
                    <div className="row mt-3 ft-14">
                        <div className="col-md-12">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-end">
                                    {!hidePagination && (
                                        <Pagination
                                            activePage={state.set_active_page}
                                            // itemsCountPerPage={result.to}
                                            totalItemsCount={result.total ? result.total : 1}
                                            pageRangeDisplayed={result.last_page}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            onChange={handlePageChange}
                                            activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                                        />
                                    )}
                                    <li className="pl-3">
                                        {/* <form className="form-inline">
                          <label className="my-1 mr-2">Results on page:</label>
                          <select
                            className="form-control p-0"
                            style={{ height: "30px" }}
                            defaultValue={"10"}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </form> */}
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

            </div>
            {/* </div> */}
        </React.Fragment>
    );
}
export default ContractLising;
