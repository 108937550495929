import React, { useCallback, useEffect, useState } from "react";
import AdvanceSearch from "./AdvanceSearch";
import { Context } from "../Store/Store";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { DateRangePicker } from "rsuite";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import { DateRangePicker } from 'react-date-range';
var { apiRequest } = require("../Api/Service");
var { ListSearchApi } = require("../Api/ApiRoutes");
const { routes } = require("../utils/SearchRouteParam");

const Helper = require("../Helper");
const _ = require("lodash");
const initialState = {
  isError: false,
  errors: [],
  isSuccess: false,
  success: "",
  disable: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return {
        ...state,
        isSuccess: true,
        success: action.payload,
        disable: false,
      };
    case "FETCH_ERROR":
      return {
        ...state,
        isError: true,
        errors: action.payload,
        disable: false,
      };
    case "DEFAULT":
      return {
        disable: action.payload,
      };
    default:
      return state;
  }
};

function Index({ showSearch = true, showAdvanceSearch = false }) {
  let location = useLocation();

  const isAuth = !_.isEmpty(localStorage.getItem("SESSION"))
    ? Helper.getStorageData("SESSION")
    : {};

  const toggleClass = (evt) => {
    document.body.classList.toggle("showToggle");
  };

  const handleLogout = () => {
    localStorage.removeItem("SESSION");
    window.location.href = "/";
  };

  const [state, dispatch] = useContext(Context);
  const [LocalState, Localdispatch] = React.useReducer(reducer, initialState);
  const [loader, setloader] = React.useState(false);
  const [datesInputField, setDatesInputField] = React.useState(false);
  const [startDates, setStartDate] = useState(new Date());
  const [endDates, setEndDate] = useState(null);
  const [moduleName, setModuleName] = useState("");
  const [moduleParam, setModuleParam] = useState("");
  const [pathname, setPathName] = useState("");
  const [keyword, setKeyword] = useState("");
  var user = Helper.getStorageData("SESSION");
  const onChange = (dates) => {
    const [s, e] = dates;
    const start = JSON.stringify(new Date(s)).slice(1, 11);
    const end = JSON.stringify(new Date(e)).slice(1, 11);
    setStartDate(start);
    setEndDate(end);
  };

  const setModuleNamesAndParams = useCallback(()=>{
    routes.forEach((value) => {
      if (location.pathname === value.route + `/${state.set_active_page}`) {
        setModuleName(value.moduleName);
        setPathName(value.route);

        if ("/admin/booking-active-list" === value.route) {
          setModuleParam("&status=1");
        }
        if ("/admin/booking-future-list" === value.route) {
          setModuleParam("&status=2");
        }
        if ("booking-vehicle-return-list" === value.route) {
          setModuleParam("&status=3");
        }
        if ("/admin/booking-expired-list" === value.route) {
          setModuleParam("&status=4");
        }
      }
      if (location.pathname === "/admin/shares-with-me-list") {
        setModuleName("shares/sharedwithme");
      }
    });
  },[location.pathname, state.set_active_page])

  useEffect(() => {
    setModuleNamesAndParams()
  }, [setModuleNamesAndParams]);

  const onClean = () => {
    setStartDate(null);
    setEndDate(null);
  };
  React.useEffect(() => {
    if (
      location.pathname === `/admin/usage-my-list/${state.set_active_page}` ||
      location.pathname === `/admin/usage-list/${state.set_active_page}`
    ) {
      setDatesInputField(true);
    }
  }, []);

  let history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    // let params = new FormData(form);
    Localdispatch({ type: "DEFAULT", payload: true });
    Helper.overlay(true);
    if (datesInputField === true) {
      if (startDates && endDates) {
        apiRequest(
          ListSearchApi.method,
          ListSearchApi.url +
            moduleName +
            `?start_date=${startDates}&end_date=${endDates}${moduleParam}`
        )
          .then((response) => {
            if (response.data.code === 200) {
              let data = response.data;
              console.log("FETCH_SUCCESS", data);
              Localdispatch({
                type: "FETCH_SUCCESS",
                payload: response.data.message,
              });
              dispatch({
                type: "SET_USAGES_DATES",
                start_date: startDates,
                end_date: endDates,
              });
              dispatch({ type: "SET_STATE", response: data });
              dispatch({ type: "SET_SEARCH_STATE", pagination: true });
              dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: 1 });
              Helper.overlay(false);
              // <Redirect to={location.pathname+"#search"}/>
              history.push(`${pathname}/1#search`);
            }
          })
          .catch((error) => {
            Localdispatch({
              type: "FETCH_ERROR",
              payload: error.response.data.data,
            });
            history.push(`${location.pathname}#error`);
            //Localdispatch({ type: 'FETCH_ERROR', payload: error.response.data.data })
            dispatch({ type: "SET_STATE", response: [] });
            dispatch({ type: "SET_SEARCH_STATE", pagination: true });
            console.log("FETCH_ERROR");
            Helper.overlay(false);
          });
      } else {
        alert("Please select date");
        Helper.overlay(false);
        setloader(false);
      }
    } else {
      const name = event.target.name.value;
      if(keyword !== ''){

          if(keyword == name){
              Helper.overlay(false);
              return 
          }
      }
      setKeyword(name);
      if(name === ''){
        history.push(`${pathname}/1`);
        Helper.overlay(false);
        return 
      }
      moduleName
        ? apiRequest(
            ListSearchApi.method,
            ListSearchApi.url + moduleName + `?name=${name}${moduleParam}`
          )
            .then((response) => {
              if (response.data.code === 200) {
                let data = response.data;
                Localdispatch({
                  type: "FETCH_SUCCESS",
                  payload: response.data.message,
                });
                dispatch({ type: "SET_STATE", response: data });
                dispatch({ type: "SET_SEARCH_STATE", pagination: true });
                dispatch({ type: "SET_SEARCH_STATE", searchedName: name });
                dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: 1 });
                history.push(`${pathname}/1#search`);

                Helper.overlay(false);
                setloader(false);
                // <Redirect to={location.pathname+"#search"}/>
              }
            })
            .catch((error) => {
              history.push(`${location.pathname}#error`);
              Localdispatch({
                type: "FETCH_ERROR",
                payload: error.response.data.message,
              });
              dispatch({ type: "SET_STATE", response: [] });
              dispatch({ type: "SET_SEARCH_STATE", pagination: true });

              Helper.overlay(false);
              setloader(false);
            })
        : dispatch({ type: "SET_SEARCH_STATE", searchedName: name });
      dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: 1 });
      Helper.overlay(false);
      history.push(`${pathname}/1#search`);
    }
  };

  return (
    <>
      <nav
        className="navbar navbar-expand navbar-light bg-orange topbar  static-top shadow"
        style={{ backgroundColor: user.background_color }}
      >
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3 btn ml-2 menu-toggle"
          onClick={() => {
            Helper.loadScript("/assets/js/sb-admin-2.js");
          }}
        >
          <i className="fa fa-bars" style={{
            color: `${user.type === "whitelabel" ? '#ffdc02' : '#4e73df'}`
          }}></i>
        </button>

        <form
          className="white d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
          onSubmit={handleSubmit}
        >
          <ul className="nav-ul">
            {datesInputField === true && (
              <li className="nav-li">
                <div className="input-group">
                  <DateRangePicker
                    placeholder="Select Date Range"
                    onChange={onChange}
                    onClean={onClean}
                    startDate={startDates}
                    endDate={endDates}
                  />
                  <div>
                    <div className="input-group-append">
                      <button className="btn btn-primary " type="submit">
                        <i className="fas fa-search fa-sm"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            )}
            {datesInputField === false && showSearch && (
              <li className="nav-li">
                <div className="input-group">
                  <input
                    type="search"
                    name="name"
                    className="form-control bg-light border-0 small"
                    placeholder="Search here"
                    onChange={(e) => {
                      if (!e.target.value)
                        dispatch({
                          type: "SET_SEARCH_STATE",
                          searchedName: null,
                        });
                    }}

                  />
                  {/* <div className="input-group-append"> */}
                  <div className="">
                    <button className="btn btn-primary" type="submit" style={{
                      backgroundColor: `${user.type === "whitelabel" ? "#4caf50": "#4e73df" }`,
                      borderColor:  `${user.type === "whitelabel" ? "#4caf50": "#4e73df" }`,

                    }}>
                      <i className="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </li>
            )}
            {showAdvanceSearch === true && (
              <li className="nav-li white">
                <span
                  className="navbar-toggler text-white custom-toggler ft-14 font-weight-bold panel-title "
                  data-toggle="collapse"
                  data-target="#navbarToggleExternalContent"
                  aria-controls="navbarToggleExternalContent"
                  aria-expanded="false"
                >
                  Advanced Search
                </span>
              </li>
            )}
          </ul>
        </form>
        <ul className="navbar-nav ml-auto">
          {/* <li className="nav-item dropdown no-arrow d-sm-none">
            <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-search fa-fw"></i>
            </a>
  
            <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                aria-labelledby="searchDropdown">
                <form className="form-inline mr-auto w-100 navbar-search">
                    <div className="input-group">
                        <input type="text" className="form-control bg-light border-0 small"
                            placeholder="Search for..." aria-label="Search"
                            aria-describedby="basic-addon2"/>
                        <div className="input-group-append">
                            <button className="btn btn-primary" type="button">
                                <i className="fas fa-search fa-sm"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </li> */}

          {/* <li className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-bell fa-fw"></i>
                
                <span className="badge badge-danger badge-counter">3+</span>
            </a>
        
            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="alertsDropdown">
                <h6 className="dropdown-header">
                    Alerts Center
                </h6>
                <a className="dropdown-item d-flex align-items-center" href="#">
                    <div className="mr-3">
                        <div className="icon-circle bg-primary">
                            <i className="fas fa-file-alt text-white"></i>
                        </div>
                    </div>
                    <div>
                        <div className="small text-gray-500">December 12, 2019</div>
                        <span className="font-weight-bold">A new monthly report is ready to download!</span>
                    </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                    <div className="mr-3">
                        <div className="icon-circle bg-success">
                            <i className="fas fa-donate text-white"></i>
                        </div>
                    </div>
                    <div>
                        <div className="small text-gray-500">December 7, 2019</div>
                        $290.29 has been deposited into your account!
                    </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                    <div className="mr-3">
                        <div className="icon-circle bg-warning">
                            <i className="fas fa-exclamation-triangle text-white"></i>
                        </div>
                    </div>
                    <div>
                        <div className="small text-gray-500">December 2, 2019</div>
                        Spending Alert: We've noticed unusually high spending for your account.
                    </div>
                </a>
                <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
            </div>
        </li> */}

          {/* <li className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fas fa-envelope fa-fw"></i>
           
                <span className="badge badge-danger badge-counter">7</span>
            </a>
            
            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                aria-labelledby="messagesDropdown">
                <h6 className="dropdown-header">
                    Message Center
                </h6>
                <a className="dropdown-item d-flex align-items-center" href="#">
                    <div className="dropdown-list-image mr-3">
                        <img className="rounded-circle" src="img/undraw_profile_1.svg"
                            alt="..."/>
                        <div className="status-indicator bg-success"></div>
                    </div>
                    <div className="font-weight-bold">
                        <div className="text-truncate">Hi there! I am wondering if you can help me with a
                            problem I've been having.</div>
                        <div className="small text-gray-500">Emily Fowler · 58m</div>
                    </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                    <div className="dropdown-list-image mr-3">
                        <img className="rounded-circle" src="img/undraw_profile_2.svg"
                            alt="..."/>
                        <div className="status-indicator"></div>
                    </div>
                    <div>
                        <div className="text-truncate">I have the photos that you ordered last month, how
                            would you like them sent to you?</div>
                        <div className="small text-gray-500">Jae Chun · 1d</div>
                    </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                    <div className="dropdown-list-image mr-3">
                        <img className="rounded-circle" src="img/undraw_profile_3.svg"
                            alt="..."/>
                        <div className="status-indicator bg-warning"></div>
                    </div>
                    <div>
                        <div className="text-truncate">Last month's report looks great, I am very happy with
                            the progress so far, keep up the good work!</div>
                        <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                    </div>
                </a>
                <a className="dropdown-item d-flex align-items-center" href="#">
                    <div className="dropdown-list-image mr-3">
                        <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                            alt="..."/>
                        <div className="status-indicator bg-success"></div>
                    </div>
                    <div>
                        <div className="text-truncate">Am I a good boy? The reason I ask is because someone
                            told me that people say this to all dogs, even if they aren't good...</div>
                        <div className="small text-gray-500">Chicken the Dog · 2w</div>
                    </div>
                </a>
                <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
            </div>
        </li> */}

          <div className="topbar-divider d-none d-sm-block"></div>

          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="mr-2 d-lg-inline  small text-white">
                Hello, {isAuth.name}
              </span>
              {/* <img className="img-profile rounded-circle"
                    src="img/undraw_profile.svg"/> */}
            </a>

            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              {/* <a className="dropdown-item" href="#">
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                </a>
                <a className="dropdown-item" href="#">
                    <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                    Settings
                </a>
                <a className="dropdown-item" href="#">
                    <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                    Activity Log
                </a> */}
              {/* <div className="dropdown-divider"></div> */}
              <a
                className="dropdown-item"
                onClick={handleLogout}
                data-toggle="modal"
                data-target="#logoutModal"
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      {showAdvanceSearch === true && <AdvanceSearch />}
    </>
  );
}

export default Index;
