import React, { useEffect, useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Header from "../../Header/Index.js";
import { Modal, Button, Icon } from "rsuite";
import Pagination from "react-js-pagination";
import Helper from "../../Helper";
import { Context } from "../../Store/Store";
import { useHistory } from "react-router-dom";
import { apiRequest } from "../../Api/Service.js";
import ReactCountryFlag from "react-country-flag"

import {
  DeleteTermOfUse,
  ListSearchApi,
  TermsOfUseTemplateListing,
} from "../../Api/ApiRoutes.js";

function TermsOfUse() {
  let user = Helper.getStorageData("SESSION");
  let permissions = user.permissions;
  let location = useLocation();

  const [termOfUseRecord, setTermOfUseRecord] = React.useState([]);
  const [termOfUseResult, setTermOfUseResult] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [rsModal, setRsModal] = React.useState(false);
  const [deleteRecordData, setDeleteRecordData] = React.useState();
  const [state, dispatch] = React.useContext(Context);
  const history = useHistory();
  const [sort_by, setSortBy] = React.useState(1);

  const Modalclose = () => {
    setRsModal(false);
    setShow(false);
  };
  const Modalopen = (e) => {
    setDeleteRecordData(e.target.dataset.id);
    setRsModal(true);
  };

  const getListing = useCallback(
    async (page, sort) => {
      Helper.overlay(true);
      try {

        const termOfUseData = await apiRequest(
          TermsOfUseTemplateListing.method,
          TermsOfUseTemplateListing.url + `?page=${1}&sort_by=${sort_by}`
        );
        if (termOfUseData.data.code === 200) {
          let data = termOfUseData.data.data;
          dispatch({ type: "SET_CALL_STATE", call_one: false });
          dispatch({ type: "SET_SEARCH_STATE", search_list: false });
          setTermOfUseRecord(data);
          setTermOfUseResult(termOfUseData.data.meta);
        }

        Helper.overlay(false);
      } catch (error) {
        setErrorMsg(error);
        Helper.overlay(false);
      }
    },
    [dispatch, sort_by]
  );

  const handleSearchPageChange = useCallback(
    (pageNumber) => {
      Helper.overlay(true);
      apiRequest(
        ListSearchApi.method,
        ListSearchApi.url +
        `contracts?name=${state.searchedName}&page=${pageNumber}`
      )
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data;
            setTermOfUseRecord(data.data);
            setTermOfUseResult(data.meta);
            Helper.overlay(false);
          }
        })
        .catch((error) => {
          Helper.overlay(false);
          history.push(`${location.pathname}#error`);
        });
    },
    [history, location.pathname, state.searchedName]
  );

  useEffect(() => {
    if (location.hash !== "#search" && !state.searchedName) {
      dispatch({ type: "SET_STATE", response: [] });
      getListing(state.set_active_page, sort_by);
    }
  }, [
    dispatch,
    location.hash,
    sort_by,
    state.searchedName,
    state.set_active_page,
  ]);

  useEffect(() => {
    if (
      location.pathname.includes("terms-of-use") &&
      location.hash === "#search" &&
      state.searchedName
    ) {
      dispatch({ type: "SET_STATE", response: [] });
      handleSearchPageChange(state.set_active_page);
    }
  }, [dispatch, location.hash, state.searchedName, state.set_active_page]);

  useEffect(() => {
    // if (location.pathname.includes('terms-of-use') && location.hash === "#search") {
    //     if (state.data.data && state.data.data.length === 0) {
    //         setrecords([]);
    //         setResult([]);
    //         setErrorMsg('No record Found');
    //         Helper.overlay(false);
    //     }
    //     else {
    //         sethidePagination(true);
    //         setrecords(state.data.data || []);
    //         setResult(state.data.meta || []);
    //         dispatch({ type: "SET_SEARCH_STATE", pagination: false });
    //         Helper.overlay(false);
    //     }
    // }
    if (location.hash === "#error") {
      setTermOfUseRecord([]);
      //setShow(true);
      setErrorMsg("No record Found");
    }
  }, [location]);

  const deleteRecord = () => {
    apiRequest(
      DeleteTermOfUse.method,
      DeleteTermOfUse.url + `${deleteRecordData}`
    )
      .then((response) => {
        if (response.data.code === 200) {
          let data = termOfUseRecord.filter((d) => {
            return !(
              d.id === +deleteRecordData
            );
          });
          setTermOfUseRecord(data);
          // setResults((prev) => ({ ...prev, total: prev.total - 1 }));
          setRsModal(false);
          console.log("Success", response);
        } else {
          console.log("Failure", response);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setShow(true);
        setRsModal(false);
        setErrorMsg(error.response.data.message);
      });

  };

  const handlePageChange = (pageNumber) => {
    dispatch({ type: "SET_ACTIVE_PAGE_STATE", set_active_page: pageNumber });
    if (
      location.pathname.includes("terms-of-use") &&
      location.hash === "#search"
    ) {
      history.push(`/admin/terms-of-use/${pageNumber}#search`);
    } else {
      history.push(`/admin/terms-of-use/${pageNumber}`);
    }
  };

  return (
    <React.Fragment>
      <Modal backdrop="static" show={rsModal} onHide={Modalclose} size="xs">
        <Modal.Body>
          <Icon
            icon="remind"
            style={{
              color: "#ffb300",
              fontSize: 24,
            }}
          />
          {"  "}
          Are you sure you want to delete this record?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteRecord} appearance="primary">
            Ok
          </Button>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={Modalclose}>
        <Modal.Body>
          <h5>{errorMsg}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={Modalclose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <div id="content">
        <Header showSearch={false} />

        <div className="p-4">
          <div className="row pb-2 d-flex justify-content-between align-items-center border-bottom">
            <div className="col-lg-12 col-md-6">
              <div className="d-flex flex-row justify-content-between  mb-3">
                {Helper.getPermissions(
                  "terms_of_use-create",
                  permissions && permissions,
                  user.type
                ) && (
                    <div className="p-2">
                      <NavLink
                        exact
                        to={`/admin/create-terms-of-use`}
                        className="btn btnn-user white bg-orange"
                        style={{ backgroundColor: user.background_color }}
                      >
                        Create Terms of Use
                      </NavLink>
                    </div>
                  )}

                <div className="col-lg-4 col-md-6 ">
                  <div className="d-flex row align-items-center">
                    <div className="col-md-12 col-lg-3 pl-lg-0 pr-lg-0">
                      Results: {termOfUseResult.total ? termOfUseResult.total : "0"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errorMsg && " "}
          <div className="row">
            {termOfUseRecord.length
              ? termOfUseRecord.map((record, index) => (
                <div
                  className="card col-md-12 mt-3 shadow bg-white rounded"
                  key={index}
                >
                  <div className="row">
                    <div className="col-md-12 col-lg-2  col-sm-12 pb-3 pt-2 my-auto">
                      <img
                        src={record.created_by.thumbnail}
                        className="card-img-top img-fluid "
                        alt="..."
                        style={{
                          borderRadius: "50% !important",
                          width: "150px",
                          height: "100px",
                        }}
                      />
                    </div>
                    <div className="col-md-12 col-lg-8  col-sm-12 pb-3 pt-2">
                      <div className="row pt-2">
                        <div className="col-md-2 col-12 px-0">
                          {Helper.getPermissions(
                            "terms_of_use-update",
                            permissions && permissions,
                            user.type
                          ) && (
                              <NavLink
                                className="form-check-label font-weight-bold"
                                exact
                                to={`/admin/edit-terms-of-use/${record.label_id}/${record.locale}`}
                              >
                                {record.id}
                              </NavLink>
                            )}
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-md-3 col-12 px-0">
                          Name: <strong>{record?.whitelabel?.name ?? ''}</strong>
                        </div>

                        <div className="col-md-5 col-12 px-0">
                          created by Email:{" "}
                          <span className="text-success">
                            {record.created_by.email
                              ? record.created_by.email
                              : "-"}
                          </span>
                        </div>
                        <div className="col-md-2 col-12 pl-0">
                          Language:{" "}
                          <span className="text-success">{record.locale ? record.locale == 'en'?'EN':'ET':'-'}</span>
                              {" "} <ReactCountryFlag countryCode={record.locale == 'en'? "GB":'EE'} svg />

                        </div>
                      </div>
        
                    </div>
                  
                    <div className="col-md-12 col-sm-12  col-lg-2 ">
                      <div className="row pt-3 pb-3">
                        <div className="col-md-6 col-sm-6 col-lg-12 pt-lg-4">
                          {Helper.getPermissions(
                            "terms_of_use-update",
                            permissions && permissions,
                            user.type
                          ) && (
                              <NavLink
                                exact
                                to={`/admin/edit-terms-of-use/${record.label_id}/${record.locale}`}
                              >
                                <button className="btn-success w-100">
                                  Edit {record.type}
                                </button>
                              </NavLink>
                            )}
                        </div>
                        <div className="col-md-6  col-sm-6 col-lg-12 pt-lg-3 pt-md-0 pt-2">
                          {Helper.getPermissions(
                            "terms_of_use-delete",
                            permissions && permissions,
                            user.type
                          ) && (
                              <button
                                data-id={record.id}
                                onClick={Modalopen}
                                className="btn-danger w-100"
                              >
                                Delete
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
              : errorMsg && (
                <div className="text-center ft-14 mt-3 font-weight-bold">
                  No Data Found...
                </div>
              )}
          </div>
          <div className="row mt-3 ft-14">
            <div className="col-md-12">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <Pagination
                    activePage={state.set_active_page}
                    // itemsCountPerPage={result.to}
                    totalItemsCount={
                      termOfUseResult.total ? termOfUseResult.total : 1
                    }
                    pageRangeDisplayed={termOfUseResult.last_page}
                    itemClass="page-item"
                    linkClass="page-link"
                    onChange={handlePageChange}
                    activeClass={user.type === "whitelabel" ? "active-whitelabel-pagination" : "active-default-pagination "}
                  />
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
export default TermsOfUse;
