const Helper = require("../Helper");
let VAPID_PUBLIC_KEY =
  "BCX4_C7fN8_7NETjO1fm76Jk3E1gqMDgygeUF4R8yeq_41QrRbxeyQowtQsS6Pdn9JhR_1BlTon5swK9xnVZVMI";

const initSW = () => {
  if (!"serviceWorker" in navigator) {
    //service worker isn't supported
    return;
  }
  //don't use it here if you use service worker
  //for other stuff.
  if (!"PushManager" in window) {
    //push isn't supported
    return;
  }
  try {
    //register the service worker
    navigator.serviceWorker
      .register("/sw.js")
      .then(() => {
        initPush();
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    return;
  }
};
function initPush() {
  if (!navigator.serviceWorker.ready) {
    return;
  }
  new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result);
    });
    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then((permissionResult) => {
    // if (permissionResult !== "granted") {
    //   throw new Error("We weren't granted permission.");
    // }
    subscribeUser();
  });
}
function subscribeUser() {
  navigator.serviceWorker.ready
    .then((registration) => {
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
      };
      return registration.pushManager.subscribe(subscribeOptions);
    })
    .then((pushSubscription) => {
      storePushSubscription(pushSubscription);
    });
}
function urlBase64ToUint8Array(base64String) {
  var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);
  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
function storePushSubscription(pushSubscription) {
  const token = document
    .querySelector("meta[name=csrf-token]")
    .getAttribute("content");
  fetch(process.env.REACT_APP_API_URL +"/push", {
    method: "POST",
    body: JSON.stringify(pushSubscription),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + Helper.getStorageData("SESSION").access_token,
      "X-CSRF-Token": token,
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
}

export default initSW;
